import Decimal from "decimal.js";

/*
 * Expects input in cents, and outputs a formatted string in dollars
 */
export function formatCents(value?: number): string {
  if (value === undefined || isNaN(value)) {
    return "--";
  }
  return formatDollars(value / 100);
}

/*
 * Expects number (maybe float) in dollars, and outputs a formatted string in dollars.
 * The resulting string will always have 2 decimal places for the cents.
 * Outputs "--" if the input value is invalid.
 */
export function formatCurrencyInDollars(value?: number | null): string {
  if (value === undefined || value === null || isNaN(value)) {
    return "--";
  }
  return formatDollars(value);
}

/*
 * Expects number (maybe float) in dollars, and outputs a formatted string in dollars
 * The resulting string will always have 2 decimal places for the cents.
 */
export function formatDollars(value: number): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  return formatter.format(value);
}

/*
 * Cleans a given string from non digits and parses that resulting string into a float number of dollars
 * Returns float representing the number of dollars
 */
export const formattedStringToDollars = (formattedString: string) => {
  const cleaned = formattedString.replace(/[^\d.]+/gi, "");
  const dollars = parseFloat(cleaned);
  return dollars;
};

/*
 * Converts from integer Cents to a formatted dollar amount string
 * Returns "$5" instead of "$5.00" if there are no cents
 * Returns "$5.50" if there are cents
 */
export const centsToFormattedString = (totalCents: number) => {
  const currencyString = formatCents(totalCents);
  const [dollarString, centsString] = currencyString.split(".");
  if (centsString === "00") return dollarString;
  return currencyString;
};

/*
 * Converts from float dollars.cents to a formatted dollar amount string
 * Returns "$5" instead of "$5.00" if there are no cents
 * Returns "$5.50" if there are cents
 */
export const dollarsToFormattedString = (totalDollars: number) => {
  const currencyString = formatDollars(totalDollars);
  const [dollarString, centsString] = currencyString.split(".");
  if (centsString === "00") return dollarString;
  return currencyString;
};

/*
 * Converts from a decimal string, say "1.02", to an integer number of cents, say 102
 * When passing more than 2 decimals, it should round up as needed
 */
export const decimalStringToCents = (decimalString: string): number => {
  const decimal = parseFloat(decimalString);
  const cents = Math.round(100 * decimal);
  return cents;
};

/*
 * Cleans a given string from non digits and parses that resulting string into a float number of cents
 * It will round up the cents if there are more than 2 decimal places
 * Returns an integer representing the number of cents
 */
export const formattedStringToCents = (formattedString: string) => {
  const cleaned = formattedString.replace(/[^\d.]+/gi, "");
  return decimalStringToCents(cleaned);
};

/*
 * Cleans and formats a currency string in dollars and cents, including thousands separators
 *
 * 🟠 NOTE: It truncates to 2 decimal places, it DOES NOT round up the decimals. E.g. $1.9999 will be formatted as $1.99
 */
export const formatCurrencyInput = (value: string) => {
  // remove any non-digits, except for the decimal point
  const cleaned = value.replace(/[^\d.]+/gi, "");
  const [dollarString, centString] = cleaned.split(".");
  // We only take the first two decimal places and discard the rest because otherwise
  // there was a bug with the input components where it would round up the cents and result in a very
  // weird UX
  // See: https://moovfinancial.slack.com/archives/C059JFG2Y0H/p1722618534294329
  const twoCentsString = centString === undefined ? undefined : centString.slice(0, 2);

  const formattedString = formatDollars(
    parseFloat(`${dollarString ?? 0}.${twoCentsString ?? 0}` ?? "0")
  );
  // remove cents if the original string didn't have them either
  const formattedStringMaybeWithoutCents =
    centString !== undefined ? formattedString : formattedString.slice(0, -3);
  return formattedStringMaybeWithoutCents;
};

/* Formats a Decimal type without performing any rounding. */
export const formatDecimalNoRounding = (value: Decimal): string => {
  return formatCurrencyInput(value.valueOf());
};

/* Formats an integer into a dollar.cents float */
export const centsToDollarFloat = (value?: number): number => {
  if (!value) return 0;
  return parseFloat((value / 100).toFixed(2));
};

/* Converts a dollar.cents float into a CENTS Integer */
export const dollarToCentsInteger = (value?: number): number => {
  if (!value) return 0;
  return Math.round(value * 100);
};

/**
 * Formats an already-formatted string to include a dollar sign.
 * Allows negative sign to come before dollar sign for stylistic reasons.
 **/
export const prependDollarSign = (formattedString: string) => {
  const isNegative = formattedString.includes("-");

  if (isNegative) return formattedString.replace("-", "-$");
  return `$${formattedString}`;
};
