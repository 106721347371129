import { useCallback, useState } from "react";
import { openApi } from "@moovfinancial/common/api/OpenApiClient";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { IconChevronRight } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import { AutoComplete } from "./AutoComplete";
import { type AutoCompleteProps } from "./AutoComplete";

type AddressSuggestion = components["schemas"]["EnrichmentAddress"];

interface FetchAddressSuggestionOptionsBase {
  maxResults?: number;
  preferCities?: string;
  preferGeolocation?: "city" | "none";
  preferStates?: string;
  preferRatio?: number;
  preferZipcodes?: string;
  selected?: AddressSuggestion;
  source?: "all" | "postal";
}

interface FetchAddressSuggestionOptionWithIncludes extends FetchAddressSuggestionOptionsBase {
  includeCities?: string;
  includeStates?: string;
  includeZipcodes?: string;
}

interface FetchAddressSuggestionOptionWithExcludes extends FetchAddressSuggestionOptionsBase {
  excludeStates?: string;
}

type FetchAddressSuggestionOptions =
  | FetchAddressSuggestionOptionWithIncludes
  | FetchAddressSuggestionOptionWithExcludes;

const isOptionsWithIncludes = (
  x: FetchAddressSuggestionOptions | undefined
): x is FetchAddressSuggestionOptionWithIncludes => !!x && !("excludeStates" in x);

function fetchAddressSuggestion(
  search: string,
  facilitatorID: string,
  options?: FetchAddressSuggestionOptions
) {
  const selected = options?.selected
    ? `${options.selected.addressLine1} ${options.selected.addressLine2} (${options.selected.entries}) ${options.selected.city} ${options.selected.stateOrProvince} ${options.selected.postalCode}`
    : undefined;

  return openApi.GET("/enrichment/address", {
    params: {
      query: {
        excludeStates: isOptionsWithIncludes(options) ? undefined : options?.excludeStates,
        includeCities: isOptionsWithIncludes(options) ? options.includeCities : undefined,
        includeStates: isOptionsWithIncludes(options) ? options.includeStates : undefined,
        includeZipcodes: isOptionsWithIncludes(options) ? options.includeZipcodes : undefined,
        maxResults: options?.maxResults,
        preferCities: options?.preferCities,
        preferGeolocation: options?.preferGeolocation,
        preferRatio: options?.preferRatio,
        preferStates: options?.preferStates,
        preferZipcodes: options?.preferZipcodes,
        selected,
        search,
        source: options?.source
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function quickFormat(item: AddressSuggestion) {
  if (item.entries && item.entries > 1) {
    return `${item.addressLine1} ${item.addressLine2} (${item.entries} entries), ${item.city}, ${item.stateOrProvince}, ${item.postalCode}`;
  } else {
    return `${item.addressLine1}${item.addressLine2 ? `, ${item.addressLine2}` : ""}, ${
      item.city
    }, ${item.stateOrProvince}, ${item.postalCode}`;
  }
}

export type AddressAutoCompleteProps = Omit<
  AutoCompleteProps<AddressSuggestion>,
  "displaySuggestion" | "fetchSuggestions"
> & {
  /**
   * The ID of the facilitator account used to make the request to fetch address suggestions
   */
  facilitatorID: string;
  /**
   * A callback function to be called when the user selects a suggestion
   */
  onSuggestionSelected?: (address: AddressSuggestion) => void;
};

export const AddressAutoComplete = ({
  facilitatorID,
  label,
  name,
  onChange,
  onSuggestionSelected,
  value: incomingValue,
  ...rest
}: AddressAutoCompleteProps) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressSuggestion | undefined>();
  const [value, setValue] = useState(incomingValue);

  const fetchSuggestions = useCallback(
    async (search: string) => {
      const {
        /* @ts-expect-error openApiSpecIsWrong - data is an object with a 'suggestions' key which contains the array of AddressSuggestion */
        data: { suggestions }
      } = await fetchAddressSuggestion(search, facilitatorID, {
        selected: selectedAddress,
        source: selectedAddress ? undefined : "all"
      });
      if (suggestions) {
        return suggestions ?? [];
      }
    },
    [facilitatorID, selectedAddress]
  );

  const handleAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      setSelectedAddress(undefined);
      onChange?.(e);
    },
    [onChange]
  );

  const handleSuggestionSelected = useCallback(
    async (address: AddressSuggestion) => {
      if (address.entries && address.entries > 1) {
        setSelectedAddress(address);
      } else {
        setValue(address.addressLine1);
        onSuggestionSelected?.(address);
      }
    },
    [onSuggestionSelected, setSelectedAddress]
  );

  return (
    <AutoComplete<AddressSuggestion>
      displaySuggestion={(item) => (
        <>
          {quickFormat(item)}
          {!!(item.entries && item.entries > 1) && <Icon iconComponent={IconChevronRight} />}
        </>
      )}
      fetchSuggestions={fetchSuggestions}
      label={label}
      name={name}
      onChange={handleAddressChange}
      onSuggestionSelected={handleSuggestionSelected}
      value={value}
      {...rest}
    />
  );
};
