import deepMerge from "@moovfinancial/common/utils/deepMerge";
import { LiveMoovAPIClient } from "./api";
import { ApiKey, CreateApiKey, CreatedApiKey, defaultCreateApiKey } from "./apiKeys.model";
import { APIResponse } from "./request";

export interface ApiKeysAPI {
  /** Lists all api keys for an account */
  list(facilitatorID: string, applicationID: string): APIResponse<ApiKey[]>;
  /** Gets data for an api key */
  get(facilitatorID: string, applicationID: string, appKeyID: string): APIResponse<ApiKey>;
  /** Creates an api key */
  create(
    facilitatorID: string,
    applicationID: string,
    apiKey: CreateApiKey
  ): APIResponse<CreatedApiKey>;
  /** Updates an existing api key */
  update(
    facilitatorID: string,
    applicationID: string,
    apiKey: Pick<ApiKey, "applicationKeyID" | "name" | "description">
  ): APIResponse<ApiKey>;
  /** Deletes an existing api key */
  delete(facilitatorID: string, applicationID: string, appKeyID: string): APIResponse<null>;
}

export class LiveApiKeysAPI implements ApiKeysAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(facilitatorID: string, applicationID: string): APIResponse<ApiKey[]> {
    const [result, err] = await this._client.request<ApiKey[]>(
      `/applications/${applicationID}/keys`,
      {
        xAccountID: facilitatorID
      }
    );
    if (!result) return [undefined, err];
    const apiKeys = result;
    const apiKeysArray = apiKeys.length
      ? apiKeys.map((k) => deepMerge(defaultCreateApiKey, k))
      : apiKeys;
    return [apiKeysArray, err];
  }

  async get(facilitatorID: string, applicationID: string, appKeyID: string): APIResponse<ApiKey> {
    const [result, err] = await this._client.request<ApiKey>(
      `/applications/${applicationID}/keys/${appKeyID}`,
      {
        xAccountID: facilitatorID
      }
    );
    if (!result) return [undefined, err];
    const apiKey = deepMerge(defaultCreateApiKey, result);
    return [apiKey, err];
  }

  async create(
    facilitatorID: string,
    applicationID: string,
    apiKey: CreateApiKey
  ): APIResponse<CreatedApiKey> {
    const [result, err] = await this._client.request<CreatedApiKey>(
      `/applications/${applicationID}/keys`,
      {
        method: "POST",
        xAccountID: facilitatorID,
        json: apiKey
      }
    );
    if (!result) return [undefined, err];
    const createdApiKey = deepMerge(defaultCreateApiKey, result);
    return [createdApiKey, err];
  }

  async update(
    facilitatorID: string,
    applicationID: string,
    apiKey: Pick<ApiKey, "applicationKeyID" | "name" | "description">
  ): APIResponse<ApiKey> {
    const [result, err] = await this._client.request<ApiKey>(
      `/applications/${applicationID}/keys/${apiKey.applicationKeyID}`,
      {
        method: "PUT",
        xAccountID: facilitatorID,
        json: apiKey
      }
    );
    if (!result) return [undefined, err];
    const updatedApiKey = deepMerge(defaultCreateApiKey, result);
    return [updatedApiKey, err];
  }

  async delete(facilitatorID: string, applicationID: string, appKeyID: string): APIResponse<null> {
    const [_result, err] = await this._client.request<null>(
      `/applications/${applicationID}/keys/${appKeyID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [undefined, err];
  }
}
