export const defaultShouldPrune = (key: string, value: any): boolean =>
  value === null || value === undefined || value === "";

/**
 * Recursively travels an object and deletes any properties with null, undefined, or empty string values.
 * This behavior can be modified with the `shouldPrune` argument
 * @param input The object to be pruned
 * @param shouldPrune A function that returns true if the given property should be deleted from the object
 * @returns A new object with all prune-able properties deleted
 */
export default function pruneObject(
  input: any,
  shouldPrune: (val: any, key: string) => boolean = defaultShouldPrune
) {
  const object = JSON.parse(JSON.stringify(input));

  const deepClean = (obj: any) => {
    for (const key in obj) {
      const value = obj[key];
      const isPruneable = shouldPrune(key, value);
      const isArray = Array.isArray(value);
      const isObject = typeof value === "object" && value !== null;

      if (isPruneable) {
        delete obj[key];
      } else if (isArray) {
        value.forEach((entry: any) => {
          if (typeof entry === "object" && entry !== null) deepClean(entry);
        });
      } else if (isObject) {
        deepClean(value);
        if (!Object.keys(value).length) delete obj[key];
      }
    }
  };

  deepClean(object);
  return object;
}
