import { PaginationCursor } from "api/v2/common.model";
import {
  AccountUnderwriting,
  CardVolumeDistribution,
  EnrichedAccount,
  EnrichedUnderwriting,
  Fulfillment,
  RiskLevel,
  Underwriting,
  UnderwritingAdmin,
  UnderwritingDocument,
  UnderwritingFilter,
  UnderwritingStatements,
  UnderwritingStatus,
  VolumeByCustomerType
} from "api/v2/underwriting.model";
import calculate from "helpers/underwriting";
import { LiveMoovAPIClient } from "./api";
import { RequestError } from "./request";

export interface UnderwritingAPI {
  /** Get underwriting record. */
  get(
    accountID: string,
    facilitatorID: string
  ): Promise<[AccountUnderwriting | undefined, RequestError | undefined]>;

  /** Get admin underwriting record. */
  getAdmin(
    accountID: string
  ): Promise<[EnrichedUnderwriting | undefined, RequestError | undefined]>;

  /** Lists underwriting records. */
  list(
    filter?: UnderwritingFilter,
    cursor?: PaginationCursor
  ): Promise<[Underwriting[] | undefined, RequestError | undefined]>;

  //Initiate the Underwriting process
  initiate(
    accountID: string,
    facilitatorID: string
  ): Promise<[Underwriting | undefined, RequestError | undefined]>;

  //Updated Underwriting Admin
  updateAdmin(
    facilitatorID: string,
    accountID: string,
    payload: Partial<UnderwritingAdmin>
  ): Promise<[Underwriting | undefined, RequestError | undefined]>;

  put(
    accountID: string,
    facilitatorID: string,
    payload: AccountUnderwriting
  ): Promise<[AccountUnderwriting | undefined, RequestError | undefined]>;

  //put underwriting statements
  putStatements(
    accountID: string,
    facilitatorID: string,
    underwritingID: string,
    payload: UnderwritingStatements
  ): Promise<[UnderwritingStatements | undefined, RequestError | undefined]>;
}

export class LiveUnderwritingAPI implements UnderwritingAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(
    accountID: string,
    facilitatorID: string
  ): Promise<[AccountUnderwriting | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(`/accounts/${accountID}/underwriting`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async getAdmin(
    accountID: string
  ): Promise<[EnrichedUnderwriting | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/dashboard/admin/accounts/${accountID}/underwriting`
    );
    if (err) return [undefined, err];
    if (result === undefined) return [result, undefined];
    const underwritings: Underwriting = toEnrichedUnderwriting(
      result.underwritings,
      result.accounts
    );
    const enrichedUnderwriting: EnrichedUnderwriting = {
      ...underwritings,
      history: result.underwritings.history,
      statements: result.underwritings.statements
    };

    if (result.underwritings.statements.rows && result.underwritings.statements.rows.length > 1) {
      result.underwritings.statements.rows = calculate.sortStatementRows(
        result.underwritings.statements.rows
      );
    }
    return [enrichedUnderwriting, undefined];
  }

  async list(
    filter?: UnderwritingFilter,
    cursor?: PaginationCursor
  ): Promise<[Underwriting[] | undefined, RequestError | undefined]> {
    let query;
    if (filter || cursor) {
      query = { ...filter, ...cursor };
    }
    // Get the underwritings and the accounts
    const [result, err] = await this._client.request<any>("/dashboard/underwriting", {
      query
    });
    if (err) return [undefined, err];
    if (result === undefined) return [result, undefined];

    // Translate from backend underwriting to an underwriting enriched with account data
    const underwritings: Underwriting[] = result.underwritings.map((u: any) =>
      toEnrichedUnderwriting(u, result.accounts)
    );
    return [underwritings, undefined];
  }

  async initiate(
    accountID: string,
    facilitatorID: string
  ): Promise<[Underwriting | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/admin/accounts/${accountID}/underwriting`,
      {
        xAccountID: facilitatorID,
        method: "POST"
      }
    );
    return [result, err];
  }

  async updateAdmin(
    facilitatorID: string,
    accountID: string,
    payload: UnderwritingAdmin
  ): Promise<[Underwriting | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/admin/accounts/${accountID}/underwriting`,
      {
        xAccountID: facilitatorID,
        method: "PUT",
        json: payload
      }
    );
    return [result, err];
  }

  async put(
    accountID: string,
    facilitatorID: string,
    payload: AccountUnderwriting
  ): Promise<[AccountUnderwriting | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(`/accounts/${accountID}/underwriting`, {
      xAccountID: facilitatorID,
      method: "PUT",
      json: payload
    });
    return [result, err];
  }

  async putStatements(
    accountID: string,
    facilitatorID: string,
    underwritingID: string,
    payload: UnderwritingStatements
  ): Promise<[UnderwritingStatements | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/admin/accounts/${accountID}/underwriting/${underwritingID}/statements`,
      {
        xAccountID: facilitatorID,
        method: "PUT",
        json: payload
      }
    );
    return [result, err];
  }
}

interface UnenrichedUnderwriting {
  underwritingID: string;
  initiatorAccountID: string;
  accountID: string;
  status: UnderwritingStatus;
  businessMCC: string;
  businessDescription: string;
  averageTransactionSize: number;
  maxTransactionSize: number;
  averageMonthlyTransactionVolume: number;
  fulfillment: Fulfillment;
  riskLevel: RiskLevel;
  createdOn: string;
  updatedOn: string;
  admin?: UnderwritingAdmin;
  files?: UnderwritingDocument[];
  cardVolumeDistribution: CardVolumeDistribution;
  volumeByCustomerType: VolumeByCustomerType;
}

function toEnrichedUnderwriting(
  unenriched: UnenrichedUnderwriting,
  accounts: Record<string, EnrichedAccount>
): Underwriting {
  return {
    underwritingID: unenriched.underwritingID,
    facilitatorAccount: accounts[unenriched.initiatorAccountID],
    connectedAccount: accounts[unenriched.accountID],
    status: unenriched.status,
    businessMCC: unenriched.businessMCC,
    businessDescription: unenriched.businessDescription,
    averageTransactionSize: unenriched.averageTransactionSize,
    maxTransactionSize: unenriched.maxTransactionSize,
    averageMonthlyTransactionVolume: unenriched.averageMonthlyTransactionVolume,
    fulfillment: unenriched.fulfillment,
    riskLevel: unenriched.riskLevel,
    createdOn: unenriched.createdOn,
    updatedOn: unenriched.updatedOn,
    admin: unenriched.admin,
    files: unenriched.files,
    accountID: unenriched.accountID,
    cardVolumeDistribution: unenriched.cardVolumeDistribution,
    volumeByCustomerType: unenriched.volumeByCustomerType
  };
}
