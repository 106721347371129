import { generateGUID } from "helpers/generateGuid";
import { LiveMoovAPIClient } from "./api";
import {
  EnrichedPaymentMethod,
  GetPaymentMethodsReturn
} from "./paymentMethods/paymentMethods.model";
import { APIResponse, RequestError } from "./request";
import {
  GetTransferOptions,
  NewTransfer,
  Refund,
  RefundAmount,
  Reversal,
  Transfer,
  TransferListFilter,
  TransferOptions,
  UpdateTransferMetadata,
  defaultTransferListFilter
} from "./transfers.model";

export interface TransfersAPI {
  list(facilitatorID: string, filter?: TransferListFilter): APIResponse<Transfer[]>;

  get(facilitatorID: string, transferID: string): APIResponse<Transfer>;

  create(
    facilitatorID: string,
    transfer: NewTransfer,
    idempotencyKey: string
  ): APIResponse<Transfer>;

  options(facilitatorID: string, transfer: GetTransferOptions): APIResponse<TransferOptions>;

  updateMetadata(
    facilitatorID: string,
    transferID: string,
    transfer: UpdateTransferMetadata
  ): APIResponse<Transfer>;

  refundTransfer(
    facilitatorID: string,
    transferID: string,
    amount: RefundAmount
  ): Promise<[Refund | undefined, RequestError | undefined, Response | undefined]>;

  reverseTransfer(
    facilitatorID: string,
    transferID: string
  ): Promise<[Reversal | undefined, RequestError | undefined, Response | undefined]>;

  listRefunds(facilitatorID: string, transferID: string): APIResponse<Refund[]>;

  getPaymentMethods(facilitatorID: string, connectedAccountID: string): GetPaymentMethodsReturn;
}

export class LiveTransfersAPI implements TransfersAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    filter: TransferListFilter = defaultTransferListFilter
  ): Promise<[Transfer[] | undefined, RequestError | undefined]> {
    const parsedFilters = filter as Record<string, string>;
    if (filter.startDateTime) parsedFilters.startDateTime = filter.startDateTime.toISOString();
    else delete parsedFilters.startDateTime;
    if (filter.endDateTime) parsedFilters.endDateTime = filter.endDateTime.toISOString();
    else delete parsedFilters.endDateTime;
    if (!filter.status) delete parsedFilters.status;
    if (!filter.accountIDs) delete parsedFilters.accountIDs;

    const [result, err] = await this._client.request<Transfer[]>("/transfers", {
      query: {
        ...defaultTransferListFilter,
        ...parsedFilters
      },
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async get(
    facilitatorID: string,
    transferID: string
  ): Promise<[Transfer | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Transfer>(`/transfers/${transferID}`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async create(
    facilitatorID: string,
    transfer: NewTransfer,
    idempotencyKey: string
  ): Promise<[Transfer | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Transfer>("/transfers", {
      xAccountID: facilitatorID,
      method: "POST",
      json: transfer,
      headers: {
        "x-idempotency-Key": idempotencyKey,
        "x-wait-for": "rail-response"
      }
    });
    return [result, err];
  }

  async options(
    facilitatorID: string,
    transfer: GetTransferOptions
  ): Promise<[TransferOptions | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<TransferOptions>("/transfer-options", {
      method: "POST",
      xAccountID: facilitatorID,
      json: transfer
    });
    return [result, err];
  }

  async updateMetadata(
    facilitatorID: string,
    transferID: string,
    transfer: UpdateTransferMetadata
  ): Promise<[Transfer | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Transfer>(`/transfers/${transferID}`, {
      xAccountID: facilitatorID,
      method: "PATCH",
      json: transfer
    });
    return [result, err];
  }

  async refundTransfer(
    facilitatorID: string,
    transferID: string,
    amount: RefundAmount
  ): Promise<[Refund | undefined, RequestError | undefined, Response | undefined]> {
    const idempotencyKey = generateGUID();
    const [result, err, resp] = await this._client.request<Refund>(
      `/transfers/${transferID}/refunds`,
      {
        xAccountID: facilitatorID,
        method: "POST",
        headers: {
          "x-idempotency-Key": idempotencyKey
        },
        json: amount
      }
    );
    return [result, err, resp];
  }

  async reverseTransfer(
    facilitatorID: string,
    transferID: string
  ): Promise<[Reversal | undefined, RequestError | undefined, Response | undefined]> {
    const idempotencyKey = generateGUID();
    const [result, err, resp] = await this._client.request<Reversal>(
      `/transfers/${transferID}/reversals`,
      {
        xAccountID: facilitatorID,
        method: "POST",
        headers: {
          "x-idempotency-Key": idempotencyKey
        }
      }
    );
    return [result, err, resp];
  }

  async listRefunds(
    facilitatorID: string,
    transferID: string
  ): Promise<[Refund[] | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Refund[]>(`/transfers/${transferID}/refunds`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async getPaymentMethods(
    facilitatorID: string,
    connectedAccountID: string
  ): GetPaymentMethodsReturn {
    const [result, err] = await this._client.request<{
      paymentMethods: EnrichedPaymentMethod[];
    }>(`/dashboard/transfers/source/paymentMethods/${connectedAccountID}`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }
}
