import { useContext } from "react";
import { FormGroup } from "@moovfinancial/cargo";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { VerificationLockedBanner } from "../components/AccountLockedBanner";
import { BusinessTypeSelector } from "../components/BusinessTypeSelector";
import { EINField } from "../components/EINField";
import { IndustrySelector } from "../components/IndustrySelector";
import { InputField } from "../components/InputField";
import { PrefillAlert } from "../components/PrefillAlert";
import { PrimaryRegulatorSelector } from "../components/PrimaryRegulatorSelector";
import { Step } from "components/dialog/StepModal";
import Toaster from "components/toaster/Toaster";
import { Text } from "cleanComponents/Typography/Text";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "./BusinessDetails.module.scss";

export const BusinessDetails = () => {
  const { account, capabilityRequirements, errorMessages } = useContext(AccountSetupContext);

  const { accountLocks, verificationLocked } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);

  return (
    <Step className={styles.businessDetails}>
      {verificationLocked && <VerificationLockedBanner />}
      {errorMessages && <Toaster toastInput={errorMessages} duration={4000} />}
      <PrefillAlert />
      <Text textStyle="paragraph-m-regular">
        All fields, unless labeled optional, are required to create an account and enable
        capabilities. However some information can be provided later.
      </Text>
      <div className={styles.grid}>
        <InputField
          label="Business name"
          name="legalBusinessName"
          value={account.profile?.business?.legalBusinessName}
          warn={!!accountRequirements.profile?.business?.legalBusinessName}
          isLocked={!!accountLocks?.profile?.business?.legalBusinessName}
          required
        />
        <InputField
          label="Doing business as"
          name="doingBusinessAs"
          value={account.profile?.business?.doingBusinessAs}
          warn={!!accountRequirements.profile?.business?.doingBusinessAs}
          isLocked={!!accountLocks?.profile?.business?.doingBusinessAs}
          optional
        />
        <BusinessTypeSelector
          warn={!!accountRequirements.profile?.business?.businessType}
          isLocked={!!accountLocks?.profile?.business?.businessType}
        />
        <EINField
          warn={!!accountRequirements?.profile?.business?.taxID?.ein?.number}
          locked={!!accountLocks?.profile?.business?.taxID?.ein?.number}
        />
        <InputField
          label="Foreign ID"
          name="foreignID"
          optional
          value={account.foreignID || ""}
          dispatchType="account"
          warn={!!accountRequirements.foreignID}
          isLocked={!!accountLocks.foreignID}
        />
        <FormGroup>
          <IndustrySelector
            isLocked={!!accountLocks?.profile?.business?.industryCodes}
            isMissing={!!accountRequirements.profile?.business?.industryCodes}
          />
        </FormGroup>
        <PrimaryRegulatorSelector
          warn={!!accountRequirements.profile?.business?.primaryRegulator}
          isLocked={!!accountLocks?.profile?.business?.primaryRegulator}
        />
      </div>
    </Step>
  );
};
