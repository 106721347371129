import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";
import styles from "./BaseButton.module.scss";

export interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The text to be shown in the button. If passed along with children, it is ignored.
   */
  label?: string;
  /**
   * Should the button be disabled or non-focusable? Defaults to `false`.
   */
  disabled?: boolean;
  /**
   * Whether or not the button is loading.
   */
  isLoading?: boolean;
  /**
   * Whether or not to flex center the button content, horizontally and vertically.
   *
   * Helpful to align text and icons.
   */
  centered?: boolean;
  /**
   * Optional className to add to the <button> element.
   */
  className?: string;
  /**
   * Optional ref to pass to the component.
   */
  ref?: React.Ref<HTMLButtonElement>;
}

/**
 * BaseButton is a barebones button that acts as the base of all buttons.
 */
export const BaseButton = ({
  label,
  disabled = false,
  isLoading = false,
  centered = false,
  className,
  children,
  ref,
  ...rest
}: BaseButtonProps) => {
  const isDisabled = disabled || isLoading;
  return (
    <button
      className={clsx(styles.baseButton, centered && styles.centered, className)}
      disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
      ref={ref}
      data-testid="basebutton"
      {...rest}
    >
      {children ?? label}
    </button>
  );
};
