import { LiveMoovAPIClient } from "./api";
import { Wallet } from "./paymentMethods/wallet.model";
import { RequestError } from "./request";
import {
  WalletTransaction,
  WalletTransactionsFilter,
  defaultWalletTransactionsFilter
} from "./wallets.model";

export interface WalletAPI {
  /** Lists the wallets for an account. */
  list(
    facilitatorID: string,
    accountID: string
  ): Promise<[Wallet[] | undefined, RequestError | undefined]>;

  /** Gets the details of a wallet. */
  get(
    facilitatorID: string,
    accountID: string,
    walletID: string
  ): Promise<[Wallet | undefined, RequestError | undefined]>;

  /** Lists the transactions that have gone through a wallet. */
  listTransactions(
    facilitatorID: string,
    accountID: string,
    walletID: string,
    filter?: WalletTransactionsFilter
  ): Promise<[WalletTransaction[] | undefined, RequestError | undefined]>;

  /** Gets the details of a wallet transaction. */
  getTransaction(
    facilitatorID: string,
    accountID: string,
    walletID: string,
    transactionID: string
  ): Promise<[WalletTransaction | undefined, RequestError | undefined]>;
}

export class LiveWalletAPI implements WalletAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    accountID: string
  ): Promise<[Wallet[] | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Wallet[]>(`/accounts/${accountID}/wallets`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async get(
    facilitatorID: string,
    accountID: string,
    walletID: string
  ): Promise<[Wallet | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Wallet>(
      `/accounts/${accountID}/wallets/${walletID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async listTransactions(
    facilitatorID: string,
    accountID: string,
    walletID: string,
    filter: WalletTransactionsFilter = defaultWalletTransactionsFilter
  ): Promise<[WalletTransaction[] | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<WalletTransaction[]>(
      `/accounts/${accountID}/wallets/${walletID}/transactions`,
      {
        query: filter,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getTransaction(
    facilitatorID: string,
    accountID: string,
    walletID: string,
    transactionID: string
  ): Promise<[WalletTransaction | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<WalletTransaction>(
      `/accounts/${accountID}/wallets/${walletID}/transactions/${transactionID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
