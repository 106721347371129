import { LiveMoovAPIClient } from "../api";
import { RequestError } from "../request";
import { BankAccountsAPI, LiveBankAccountsAPI } from "./bankAccounts";
import { CardsAPI, LiveCardsAPI } from "./cards";
import { PaymentMethod } from "./paymentMethods.model";

export interface PaymentMethodsAPI {
  /** Retrieve a list of payment methods associated with a Moov account. */
  list(
    facilitatorID: string,
    accountID: string,
    sourceID?: string
  ): Promise<[PaymentMethod[] | undefined, RequestError | undefined]>;

  bankAccounts: BankAccountsAPI;
  cards: CardsAPI;
}

export class LivePaymentMethodsAPI implements PaymentMethodsAPI {
  private _client: LiveMoovAPIClient;
  bankAccounts: BankAccountsAPI;
  cards: CardsAPI;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
    this.bankAccounts = new LiveBankAccountsAPI(client);
    this.cards = new LiveCardsAPI(client);
  }

  async list(
    facilitatorID: string,
    accountID: string,
    sourceID?: string
  ): Promise<[PaymentMethod[] | undefined, RequestError | undefined]> {
    let url = `/accounts/${accountID}/payment-methods`;
    if (sourceID) {
      url += `?sourceID=${sourceID}`;
    }

    const [result, err] = await this._client.request<PaymentMethod[]>(url, {
      xAccountID: facilitatorID
    });

    return [result, err];
  }
}
