"use client";

import clsx from "clsx";
import {
  ComponentProps,
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState
} from "react";
import { ValidationMessage } from "../ValidationMessage";
import { TextInput } from "./TextInput";
import styles from "./InputCharCounter.module.scss";

type InputCharCounterBaseProps<C> = PropsWithChildren<{
  /**
   * The minimum valid length of the input
   */
  minLength?: number;
  /**
   * The maximum valid length of the input
   */
  maxLength: number;
  /**
   * The value of the input
   */
  value?: string;
  /**
   * The base input this component wraps. Defaults to the basic HTML `input` element
   */
  baseInputComponent?: C;
  /**
   * A callback that is called when the validity of the input changes
   */
  onValidityChange?: (valid: boolean) => void;
  /**
   * The class name to apply to the input
   */
  className?: string;
}>;

// Here we extend the props of the component with the props of the component passed in as inputComponent
type InputCharCounterProps<C extends JSXElementConstructor<any>> = ComponentProps<C> &
  InputCharCounterBaseProps<C> & {
    baseInputComponent: C;
  };

export const InputCharCounter = <C extends JSXElementConstructor<any>>({
  value = "", // need to define a default value or React complains
  minLength = 0,
  maxLength = Number.MAX_SAFE_INTEGER,
  baseInputComponent,
  onValidityChange = () => {},
  className,
  ...rest
}: InputCharCounterProps<C>): ReactNode => {
  if (minLength >= maxLength) throw new Error("minLength must be less than maxLength");

  const [hasFocus, setHasFocus] = useState(true);
  const [stickyInvalid, setStickyInvalid] = useState(false);

  const currentLength = value.length;

  const isValid = (() => {
    if (currentLength === 0) return true;
    if (currentLength < minLength) return false;
    if (currentLength > maxLength) return false;
    return true;
  })();

  useEffect(() => {
    onValidityChange(isValid);
  }, [isValid]);

  const errorMessage =
    currentLength < minLength
      ? `Minimum ${minLength} characters required`
      : "Maximum character limit exceeded";

  const InputComponent = baseInputComponent ?? TextInput;

  const onFocus = (...args: any[]) => {
    setHasFocus(true);
    if (rest?.onFocus) rest.onFocus(...args);
  };

  const onBlur = (...args: any[]) => {
    if (!isValid) setStickyInvalid(true);
    if (isValid) setStickyInvalid(false);
    setHasFocus(false);
    if (rest?.onBlur) rest.onBlur(...args);
  };

  const shouldShowValidationErrors = !isValid && (!hasFocus || stickyInvalid);

  return (
    <div className={clsx(styles.charCounterInputWrapper, className)}>
      <InputComponent
        {...rest}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        data-testid="inputCharCounter"
      />
      <div className={styles.inputInfo}>
        {shouldShowValidationErrors && <ValidationMessage message={errorMessage} noMargins />}
        <div
          className={clsx(
            styles.charCounter,
            (rest.disabled || rest.isLocked) && styles.disabled,
            shouldShowValidationErrors && styles.error
          )}
        >
          {currentLength}/{maxLength}
        </div>
      </div>
    </div>
  );
};
