import type { components } from "@moovfinancial/common/types/__generated-types__/api";
import { formatDollars } from "@moovfinancial/common/utils/format/formatCurrency";
import { formatPercentInput } from "@moovfinancial/common/utils/format/formatPercent";
import styles from "./Fee.module.scss";

type Fee = components["schemas"]["Fee"];
type FeeProperties = components["schemas"]["FeeProperties"];

export function Fee({ fee }: { fee: Fee }) {
  function ChargeModel() {
    switch (fee.feeModel) {
      case "fixed":
        return <Standard />;
      case "blended":
        return <Blended />;
    }
  }

  /* Shows the min & max dollar amounts of the charge, if set */
  function MinMax({ feeProperties }: { feeProperties: FeeProperties }) {
    if (!feeProperties.minPerTransaction && !feeProperties.minPerTransaction) return undefined;
    return (
      <div className={styles.minmax}>
        {feeProperties.minPerTransaction && (
          <span>Min: {formatDollars(Number(feeProperties.minPerTransaction))}</span>
        )}
        {feeProperties.maxPerTransaction && (
          <span>Max: {formatDollars(Number(feeProperties.maxPerTransaction))}</span>
        )}
      </div>
    );
  }

  /* ChargeModel = standard */
  function Standard() {
    return (
      <div className={styles.row} key={fee.feeID}>
        <div className={styles.name}>{fee.displayName}</div>
        <div className={styles.right}>
          <div className={styles.price}>
            {formatDollars(Number(fee.feeProperties?.fixedAmount))}
          </div>
          {fee.feeProperties && <MinMax feeProperties={fee.feeProperties} />}
        </div>
      </div>
    );
  }

  /* ChargeModel = percentage */
  function Blended() {
    return (
      <div className={styles.row} key={fee.feeID}>
        <div className={styles.name}>{fee.displayName}</div>
        <div className={styles.right}>
          <div className={styles.price}>
            {formatPercentInput(fee.feeProperties?.variableRate)}
            {fee.feeProperties?.fixedAmount && (
              <>
                {" + "}
                {formatDollars(Number(fee.feeProperties.fixedAmount))}
              </>
            )}
          </div>
          {fee.feeProperties && <MinMax feeProperties={fee.feeProperties} />}
        </div>
      </div>
    );
  }

  return <>{ChargeModel()}</>;
}
