import styles from "./StatementExampleTable.module.scss";

export default function StatementExampleTable({ type = "ACH" }: { type: "ACH" | "instant" }) {
  return (
    <table className={styles.exampleStatement}>
      <caption className={styles.exampleCaption}>Example transaction</caption>
      <tbody>
        <tr className={styles.exampleRow}>
          <td>
            {type === "ACH" ? (
              <>
                <b>
                  MV<span className={styles.highlight}>####</span>
                </b>{" "}
                ACCTVERIFY
              </>
            ) : (
              <>
                RTP deposit from{" "}
                <b>
                  MV<span className={styles.highlight}>####</span>
                </b>
              </>
            )}
          </td>
          <td>$0.01</td>
        </tr>
        <tr className={styles.exampleRow}>
          <td>
            <div className={styles.skeleton} />
          </td>
          <td>
            <strong>
              <div className={styles.skeleton} />
            </strong>
          </td>
        </tr>
        <tr className={styles.spacer}>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
}
