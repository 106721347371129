import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFingerprint } from "@moovfinancial/common/utils/fingerprint";
import { openApi } from "api/OpenApiClient";
import { Session } from "api/Session.model";
import { UserContext } from "./UserContext";

interface ContextProps {
  children: React.ReactNode;
}

type SignOutOptions = {
  saveLocation?: boolean;
  showSessionExpiredMessageOnLogin?: boolean;
};

export interface SessionContext {
  fetchSession: (refresh?: boolean) => void;
  signOut: (options?: SignOutOptions) => void;
  session: Session | null;
}

/**
 * A single source of truth for the user's session
 *
 * This context might evolve and swallow the current UserContext, FacilitatorContext and AccountContext
 *
 */
export const SessionContext = createContext<SessionContext>({
  fetchSession: () => {},
  signOut: () => {},
  session: null
});

export default function SessionContextProvider({ children }: ContextProps) {
  // it's quite obvious that this context is very coupled with the UserContext, so in the future we will merge them
  // once we extract the non-logged-in parts of `UserContext` to a new context
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [session, setSession] = useState<Session | null>(null);

  const refreshSession = async () =>
    await openApi.POST("/session-refresh", {
      body: {
        fingerprint: await getFingerprint()
      }
    });

  const fetchSession = async (refresh: boolean = false) => {
    const { data, error } = refresh ? await refreshSession() : await openApi.GET("/session");
    if (error || !data) {
      navigate("/signin", { replace: true, state: { from: location } });
      return;
    }
    // this casting fixes the fact that OpenAPI spec is wrong and says we can have a session w/o userID, expiresOn or globalExpiresOn 😅
    const session = data as Session;
    setUser(session);
    setSession(session);
  };

  // Signs the user out of the Dashboard
  const signOut = async (
    { saveLocation, showSessionExpiredMessageOnLogin }: SignOutOptions = {
      saveLocation: false,
      showSessionExpiredMessageOnLogin: false
    }
  ) => {
    localStorage.moovUser = session?.userID;
    if (showSessionExpiredMessageOnLogin) {
      localStorage.moovExpired = true;
    }
    if (saveLocation) {
      // Save the current URL in localStorage so we can redirect back to it after sign in
      localStorage.moovPreviousScreen = window.location.pathname;
    } else {
      delete localStorage.moovPreviousScreen;
    }
    await openApi.DELETE("/session");
    setSession(null);
  };

  return (
    <SessionContext.Provider
      value={{
        fetchSession,
        signOut,
        session
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
