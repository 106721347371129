import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const win = window as any;

function useTracking(trackingId: string) {
  const location = useLocation();

  useEffect(() => {
    if (!win.gtag) return;
    if (!trackingId) return;
    const locationPathname = location.pathname;
    let trackingObject;
    if (locationPathname.startsWith("/sign-in/")) {
      trackingObject = {
        page_path: "/sign-in",
        page_title: "Sign in"
      };
    } else if (
      locationPathname.startsWith("/accounts/") &&
      locationPathname.endsWith("/transfers")
    ) {
      trackingObject = {
        page_path: "/accounts/:accountId/transfers",
        page_title: "Account transfers"
      };
    } else if (
      locationPathname.startsWith("/accounts/") &&
      locationPathname.endsWith("/capabilities")
    ) {
      trackingObject = {
        page_path: "/accounts/:accountId/capabilities",
        page_title: "Account capabilities"
      };
    } else if (locationPathname.startsWith("/accounts/") && locationPathname.endsWith("/people")) {
      trackingObject = {
        page_path: "/accounts/:accountId/people",
        page_title: "Account representatives"
      };
    } else if (locationPathname.startsWith("/accounts/")) {
      trackingObject = {
        page_path: "/accounts/:accountId",
        page_title: "Account detail"
      };
    } else {
      trackingObject = { page_path: location.pathname };
    }
    win.gtag("config", trackingId, trackingObject);

    // Do not want to include window in dependency array
    // eslint-disable-next-line
  }, [trackingId, location]);

  // the `yarn build` minification step will remove this conditional
  if (process.env.NODE_ENV !== "production") {
    win[`ga-disable-${trackingId}`] = true;
  }
}

export default useTracking;
