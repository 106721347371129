import clsx from "clsx";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { FormGroup } from "@moovfinancial/cargo";
import { DateObject } from "api/Account.model";
import { Input, Label } from "./Form";
import styles from "./Form.module.scss";

interface DateProps {
  day?: string;
  month?: string;
  year?: string;
  date?: Partial<DateObject>;
  dirty?: boolean;
}

const formReducer = (state: DateProps, newState: DateProps) => {
  return { ...state, ...newState };
};

const formatDateDigits = (value: string | number | undefined, unit: "month" | "day" | "year") => {
  if (value === 0) {
    return "";
  }

  const num = typeof value === "number" ? value.toString() : value;

  if (num) {
    return num.padStart(unit === "year" ? 4 : 2, "0");
  } else {
    return "";
  }
};

export interface DateInputProps {
  label: string;
  optional?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  date?: Partial<DateObject>;
  name: string;
  setDate: (date: DateObject) => void;
  labelClassName?: string;
  dateRowClassName?: string;
  inputContainerClassName?: string;
  showSecretDate?: boolean;
  isLocked?: boolean;
  warning?: string;
  error?: string;
}

const initialState: DateProps = {
  day: "",
  month: "",
  year: "",
  dirty: false
};

function DateInput({
  label,
  name,
  date,
  setDate,
  className,
  optional,
  required,
  disabled,
  labelClassName,
  dateRowClassName,
  inputContainerClassName,
  showSecretDate,
  isLocked = false,
  warning,
  error
}: DateInputProps) {
  const [localState, localDispatch] = useReducer(formReducer, initialState);
  const [isDayInvalid, setIsDayInvalid] = useState(false);
  const [isMonthInvalid, setIsMonthInvalid] = useState(false);
  const [isYearInvalid, setIsYearInvalid] = useState(false);
  const inputContainerRef = useRef() as React.RefObject<HTMLDivElement>;
  const dateIsEmpty = !date || (!date.day && !date.month && !date.year);
  const [isInputContainerFocused, setIsInputContainerFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);
  const [warningMessage, setWarningMessage] = useState(warning);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    setWarningMessage(warning);
  }, [warning]);

  useEffect(() => {
    // Update localState to match date prop
    if (date && date !== localState.date) {
      localDispatch({
        month: formatDateDigits(date.month, "month"),
        day: formatDateDigits(date.day, "day"),
        year: formatDateDigits(date.year, "year"),
        date: date
      });
    }
    //eslint-disable-next-line
  }, [date]);

  const getErrorMessage = () => {
    if (isYearInvalid) return setErrorMessage("Please select a year in the past, after 1900.");
    if (isMonthInvalid) return setErrorMessage("Please select a valid month.");
    if (isDayInvalid) return setErrorMessage("Please select a valid day.");
    setErrorMessage(undefined);
  };

  const getWarningMessage = () => {
    if (date && date.day && date.month && date.year) {
      setWarningMessage(undefined);
    } else {
      setWarningMessage(warning);
    }
  };

  const onBlurHandler = () => {
    getErrorMessage();
    getWarningMessage();
    const day = formatDateDigits(localState.day, "day");
    const month = formatDateDigits(localState.month, "month");
    const year = formatDateDigits(localState.year, "year");
    const fullDate = {
      day: localState.day ? parseInt(localState.day) : undefined,
      month: localState.month ? parseInt(localState.month) : undefined,
      year: localState.year ? parseInt(localState.year) : undefined
    };

    localDispatch({
      day,
      month,
      year,
      date: fullDate
    });
    setDate(fullDate);
  };

  const focusPreviousInput = () => {
    const currentInput = document.activeElement as HTMLInputElement;
    const inputEls = Array.from(inputContainerRef.current!.querySelectorAll("input"));
    const currentInputIndex = inputEls.indexOf(currentInput);
    if (currentInputIndex === -1) return;
    if (currentInputIndex > 0) {
      const previousInput = inputEls[currentInputIndex - 1];
      previousInput.focus();
      previousInput.setSelectionRange(previousInput.value.length, previousInput.value.length);
    }
  };

  const focusNextInput = () => {
    const currentInput = document.activeElement as HTMLInputElement;
    const inputEls = Array.from(inputContainerRef.current!.querySelectorAll("input"));
    const currentInputIndex = inputEls.indexOf(currentInput);
    if (currentInputIndex === -1) return;
    if (currentInputIndex < inputEls.length - 1) {
      const nextInput = inputEls[currentInputIndex + 1];
      nextInput.focus();
      nextInput.setSelectionRange(0, 0);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onBlurHandler();
    const target = e.target as HTMLInputElement;
    if (e.key === "ArrowLeft" && target.selectionStart === 0) {
      e.preventDefault();
      focusPreviousInput();
    }
    if (e.key === "ArrowRight" && target.selectionEnd === target.value.length) {
      e.preventDefault();
      focusNextInput();
    }
    if (e.key === "Backspace" && target.value.length === 0) {
      focusPreviousInput();
    }
  };

  const onBeforeInputHandler = (e: any) => {
    if (isNaN(e.data)) {
      e.preventDefault();
    }
  };

  function checkMonthValidity(e: any) {
    const month = parseInt(e.target.value);

    if (month < 1 || month > 12) {
      setIsMonthInvalid(true);
      e.target.setCustomValidity("Please select a correct month.");
    } else {
      setIsMonthInvalid(false);
      e.target.setCustomValidity("");
    }
  }
  function monthAutoAdvance(e: any) {
    const month = parseInt(e.target.value);
    if (month > 1 || e.target.value.length >= 2) setTimeout(focusNextInput, 1);
  }
  function checkDayValidity(e: any) {
    const day = parseInt(e.target.value);

    if (day < 1 || day > 31) {
      setIsDayInvalid(true);
      e.target.setCustomValidity("Please select a correct day.");
    } else {
      setIsDayInvalid(false);
      e.target.setCustomValidity("");
    }
  }
  function dayAutoAdvance(e: any) {
    const day = parseInt(e.target.value);
    if (day > 3 || e.target.value.length >= 2) setTimeout(focusNextInput, 1);
  }
  function checkYearValidity(e: any) {
    const year = parseInt(e.target.value);
    const date = new Date(Date.parse(e.target.value));
    const newDate = new Date();

    if (date > newDate) {
      setIsYearInvalid(true);
      e.target.setCustomValidity("Please select a date in the past.");
    } else if (year < 1900) {
      setIsYearInvalid(true);
      e.target.setCustomValidity("Please select a year after 1900.");
    } else {
      setIsYearInvalid(false);
      e.target.setCustomValidity("");
    }
  }

  const onChangeMonth = useCallback((e: any) => {
    checkMonthValidity(e);
    if (e.target.value !== localState.month || e.target.value === "") {
      localDispatch({ month: e.target.value, dirty: true });
      monthAutoAdvance(e);
    }
  }, []);

  const onChangeDay = useCallback((e: any) => {
    checkDayValidity(e);
    if (e.target.value !== localState.day || e.target.value === "") {
      localDispatch({ day: e.target.value, dirty: true });
      dayAutoAdvance(e);
    }
  }, []);

  const onChangeYear = useCallback((e: any) => {
    checkYearValidity(e);
    if (e.target.value !== localState.year || e.target.value === "") {
      localDispatch({ year: e.target.value, dirty: true });
    }
  }, []);

  return (
    <div>
      <Label label={label} optional={optional} className={labelClassName} />
      <div
        data-input-container
        className={clsx(
          styles.daterow,
          dateRowClassName,
          !!warningMessage && !errorMessage && styles.dateRowWarned,
          !!errorMessage && styles.dateRowErrored
        )}
      >
        <FormGroup
          as="label"
          className={clsx(isLocked && styles.isLockedDatePicker, className)}
          warningMessage={warningMessage}
          errorMessage={errorMessage}
        >
          <div
            ref={inputContainerRef}
            onBlurCapture={() => setIsInputContainerFocused(false)}
            onFocusCapture={() => setIsInputContainerFocused(true)}
            className={clsx(
              styles.dateInputContainer,
              inputContainerClassName,
              !!warningMessage && !errorMessage && styles.dateRowWarned,
              !!errorMessage && styles.dateRowErrored
            )}
          >
            <Input
              className={styles.dateInputUnit}
              type="text"
              inputMode="numeric"
              onChange={onChangeMonth}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDownHandler}
              onBeforeInput={onBeforeInputHandler}
              value={localState.month}
              autoComplete="off"
              size={5}
              maxLength={2}
              id={`${name}month`}
              name={`${name}month`}
              required={required}
              disabled={disabled}
              placeholder={showSecretDate && dateIsEmpty && !isInputContainerFocused ? "••" : "mm"}
              pattern="\d*"
            />
            <span className={styles.dateSlash}>/</span>
            <Input
              className={styles.dateInputUnit}
              type="text"
              inputMode="numeric"
              onChange={onChangeDay}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDownHandler}
              onBeforeInput={onBeforeInputHandler}
              value={localState.day}
              autoComplete="off"
              maxLength={2}
              id={`${name}day`}
              name={`${name}day`}
              required={required}
              disabled={disabled}
              placeholder={showSecretDate && dateIsEmpty && !isInputContainerFocused ? "••" : "dd"}
              pattern="\d*"
            />
            <span className={styles.dateSlash}>/</span>
            <Input
              className={styles.dateInputUnit}
              type="text"
              inputMode="numeric"
              onChange={onChangeYear}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDownHandler}
              onBeforeInput={onBeforeInputHandler}
              value={localState.year}
              autoComplete="off"
              size={10}
              maxLength={4}
              id={`${name}year`}
              name={`${name}year`}
              required={required}
              disabled={disabled}
              placeholder={
                showSecretDate && dateIsEmpty && !isInputContainerFocused ? "••••" : "yyyy"
              }
              pattern="\d*"
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
}

export const displayDate = (date: string, props?: any) => {
  const dateRef = new Date(date);
  dateRef.setDate(dateRef.getDate() + 1);
  const options: Intl.DateTimeFormatOptions = props || {
    dateStyle: "long"
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateRef));
};

export const displayDateTime = (date: string, props?: any) => {
  const dateRef = new Date(date);
  const options: Intl.DateTimeFormatOptions = props || {
    dateStyle: "short",
    timeStyle: "short"
  };
  return new Intl.DateTimeFormat("en-US", options).format(dateRef);
};

export default DateInput;
