import { LiveMoovAPIClient } from "./api";
import { Notifications } from "./notifications.model";
import { RequestError } from "./request";

export interface NotificationsAPI {
  /** Get underwriting record. */
  get(): Promise<[Notifications | undefined, RequestError | undefined]>;
}

export class LiveNotificationsAPI implements NotificationsAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(): Promise<[Notifications | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>("/dashboard/notifications");
    return [result, err];
  }
}
