export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function retryRequest(
  apiRequest: () => Promise<any>,
  attempts: number = 4
): Promise<any> {
  await wait(800 / attempts);
  try {
    return await apiRequest();
  } catch (err) {
    if (attempts > 0) {
      return retryRequest(apiRequest, attempts - 1);
    }
    throw err;
  }
}

export async function retryRequestIf(
  apiRequest: () => Promise<any | null>,
  shouldRetry: (result: any | null) => boolean,
  attempts: number = 4
): Promise<any | null> {
  let result = null;
  let error = null;
  let retry = false;

  try {
    result = await apiRequest();
    retry = shouldRetry(result);
  } catch (err) {
    error = err;
    retry = true;
  }

  if (retry) {
    if (attempts > 0) {
      await wait(800 / attempts);
      return retryRequestIf(apiRequest, shouldRetry, attempts - 1);
    } else {
      if (error) {
        throw error;
      } else {
        return result;
      }
    }
  } else {
    return result;
  }
}
