const MOCK_SERVER_BASE_URL = "http://127.0.0.1:4010";
const FULL_URL_REGEX = /(.+\/api\/)(.+)/g;
const UNMOCKED_ENDPOINTS = [
  "/accounts/.*/connections",
  "/admin/",
  "/applications/",
  "/auth/",
  "/dashboard/",
  "/event-types/",
  "/ops/",
  "/roles/",
  "/session",
  "/signup/",
  "/users/",
  "/webhooks/"
];

/**
 * Takes a given request url and modifies it to go through the Prism mock server when appropriate
 * See prism-mock-server/README.md for more info
 */
export default function maybeMockWithPrism(base: string, endpoint: string): string {
  let fullUrl = `${base}${endpoint}`;
  if (localStorage.useMockServer) {
    const isMockedUrl = !UNMOCKED_ENDPOINTS.find((pattern) => endpoint.match(pattern));
    if (isMockedUrl) fullUrl = `${MOCK_SERVER_BASE_URL}${endpoint}`;
  }
  return fullUrl;
}

export const maybeMockUrlWithPrism = (fullUrl: string): string => {
  let newUrl = fullUrl;
  if (localStorage.useMockServer) {
    const isMockedUrl = !UNMOCKED_ENDPOINTS.find((pattern) => fullUrl.match(pattern));
    if (isMockedUrl) newUrl = fullUrl.replace(FULL_URL_REGEX, MOCK_SERVER_BASE_URL + "/$2");
  }
  return newUrl;
};
