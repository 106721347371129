import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Account } from "api/Account.model";
import { AccountUnderwritingStatus } from "api/v2";
import { APIContext } from "./APIContext";
import { FacilitatorContext } from "./FacilitatorContext";
import { UserContext } from "./UserContext";

interface ContextProps {
  children: React.ReactNode;
}

type StatusRecord = {
  underwriting: AccountUnderwritingStatus | null;
};

export interface AccountContextType {
  /* The account actively viewed by the user. Mostly used within AccountDetail pages */
  account: Account | null;
  /* Set to true if AccountContext encounters an error loading an account */
  accountError: boolean;
  /* Status map of statuses of account actively viewed by the user */
  accountStatus: StatusRecord;
  /* Loads the account with the given account ID. Fetches the facilitator first */
  setAccountByID: (id: string, propagateErrors?: boolean) => Promise<void>;
  /* Re-fetches the account loaded into context */
  refreshAccount: () => void;
}

export const AccountContext = createContext<AccountContextType>({
  account: null,
  accountError: false,
  accountStatus: { underwriting: null },
  setAccountByID: () => Promise.resolve(),
  refreshAccount: () => {}
});

/** Single source of truth for the account currently displayed to the user. */
export default function AccountContextProvider({ children }: ContextProps) {
  const [account, setAccount] = useState<Account | null>(null);
  const [accountError, setAccountError] = useState<boolean>(false);
  const [accountStatus, setAccountStatus] = useState<StatusRecord>({
    underwriting: null
  });
  const { activeUserAccountID } = useContext(UserContext);
  const { setFacilitatorByID, facilitatorID } = useContext(FacilitatorContext);
  const { moov } = useContext(APIContext);

  useEffect(() => {
    if (account?.accountID) {
      moov.underwriting.get(account?.accountID, facilitatorID).then(([result, error]) => {
        if (error) return;
        if (result) setAccountStatus({ underwriting: result.status || "notRequested" });
        return;
      });
    }
  }, [account]);

  // Given an account id, load the entire account into AccountContext state
  const setAccountByID = useCallback(
    async (id: string, propagateErrors = false) => {
      if (id === account?.accountID) return;
      if (id === "onboarding") return; // allows the onboarding routes to be under accounts
      // Reset account state
      setAccount(null);
      setAccountError(false);
      if (!id) return;
      // Force moov admins to fetch facilitator id before fetching account
      let foundFacilitatorID = activeUserAccountID;
      if (activeUserAccountID === "moov-admin") {
        const [result, error] = await moov.accounts.listConnections(id);
        if (error) {
          setFacilitatorByID("");
          toast("Error loading facilitator");
          setAccountError(true);
          if (propagateErrors) throw error;
        } else {
          if (result && result.length) foundFacilitatorID = result[0].accountID;
          else if (result && result.length === 0) foundFacilitatorID = id;
          else throw new Error(`No facilitator found for account ${id}`);
          // Update facilitator context
          setFacilitatorByID(foundFacilitatorID);
        }
      }
      // Fetch full account
      if (foundFacilitatorID) {
        let result, error;
        if (activeUserAccountID === "moov-admin") {
          [result, error] = await moov.accounts.adminGet(id);
        } else {
          [result, error] = await moov.accounts.get(foundFacilitatorID, id);
        }
        if (error) {
          toast("Error loading account");
          setAccountError(true);
          if (propagateErrors) throw error;
        } else if (result) {
          setAccount(result);
        }
      }
    },
    [facilitatorID, account, activeUserAccountID]
  );

  const refreshAccount = useCallback(() => {
    if (!account) return;
    moov.accounts.get(facilitatorID, account?.accountID).then(([result, error]) => {
      if (error) {
        toast("Error refreshing account");
        return;
      }
      if (result) setAccount(result);
    });
  }, [account, activeUserAccountID, facilitatorID]);

  return (
    <AccountContext.Provider
      value={{
        account,
        accountError,
        accountStatus,
        setAccountByID,
        refreshAccount
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
