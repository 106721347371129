"use client";

import clsx from "clsx";
import { ComponentPropsWithRef, ElementType, ReactNode, useEffect, useState } from "react";
import { useId } from "@moovfinancial/common/hooks/useGetId";
import { ValidatedInput, ValidatedInputProps } from "./ValidatedInput";
import styles from "./FloatingLabelInput.module.scss";

export type FloatingLabelInputProps<C extends ElementType = typeof ValidatedInput> =
  (C extends typeof ValidatedInput
    ? Omit<ValidatedInputProps, "isErroring" | "isWarning">
    : ComponentPropsWithRef<C>) & {
    /**
     * The component to use as the base input. Defaults to the basic HTML `input` element
     */
    as?: C;
    /**
     * A ref to be passed to the input element
     */
    inputRef?: React.ForwardedRef<HTMLInputElement>;
    /**
     * Whether or not to force the floating styles. Defaults to `false`.
     * If `true`, the field will always have floating styles.
     *
     * Helpful when changing input values programmatically (no user focus) or working with falsy values like 0.
     */
    forceFloating?: boolean;
    /**
     * The label for the input
     */
    label: ReactNode;
  };

export const FloatingLabelInput = <C extends ElementType = typeof ValidatedInput>({
  className,
  error,
  forceFloating = false,
  helper,
  inputRef,
  label,
  onBlur,
  onFocus,
  value,
  warning,
  name,
  id: propId,
  as: BaseInputComponent,
  ...rest
}: FloatingLabelInputProps<C>) => {
  const [floating, setFloating] = useState(!!value);
  const [focused, setFocused] = useState(false);
  // Passing the name to getId ensures that the id generates is always the same, so snapshots will work
  const id = useId(propId, name);

  useEffect(() => {
    if (value) {
      setFloating(true);
    } else if (!value && !focused && floating) {
      setFloating(false);
    }
  }, [floating, focused, value]);

  function handleLabelState(e: React.FocusEvent<HTMLInputElement>, action: "focus" | "blur") {
    if (action === "focus") {
      if (!floating) {
        setFocused(true);
        setFloating(true);
      }
      onFocus?.(e);
    } else if (action === "blur") {
      if (value == null || value === "") setFloating(false);
      onBlur?.(e);
    }
  }

  function handleAutofill(e: React.AnimationEvent<HTMLInputElement>) {
    if (e.animationName === "onAutoFillStart") {
      if (!floating) {
        setFloating(true);
      }
    }
  }

  const InputComponent = BaseInputComponent ?? ValidatedInput;
  const floatingStyles = forceFloating || floating;

  return (
    <div className={styles.FloatingLabelInput}>
      <label className={clsx(styles.floatingLabel, floatingStyles && styles.floating)} htmlFor={id}>
        {label}
      </label>
      <InputComponent
        id={id}
        helper={helper}
        isErroring={!!error}
        isWarning={!!warning}
        name={name}
        onAnimationStart={handleAutofill}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleLabelState(e, "blur")}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleLabelState(e, "focus")}
        ref={inputRef}
        // In case the underlying input has a default placeholder, we hide it when we're NOT floating
        // i.e. when the input is empty and not being edited
        theme={{ inputElement: clsx(className, !floatingStyles && styles.hidden) }}
        value={value}
        error={typeof error === "boolean" ? undefined : error}
        warning={typeof warning === "boolean" ? undefined : warning}
        {...rest}
      />
    </div>
  );
};
