import clsx from "clsx";
import { useContext } from "react";
import { FormGroup, TextInput } from "@moovfinancial/cargo";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { Label } from "components/form/Form";
import { Account, Business, Individual, Name } from "api/v2";
import { capitalizeString } from "helpers/capitalizeWords";
import { AccountSetupContext } from "../../AccountSetupContext";
import { ActionType } from "../../AccountSetupReducer";
import styles from "../../AccountSetup.module.scss";

interface AccountInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: keyof Business | keyof Individual | keyof Account | keyof Name;
  value?: string;
  label: string;
  placeholder?: string;
  warn?: boolean;
  error?: string;
  isLocked?: boolean;
  optional?: boolean;
  dispatchType?: ActionType;
}

export const InputField = ({
  name,
  value,
  placeholder,
  label,
  warn,
  error,
  isLocked,
  optional,
  dispatchType,
  className,
  required,
  ...rest
}: AccountInputProps) => {
  const {
    dispatch,
    account: { accountType },
    isErrored,
    invalidInputs,
    resetInput,
    validate
  } = useContext(AccountSetupContext);

  const getWarningMessage = () => {
    if (warn) {
      return `${capitalizeString(label.toLowerCase())} is missing or invalid.`;
    }
  };

  const getErrorMessage = () => {
    if (error) return error;
    if (invalidInputs.includes(name)) {
      return required && !value ? "This field is required" : "This field is invalid.";
    }
    if (isErrored(name)) {
      return `${capitalizeString(label.toLowerCase())} is invalid.`;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetInput(name);
    resetInput(e.target.name);
    dispatch({
      type: dispatchType ?? accountType,
      value: { [name]: e.target.value }
    });
  };

  return (
    <FormGroup
      className={clsx(styles.formGroup, className)}
      warningMessage={getWarningMessage()}
      errorMessage={getErrorMessage()}
    >
      <Label label={label} optional={optional} />
      <TextInput
        disabled={isLocked}
        isErroring={isErrored(name) || invalidInputs.includes(name)}
        isLocked={isLocked}
        isWarning={warn}
        name={name}
        pattern={REGEX.NO_ILLEGAL_CHARACTERS}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={(e) => {
          validate(e.target);
        }}
        onChange={handleChange}
        {...rest}
      />
    </FormGroup>
  );
};
