import clsx from "clsx";
import { Theme } from "@moovfinancial/common/types/Theme";
import { InputProps } from "./Input";
import styles from "./Textarea.module.scss";

type TextareaTheme = Theme<typeof styles>;
type BaseTextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  Pick<InputProps, "theme" | "label">;

export interface TextareaProps extends BaseTextareaProps {
  /**
   * The size of the textarea. "S" is sized like a standard input, "M" is twice the size, and "L" is thrice the size.
   */
  textareaSize?: "S" | "M" | "L";
  /**
   * Is the input in an error state?
   */
  isErroring?: boolean;
  /**
   * Is the input in a warning state?
   */
  isWarning?: boolean;
  /**
   * Optional ref to pass to the input element.
   */
  ref?: React.Ref<HTMLTextAreaElement>;
  /**
   * Optional theme object to override or change the element's style.
   */
  theme?: TextareaTheme;
  /**
   * Is the input disabled?
   */
  disabled?: boolean;
  /**
   * Is the input locked (disabled with a lock icon)?
   */
  isLocked?: boolean;
}

/**
 * This is our basic textarea component. It's a wrapper around the native HTML textarea element.
 */
export const Textarea = ({
  textareaSize = "S",
  isErroring,
  isWarning,
  ref,
  theme,
  disabled = false,
  isLocked = false,
  ...rest
}: TextareaProps) => {
  const validationClassName = clsx({
    [styles.error]: isErroring && !disabled,
    [styles.warning]: isWarning && !isErroring && !disabled
  });

  return (
    <div className={clsx(styles.outerWrapper, validationClassName, theme?.outerWrapper)}>
      <textarea
        ref={ref}
        className={clsx(
          styles.inputElement,
          isLocked && styles.isLocked,
          styles.textareaElement,
          textareaSize === "M" && styles.textarea2X,
          textareaSize === "L" && styles.textarea3X,
          theme?.inputElement
        )}
        disabled={isLocked || disabled}
        {...rest}
      />
    </div>
  );
};
