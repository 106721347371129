import GoogleRecaptcha from "@moovfinancial/common/utils/GoogleRecaptcha";
import { getFingerprint } from "@moovfinancial/common/utils/fingerprint";
import { UserAccount } from "api/Account.model";
import {
  DeviceVerification,
  RegisterUser,
  UpdatePassword,
  UpdateUser,
  User,
  UserInvite
} from "api/User.model";
import { http } from "api/http";

export interface UsersConfig {
  fingerprintFn: () => Promise<string>;
}

const defaultConfig: UsersConfig = {
  fingerprintFn: getFingerprint
};

let activeConfig = defaultConfig;

export function getConfig(): UsersConfig {
  return activeConfig;
}

export function setConfig(config: Partial<UsersConfig>) {
  activeConfig = { ...activeConfig, ...config };
}

export function resetConfig() {
  activeConfig = defaultConfig;
}

export async function signUp(email: string, referral?: string): Promise<null> {
  const body = { email, referral };
  if (!referral) delete body.referral;
  const recaptchaToken = await GoogleRecaptcha.execute("signUp");
  return http("/signup", {
    method: "POST",
    json: body,
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export function updateUser(userID: string, updateUser: UpdateUser): Promise<UpdateUser> {
  return http(`/users/${userID}`, {
    method: "PUT",
    json: updateUser
  });
}

export async function signIn(email: string, password: string): Promise<User> {
  const recaptchaToken = await GoogleRecaptcha.execute("signIn");
  const user = await http("/auth/signin/password", {
    method: "POST",
    json: { email, password },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
  return user;
}

export async function changePassword(updatePassword: UpdatePassword): Promise<null> {
  const recaptchaToken = await GoogleRecaptcha.execute("changePassword");
  return http("/auth/signin/password/change-password", {
    method: "POST",
    json: updatePassword,
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function recover(email: string): Promise<User> {
  const recaptchaToken = await GoogleRecaptcha.execute("recoverPassword");
  return http("/auth/signin/password/recover", {
    method: "POST",
    json: { email },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function resetPasswordForRecovery(
  email: string,
  password: string,
  confirmPassword: string,
  token: string
): Promise<User> {
  const recaptchaToken = await GoogleRecaptcha.execute("resetPassword");
  return http("/auth/signin/password/recover/reset-password", {
    method: "POST",
    json: { email, password, confirmPassword, token },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function register(registerUser: RegisterUser): Promise<User> {
  const fingerprint = await activeConfig.fingerprintFn();
  window.sessionStorage.setItem("fingerprint", fingerprint);
  const recaptchaToken = await GoogleRecaptcha.execute("register");
  const user = await http("/auth/signin/password/register", {
    method: "POST",
    json: { ...registerUser, fingerprint },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
  return user;
}

export async function verifyDevice(
  fingerprintParam?: string | null
): Promise<DeviceVerification[]> {
  const fingerprint = fingerprintParam || (await activeConfig.fingerprintFn());
  if (!fingerprintParam) {
    window.sessionStorage.setItem("fingerprint", fingerprint);
  }
  const recaptchaToken = await GoogleRecaptcha.execute("verify");
  return http("/auth/verify", {
    method: "POST",
    json: { fingerprint },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function sendVerifyDeviceCode(): Promise<null> {
  const recaptchaToken = await GoogleRecaptcha.execute("sendVerify");
  return http("/auth/verify/email/send", {
    method: "POST",
    json: {},
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export function registerDevice(code: string): Promise<DeviceVerification> {
  return activeConfig.fingerprintFn().then((fingerprint: string) => {
    window.sessionStorage.setItem("fingerprint", fingerprint);
    return http("/auth/verify/email", {
      method: "POST",
      json: { code, fingerprint }
    });
  });
}

export function ping(): Promise<null> {
  return http("/ping");
}

export function listUserAccounts(userID: string): Promise<UserAccount[] | null> {
  return http(`/users/${userID}/accounts`);
}

export function listUserInvites(userID: string): Promise<UserInvite[]> {
  return new Promise((resolve, reject) => {
    http(`/users/${userID}/invites`)
      .then(resolve)
      .catch((err) => {
        if (err?.status === 404) {
          resolve([]);
        } else {
          reject(err);
        }
      });
  });
}
