import { Enum } from "../Enum";
import { components } from "../__generated-types__/api";

export type BusinessType = components["schemas"]["BusinessType"];

export const BusinessTypeMap: Record<BusinessType, string> = {
  llc: "LLC",
  partnership: "Partnership",
  publicCorporation: "Public corporation",
  soleProprietorship: "Sole proprietorship",
  trust: "Trust",
  privateCorporation: "Private corporation",
  unincorporatedAssociation: "Unincorporated association",
  unincorporatedNonProfit: "Unincorporated non-profit",
  incorporatedNonProfit: "Incorporated non-profit",
  governmentEntity: "Government entity"
};

export type BusinessTypeMap = Enum<typeof BusinessTypeMap>;
