import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SessionContext } from "contexts/SessionContext";
import SessionReminders from "layout/SessionReminders";

export const NeedsSession = () => {
  const { fetchSession, session } = useContext(SessionContext);

  let status;

  useEffect(() => {
    if (!session) {
      status = fetchSession();
    }
  }, [session]);

  if (!session && status !== undefined) {
    // So <Suspense> shows the loading spinner until the promise resolves
    throw status;
  }

  return session ? (
    <>
      <Outlet />
      {/* Always that we need session we need session reminders */}
      <SessionReminders />
    </>
  ) : null;
};
