/**
 * Recursively merges two objects
 * @param target The starting point for the merge
 * @param source Object with values that will override `target`
 * @param preserve A function that returns true if the given target value should be maintained
 * @returns A new object created from merging the two given objects
 */
export default function deepMerge(
  target: any,
  source: any,
  preserve?: (val: any, key: string) => boolean
) {
  const result: any = {};

  const handleMerge = (obj: any, preserveFunc?: any) => {
    for (const key in obj) {
      // https://eslint.org/docs/latest/rules/no-prototype-builtins#:~:text=to%20avoid%20subtle%20bugs%20like%20this%2C%20it%E2%80%99s%20better%20to%20always%20call%20these%20methods%20from%20object.prototype.%20for%20example%2C%20foo.hasownproperty(%22bar%22)%20should%20be%20replaced%20with%20object.prototype.hasownproperty.call(foo%2C%20%22bar%22).
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
          result[key] = deepMerge(result[key], obj[key], preserveFunc);
        } else {
          const shouldPreserve = preserveFunc ? preserveFunc(result[key], key) : false;
          if (!shouldPreserve) result[key] = obj[key];
        }
      }
    }
  };

  handleMerge(target);
  handleMerge(source, preserve);

  return result;
}
