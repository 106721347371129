import LegacyAlert from "components/alert/Alert";
import styles from "../../AccountSetup.module.scss";

export function VerificationLockedBanner() {
  return (
    <>
      <div className={styles.lockedBanner}>
        <LegacyAlert type="info">
          Verification is complete or in progress. To edit locked fields,{" "}
          <a href="https://support.moov.io/hc/en-us/requests/new?" target="_blank" rel="noreferrer">
            contact support
          </a>
        </LegacyAlert>
      </div>
    </>
  );
}

export function UnderwritingLockedBanner() {
  return (
    <>
      <div className={styles.lockedBanner}>
        <LegacyAlert type="info">
          Underwriting is complete or in progress. To edit locked fields,{" "}
          <a href="https://support.moov.io/hc/en-us/requests/new?" target="_blank" rel="noreferrer">
            contact support
          </a>
        </LegacyAlert>
      </div>
    </>
  );
}
