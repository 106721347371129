import createClient, { Middleware } from "openapi-fetch";
import type { paths } from "@moovfinancial/common/types/__generated-types__/api";

const API_BASE_PATH = "/api";

// Had to implement a custom fetch function for 2 reasons:
//
// 1- To be able to use the prism mock server when localStorage.useMockServer is true, as middlewares
// can't overwrite the `url` property of the request object.
//
// 2 - To be able to call silentFetch (TBD)
// const customFetch: typeof globalThis.fetch = async (resource, options) => {
//   if (typeof resource === "string") {
//     return globalThis.fetch(maybeMockUrlWithPrism(resource), options);
//   }
//   if (resource instanceof URL) {
//     const href = maybeMockUrlWithPrism(resource.href);
//     resource.href = href;
//     return globalThis.fetch(resource, options);
//   }
//   if (resource instanceof Request) {
//     const request = new Request(maybeMockUrlWithPrism(resource.url), resource);
//     return globalThis.fetch(request, options);
//   }
//   return globalThis.fetch(resource, options);
// };

export const openApi = createClient<paths>({ baseUrl: API_BASE_PATH });

const removeUndefinedMiddleware: Middleware = {
  async onRequest(req, _options) {
    const queryParams = req.params.query;
    if (queryParams) {
      Object.keys(queryParams).forEach((key) =>
        queryParams[key] === undefined ? delete queryParams[key] : {}
      );
    }
    return req;
  }
};

openApi.use(removeUndefinedMiddleware);
