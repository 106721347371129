import { AnimatePresence, motion } from "framer-motion";
import { Suspense, lazy, useContext } from "react";
import { Outlet } from "react-router-dom";
import useSuper from "hooks/useSuper";
import { UserContext } from "contexts/UserContext";
import styles from "./ActiveAcctRoute.module.scss";

const FourOhFour = lazy(() => import("pages/404"));
interface CustomProps {
  superUser?: boolean;
}

export default function ActiveAcctRoute({ superUser }: CustomProps) {
  const { activeUserAccountID } = useContext(UserContext);
  const isSuper = useSuper();

  return (
    <Suspense fallback={<div></div>}>
      <AnimatePresence mode="wait">
        {activeUserAccountID && (!superUser || isSuper) && (
          <motion.div className={styles.container}>
            <Outlet />
          </motion.div>
        )}
        {superUser && !isSuper && <FourOhFour />}
      </AnimatePresence>
    </Suspense>
  );
}
