import clsx from "clsx";
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { ValidatedInput, ValidatedInputProps } from "@moovfinancial/cargo";
import useInputMask from "@moovfinancial/common/hooks/useInputMask";
import styles from "./MaskingInput.module.scss";

// Our masking inputs are not compatible with maxLength prop
export interface MaskingInputProps<T extends string | number = number>
  extends Omit<ValidatedInputProps, "maxLength" | "onChange"> {
  /**
   * We narrow down the value to be only string or number
   */
  value: T;
  /**
   * Chars that the user is allowed to type in the input
   */
  acceptedChars?: RegExp;
  /**
   * If the number value is equal to this passed in value, the input will dim 50%
   * The input will NOT dim if this prop is not passed in (default)
   */
  dimValue?: T;
  /**
   * Event listeners to attach to the document. When fired, the passed handler will be called with the input element
   */
  documentEventListeners?: Record<string, (e: Event, inputEl: HTMLInputElement) => void>;
  /**
   * Standard onChange event handler.
   * WARNING: This gets called with he RAW value BEFORE formatting
   */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Function called on value change. The function should update the value in the parent component's state.
   */
  onValueChange?: (value: T, formattedValue: string) => void;
  /**
   * The function to go from model value (the one the code cares about) to
   * formatted string displayed to the user
   */
  modelValueToFormattedString: (value: T) => string;
  /**
   * The function to go from formatted (displayed) value to model value
   */
  formattedStringToModelValue: (value: string) => T;
  /**
   * The function to format the input value, will be passed into the useInputMask hook
   */
  inputFormatter: (value: string) => string;
  /**
   * Should the input be guided?
   */
  guided?: boolean;
}

/**
 * Generic MaskingInput that can deal with both string and number values
 *
 * This input is compatible with all the "Input ecosystem" of components like `FloatingLabelInput`
 */
export const MaskingInput = <T extends string | number = number>({
  value: valueProp,
  ref: propsRef,
  guided,
  className,
  acceptedChars,
  documentEventListeners,
  dimValue,
  theme,
  onChange,
  onValueChange,
  inputFormatter,
  modelValueToFormattedString,
  formattedStringToModelValue,
  onBeforeInput: parentOnBeforeInput,
  onInput: onInputProp,
  onBlur,
  ...rest
}: MaskingInputProps<T>) => {
  const initialFormattedString = modelValueToFormattedString
    ? modelValueToFormattedString(valueProp)
    : valueProp.toString();
  const [formattedString, setFormattedString] = useState(initialFormattedString);
  const ref = useRef<HTMLInputElement>(null);
  const inputRef = propsRef ?? ref;

  // Attach eventListeners to the document, if any are povided
  useEffect(() => {
    const inputEl = inputRef.current;
    if (documentEventListeners && inputEl) {
      const bindedHandlers = Object.entries(documentEventListeners).map(([name, handler]) => {
        const boundHandler = (e: Event) => handler(e, inputEl);
        document.addEventListener(name, boundHandler);
        return { name, boundHandler };
      });
      return () =>
        bindedHandlers.forEach(({ name, boundHandler }) =>
          inputEl.removeEventListener(name, boundHandler)
        );
    }
  }, [documentEventListeners, inputRef]);

  // Update formattedValue if it doesn't match valueProp
  useEffect(() => {
    const localFormattedValue = formattedStringToModelValue(formattedString);
    const newValuePropString = modelValueToFormattedString(valueProp);
    if (localFormattedValue !== valueProp) setFormattedString(newValuePropString);
  }, [valueProp]);

  const handleFormatComplete = (formattedString: string) => {
    setFormattedString(formattedString);
    const modelValue = formattedStringToModelValue(formattedString);
    if (modelValue !== valueProp) onValueChange?.(modelValue, formattedString);
  };

  const opacityIfValue0 =
    formattedStringToModelValue(formattedString) === dimValue ? styles.opacity : "";

  const {
    value: maskedValue,
    onInput,
    onBeforeInput: baseOnBeforeInput
  } = useInputMask({
    format: inputFormatter,
    guided,
    acceptedChars,
    value: formattedString,
    onFormatComplete: handleFormatComplete
  });

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // 🔴 @TODO: Probably we should not call these three sequentially. We should probably
      // compose them together, and allow one of them to decide if the next `onInput`
      // should be called or not. I.e. the first onInput should be able to short-circuit
      // the second one, and not call it.
      //
      // See how we do it in handleBeforeInput below
      onInputProp?.(e);
      onInput(e);
      onChange?.(e);
    },
    [onInputProp, onInput, onChange]
  );

  const handleBeforeInput = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const parentResult = parentOnBeforeInput?.(e);
      // if parentOnBeforeInput handler returns undefined or truthy value,
      // we should continue calling the base handler. Otherwise, we should
      // short-circuit the base handler call.
      if (parentResult === undefined || parentResult) {
        baseOnBeforeInput(e);
      }
    },
    [parentOnBeforeInput, baseOnBeforeInput]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement, Element>) => {
      onBlur?.(e);
      const formattedValue = modelValueToFormattedString(valueProp);
      setFormattedString(formattedValue);
    },
    [onBlur, modelValueToFormattedString, valueProp, setFormattedString]
  );

  return (
    <ValidatedInput
      ref={inputRef}
      type="text"
      onBeforeInput={handleBeforeInput}
      onChange={handleInput}
      onBlur={handleBlur}
      value={maskedValue}
      // We need to "extend" the theme passed down from the parent components
      theme={{
        ...theme,
        inputElement: clsx(theme?.inputElement, opacityIfValue0, className)
      }}
      autoComplete="off"
      data-testid="maskingInput"
      {...rest}
    />
  );
};
