import { useCallback, useMemo } from "react";
import {
  type PercentFormatOptions,
  floatToFormattedString,
  formatPercentInput,
  formattedStringToFloat,
  getEmptyState
} from "@moovfinancial/common/utils/format/formatPercent";
import { NumberMaskingInput, NumberMaskingInputProps } from "./NumberMaskingInput";

export interface PercentInputProps
  extends Omit<
    NumberMaskingInputProps,
    | "numberToFormattedString"
    | "formattedStringToNumber"
    | "acceptedChars"
    | "inputFormatter"
    | "documentEventListeners"
    | "onBeforeInput"
  > {
  /**
   * The maximum percentage value that the input can accept.
   * If the value is greater than the max, the value will be set to the max onChange.
   *
   * NOTE: If passed, this will override the `maxOnBlur` value.
   *
   * @default undefined
   */
  maxOnChange?: number;
  /**
   * The maximum percentage value that the input can accept.
   * If the value is greater than the max, the value will be set to the max onBlur.
   *
   * NOTE: If `maxOnChange` is passed, this value will be ignored.
   *
   * @default 100
   */
  maxOnBlur?: number;
  /**
   * The number of decimal places that the masked input displays
   * @default 0
   */
  decimalPlaces?: number;
  /**
   * Dim the input when the value is the "empty state"
   * @default false
   */
  dimOnEmptyState?: boolean;
}

export const PercentInput = ({
  maxOnBlur = 100,
  maxOnChange,
  decimalPlaces = 0,
  dimOnEmptyState = false,
  onValueChange,
  onBlur: parentOnBlur,
  ...rest
}: PercentInputProps) => {
  const formattingOptions: PercentFormatOptions = useMemo(() => {
    return {
      decimalPlaces,
      max: maxOnChange
    };
  }, [decimalPlaces, maxOnChange]);
  const emptyState = useMemo(() => getEmptyState(formattingOptions), [formattingOptions]);
  const handleBeforeInput = useCallback(
    (e: any) => {
      const input = e.target;
      // When decimal point is pressed for the first time, edit value/selection to prevent cursor jumping
      if (e.data === "." && !input.value.includes(".")) {
        if (input.value === emptyState) {
          input.selectionStart = input.selectionEnd = 2;
        }
      }
      // When decimal point is pressed and decimal already exists, move selection to decimals
      if (e.data === "." && input.value.includes(".")) {
        input.selectionStart = input.selectionEnd = input.selectionStart + 1;
        e.preventDefault();
      }
      // Highlight `0%` on first digit keypress
      if (
        e.data !== "%" &&
        input.value === emptyState &&
        input.selectionStart <= input.value.length - 1 &&
        input.selectionEnd <= input.value.length - 1
      ) {
        input.selectionStart = 0;
        input.selectionEnd = input.value.length - 1;
      }
    },
    [emptyState]
  );

  const documentEventListeners = {
    selectionchange: (_e: any, inputElem: HTMLInputElement) => {
      // Force cursor to the left of the percent sign
      if (
        inputElem.selectionStart === inputElem.value.length ||
        inputElem.value === `${maxOnBlur}%`
      ) {
        inputElem.selectionStart = inputElem.value.length - 1;
        inputElem.selectionEnd = inputElem.value.length - 1;
      }
    }
  };

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      // debugger;
      // if the value is greater than the max, set the value to the max
      if (
        maxOnBlur &&
        !maxOnChange &&
        formattedStringToFloat(e.target.value, { decimalPlaces }) > maxOnBlur
      ) {
        const newValue = maxOnBlur.toFixed(decimalPlaces) + "%";
        e.target.value = newValue;
        onValueChange?.(maxOnBlur, newValue);
      }
      parentOnBlur?.(e);
    },
    [decimalPlaces, maxOnBlur, maxOnChange, parentOnBlur]
  );

  return (
    <NumberMaskingInput
      dimValue={dimOnEmptyState ? 0 : undefined}
      documentEventListeners={documentEventListeners}
      onBeforeInput={handleBeforeInput}
      inputFormatter={(value) => formatPercentInput(value, formattingOptions)}
      acceptedChars={/[\d.%]+/}
      numberToFormattedString={(value) => floatToFormattedString(value, formattingOptions)}
      formattedStringToNumber={(value) => formattedStringToFloat(value, formattingOptions)}
      onBlur={handleBlur}
      onValueChange={onValueChange}
      {...rest}
    />
  );
};
