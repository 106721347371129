import clsx from "clsx";
import React, {
  FunctionComponent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { BaseButton, CargoElement, TextInput } from "@moovfinancial/cargo";
import useInputMask from "@moovfinancial/common/hooks/useInputMask";
import {
  centsToFormattedString,
  dollarsToFormattedString,
  formatCents,
  formatCurrencyInput,
  formattedStringToCents,
  formattedStringToDollars
} from "@moovfinancial/common/utils/format/formatCurrency";
import CheckMark from "components/icons/checkmark_circle.svg?react";
import CircleIcon from "components/icons/circle_outlined.svg?react";
import InfoIcon from "components/icons/info.svg?react";
import { Title } from "components/typography/Typography";
import { Placement } from "@popperjs/core";
import Tippy from "@tippyjs/react";
import styles from "./Form.module.scss";

interface FormProps {
  as?: string;
  type?: string;
  indeterminate?: boolean;
  isLocked?: boolean;
  warn?: boolean;
  error?: boolean;
  errorMessage?: string;
}

export interface LabelProps {
  label?: string;
  optional?: boolean;
  optionalText?: string;
  infoText?: string;
}

export interface InfoTippyProps extends React.HTMLAttributes<HTMLSpanElement> {
  infoText?: string | React.ReactNode;
  placement?: Placement;
  trigger?: string;
}

export interface CheckboxProps {
  label?: string | React.ReactNode;
  reverse?: boolean;
}

export interface CurrencyAmountProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  value: number;
  onValueChange: (value: number, ...rest: any) => void;
  name?: string;
  disabled?: boolean;
  isLocked?: boolean;
  error?: boolean;
  errorMessage?: string;
  warn?: boolean;
  keyName?: string;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
}

interface FormMethodProps extends React.HTMLAttributes<HTMLFormElement> {
  method?: string;
}

// @TODO: Kill with fire
export const Form: FunctionComponent<React.PropsWithChildren<FormMethodProps>> = ({
  children,
  className,
  ...rest
}) => (
  <form className={className} {...rest}>
    {children}
  </form>
);

// @TODO: Move to cargo. Wrap infoIcon in <Icon />
export const InfoTippy = ({ infoText, className, placement, trigger }: InfoTippyProps) => {
  return (
    <span className={className}>
      <Tippy
        placement={placement || "top"}
        content={infoText}
        appendTo="parent"
        trigger={trigger}
        duration={300}
        delay={[0, 500]}
      >
        <span tabIndex={0}>
          <InfoIcon />
        </span>
      </Tippy>
    </span>
  );
};

// @TODO: Probably try to kill with fire, unless we see a very common pattern in current UIs
export const Label: FunctionComponent<
  React.PropsWithChildren<FormProps & LabelProps & React.AllHTMLAttributes<HTMLLabelElement>>
> = ({
  children,
  as,
  className,
  label,
  optional = false,
  optionalText = "(optional)",
  infoText,
  ...rest
}) => (
  <CargoElement renderAs={as || "label"} className={clsx(styles.label, className)} {...rest}>
    {(label || optional) && (
      <>
        <span>{label}</span>
        {infoText && <InfoTippy infoText={infoText} className={styles.labelInfo} />}
        {optional && <span className={styles.labelOptional}>{optionalText}</span>}
      </>
    )}
    {children && <span className={styles.labelChildren}>{children}</span>}
  </CargoElement>
);

interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  warn?: boolean;
}

// @TODO: Kill and replace with cargo's `Input` or `FloatingLabelInput`
export const Input = forwardRef<HTMLInputElement, InputProps>(function inputNested(
  { className, name, warn, ...rest },
  ref
) {
  return (
    <input
      className={clsx(styles.input, warn && styles.warn, className)}
      name={name}
      ref={ref}
      {...rest}
    />
  );
});

// @TODO: Aggressively clean and improve, then move to cargo
export const Checkbox: FunctionComponent<
  React.PropsWithChildren<FormProps & CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>>
> = ({ className, indeterminate, type, label, reverse, disabled, ...rest }) => {
  return (
    <>
      {label && reverse && (
        <Label className={clsx(styles.checkboxLabelReverse, disabled && styles.disabledCheckbox)}>
          <span>{label}</span>
          <input
            ref={(ref) => {
              if (ref) {
                ref.indeterminate = indeterminate || false;
              }
            }}
            type={type || "checkbox"}
            className={clsx(styles.checkbox, className)}
            disabled={disabled}
            {...rest}
          />
        </Label>
      )}

      {label && !reverse && (
        <label className={clsx(disabled && styles.disabledCheckbox)}>
          <input
            ref={(ref) => {
              if (ref) {
                ref.indeterminate = indeterminate || false;
              }
            }}
            type={type || "checkbox"}
            className={clsx(styles.checkbox, className)}
            disabled={disabled}
            {...rest}
          />
          <span className={styles.checkLabel}>{label}</span>
        </label>
      )}

      {!label && (
        <input
          ref={(ref) => {
            if (ref) {
              ref.indeterminate = indeterminate || false;
            }
          }}
          type={type || "checkbox"}
          className={clsx(styles.checkbox, className)}
          disabled={disabled}
          {...rest}
        />
      )}
    </>
  );
};

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  ref?: React.RefObject<HTMLTextAreaElement>;
}

//@TODO: Probably move to cargo but adding the `error`, `warning`, `isErroring`, `isWarning`, etc. props a la Input
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  { className, ...rest }: TextareaProps,
  ref
) {
  return <textarea className={clsx(styles.textarea, className)} ref={ref} {...rest}></textarea>;
});

//@TODO: Kill & replace with `FormGroup`, we may want to make FormGroup be a fieldset underneath?
export const Fieldset: FunctionComponent<
  React.PropsWithChildren<React.HTMLAttributes<HTMLFieldSetElement>>
> = ({ children, className, ...rest }) => (
  <fieldset className={clsx(styles.fieldset, className)} {...rest}>
    {children}
  </fieldset>
);

// @TODO: kill
export const Legend: FunctionComponent<
  React.PropsWithChildren<React.HTMLAttributes<HTMLLegendElement>>
> = ({ children, className, ...rest }) => (
  <legend className={clsx(styles.legend, className)} {...rest}>
    {children}
  </legend>
);

//@TODO: Kill & replace with `<CentsCurrencyAmountInput />`
// Cents input always displays $0.XX and forces right-to-left input of cents only
const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const BACKSPACE_KEY = "Backspace";
export const CentsInput: FunctionComponent<React.PropsWithChildren<CurrencyAmountProps>> = ({
  className,
  value,
  onValueChange,
  ...rest
}) => {
  if (!Number.isInteger(value) || value < 0) {
    throw new Error("Value is not valid");
  }

  const handleKeyDown = useCallback(
    (event: any) => {
      const { key } = event;

      const isValidFirst = VALID_FIRST.test(key);
      const isValidNext = VALID_NEXT.test(key) || key === BACKSPACE_KEY;
      if (value === 0 && !isValidFirst) return;
      if (value > 0 && !isValidNext) return;

      const valueString = value.toString();
      const nextValueString =
        key === BACKSPACE_KEY ? valueString.slice(0, -1) || "0" : valueString + key;
      const nextValue = Number.parseInt(nextValueString, 10) % 100;

      onValueChange(nextValue);
    },
    [onValueChange, value]
  );

  const valueDisplay = formatCents(value);
  const opacityIfValue0 = value === 0 ? styles.opacity : "";
  // dummy function to avoid react warning
  const handleChange = useCallback(() => {}, []);

  return (
    <input
      type="text"
      inputMode="numeric"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={valueDisplay}
      className={clsx(`${opacityIfValue0} ${styles.input}`, className)}
      {...rest}
    />
  );
};

// Same as above??
//@TODO: Kill & replace with `<CentsCurrencyAmountInput />`
// Currency input for cents
export const CurrencyInput: FunctionComponent<React.PropsWithChildren<CurrencyAmountProps>> = ({
  className,
  value: valueProp,
  onValueChange,
  disabled,
  error,
  errorMessage,
  keyName,
  ...rest
}) => {
  const valuePropString = centsToFormattedString(valueProp);
  const [localValue, setLocalValue] = useState(valuePropString);
  const inputRef = useRef() as React.RefObject<HTMLInputElement>;

  // Force cursor to the right of the dollar sign
  useEffect(() => {
    const inputEl = inputRef.current!;
    const deselectDollarSign = () => {
      if (inputEl.selectionStart === 0) inputEl.selectionStart = 1;
    };
    document.addEventListener("selectionchange", deselectDollarSign);
    return () => document.removeEventListener("selectionchange", deselectDollarSign);
  }, []);

  // Update localValue if it doesn't match valueProp
  useEffect(() => {
    const localCentsValue = formattedStringToCents(localValue);
    const newValuePropString = centsToFormattedString(valueProp);
    if (localCentsValue !== valueProp) setLocalValue(newValuePropString);
  }, [valueProp]);

  const handleFormatComplete = (formattedString: string) => {
    setLocalValue(formattedString);
    const valueInCents = formattedStringToCents(formattedString);
    if (keyName) {
      onValueChange(valueInCents, keyName);
    } else {
      onValueChange(valueInCents);
    }
  };

  const {
    value: currencyMaskedValue,
    onInput: onCurrencyMaskInput,
    onBeforeInput: onBeforeCurrencyMaskInput
  } = useInputMask({
    format: formatCurrencyInput,
    acceptedChars: /[\d.]/g,
    value: localValue,
    onFormatComplete: handleFormatComplete
  });

  const handleBeforeInput = (e: any) => {
    const input = e.target;
    // When decimal point is pressed for the first time, edit value/selection to prevent cursor jumping
    if (e.data === "." && !input.value.includes(".")) {
      if (input.value === "$0") input.selectionStart = input.selectionEnd = 2;
    }
    // When decimal point is pressed and decimal already exists, move selection to cents
    if (e.data === "." && input.value.includes(".")) {
      input.selectionStart = input.selectionEnd = input.value.length - 2;
      e.preventDefault();
    }
    // Highlight `0` dollars on first digit keypress
    if (
      e.data !== "." &&
      input.value.split(".")[0] === "$0" &&
      input.selectionStart <= 2 &&
      input.selectionEnd <= 2
    ) {
      input.selectionStart = 1;
      input.selectionEnd = 2;
    }
    onBeforeCurrencyMaskInput(e);
  };

  const handleInput = (e: any) => {
    onCurrencyMaskInput(e);
  };

  const handleBlur = () => {
    const valuePropString = centsToFormattedString(valueProp);
    setLocalValue(valuePropString);
  };

  const opacityIfValue0 = formattedStringToCents(localValue) === 0 ? styles.opacity : "";

  return (
    <span>
      <input
        ref={inputRef}
        type="text"
        inputMode="numeric"
        onBeforeInput={handleBeforeInput}
        onChange={handleInput}
        onBlur={handleBlur}
        value={currencyMaskedValue}
        className={clsx(
          { [styles.errored]: error },
          `${opacityIfValue0} ${styles.input}`,
          className
        )}
        autoComplete="off"
        disabled={disabled}
        {...rest}
      />
      {error && <div className={styles.errorMessage}>{errorMessage}</div>}
    </span>
  );
};

// @TODO: Replace with <DollarCurrencyAmountInput>
export const CurrencyDollarsInput: FunctionComponent<
  React.PropsWithChildren<CurrencyAmountProps>
> = ({
  forwardedRef,
  value: valueProp,
  onValueChange,
  disabled,
  isLocked,
  error,
  warn,
  keyName,
  placeholder,
  ...rest
}) => {
  const valuePropString = dollarsToFormattedString(valueProp);
  const [localValue, setLocalValue] = useState(valuePropString);
  const inputRef = useRef() as React.RefObject<HTMLInputElement>;

  // Force cursor to the right of the dollar sign
  useEffect(() => {
    const inputEl = forwardedRef?.current ?? inputRef.current!;
    const deselectDollarSign = () => {
      if (inputEl?.selectionStart === 0) inputEl.selectionStart = 1;
    };
    document.addEventListener("selectionchange", deselectDollarSign);
    return () => document.removeEventListener("selectionchange", deselectDollarSign);
  }, []);

  // Update localValue if it doesn't match valueProp
  useEffect(() => {
    const localDollarsValue = formattedStringToDollars(localValue);
    const newValuePropString = dollarsToFormattedString(valueProp);
    if (localDollarsValue !== valueProp) setLocalValue(newValuePropString);
  }, [valueProp]);

  const handleFormatComplete = (formattedString: string) => {
    setLocalValue(formattedString);
    const valueInDollars = formattedStringToDollars(formattedString);
    if (keyName) {
      onValueChange(valueInDollars, keyName);
    } else {
      onValueChange(valueInDollars);
    }
  };

  const {
    value: currencyMaskedValue,
    onInput: onCurrencyMaskInput,
    onBeforeInput: onBeforeCurrencyMaskInput
  } = useInputMask({
    format: formatCurrencyInput,
    acceptedChars: /[\d.]/g,
    value: localValue,
    onFormatComplete: handleFormatComplete
  });

  const handleBeforeInput = (e: any) => {
    const input = e.target;
    // When decimal point is pressed for the first time, edit value/selection to prevent cursor jumping
    if (e.data === "." && !input.value.includes(".")) {
      if (input.value === "$0") input.selectionStart = input.selectionEnd = 2;
    }
    // When decimal point is pressed and decimal already exists, move selection to dollars
    if (e.data === "." && input.value.includes(".")) {
      input.selectionStart = input.selectionEnd = input.value.length - 2;
      e.preventDefault();
    }
    // Highlight `0` dollars on first digit keypress
    if (
      e.data !== "." &&
      input.value.split(".")[0] === "$0" &&
      input.selectionStart <= 2 &&
      input.selectionEnd <= 2
    ) {
      input.selectionStart = 1;
      input.selectionEnd = 2;
    }
    onBeforeCurrencyMaskInput(e);
  };

  const handleInput = (e: any) => {
    onCurrencyMaskInput(e);
  };

  const handleBlur = () => {
    const valuePropString = dollarsToFormattedString(valueProp);
    setLocalValue(valuePropString);
  };

  return (
    <TextInput
      autoComplete="off"
      disabled={disabled}
      inputMode="numeric"
      isErroring={error}
      isLocked={isLocked}
      isWarning={warn}
      name={keyName || rest.name}
      placeholder={placeholder}
      ref={forwardedRef ?? inputRef ?? null}
      type="text"
      value={currencyMaskedValue === "$0" && placeholder ? "" : currencyMaskedValue}
      onBeforeInput={handleBeforeInput}
      onBlur={handleBlur}
      onChange={handleInput}
      {...rest}
    />
  );
};

export interface RadioOptionData {
  value: string;
  label: string;
  secondaryLabel?: string;
}
interface RadioProps extends React.HTMLAttributes<HTMLDivElement> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioOptionData[];
  name: string;
  value: string;
  required?: boolean;
  stacked?: boolean;
  disabled?: boolean;
}

//@TODO: Aggressively clean & refactor, then move to cargo
export const RadioInput: FunctionComponent<React.PropsWithChildren<RadioProps>> = ({
  onChange,
  options,
  name,
  value,
  required,
  disabled
}) => {
  return (
    <>
      {options.map((option) => {
        return (
          <div key={option.value} className={styles.optionContainer}>
            <input
              type="radio"
              checked={value === option.value}
              onChange={onChange}
              name={name}
              value={option.value}
              key={option.value}
              required={required}
              disabled={disabled}
            />{" "}
            <span
              className={styles.optionLabel}
              onClick={() =>
                onChange({
                  target: { value: option.value }
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              {option.label}
            </span>
            {option.secondaryLabel && (
              <span className={styles.secondaryLabel}>{option.secondaryLabel}</span>
            )}
          </div>
        );
      })}
    </>
  );
};

/**
 * @deprecated Use cargo's ButtonToggle Instead
 */
export const LegacyButtonToggle: FunctionComponent<React.PropsWithChildren<RadioProps>> = ({
  onChange,
  options,
  name,
  value,
  stacked,
  className,
  disabled,
  ...rest
}) => {
  return (
    <div
      className={clsx(className, styles.toggleButtonContainer, {
        [styles.stackedToggle]: stacked
      })}
      {...rest}
    >
      {options.map((option) => {
        return (
          <BaseButton
            key={option.value}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onChange({
                target: { value: option.value, name: name }
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            className={clsx(
              option.value === value ? styles.activeToggleButton : styles.inactiveToggleButton,
              styles.spread,
              stacked && styles.fullWidth
            )}
            disabled={disabled}
          >
            {option.secondaryLabel ? (
              <div className={styles.labelGrid}>
                <span className={styles.labelOne}>{option.label}</span>
                <span className={styles.labelTwo}>{option.secondaryLabel}</span>
              </div>
            ) : (
              <span>{option.label}</span>
            )}
            {option.value === value ? (
              <CheckMark className={styles.checkIcon} />
            ) : (
              <CircleIcon className={styles.circleIcon} />
            )}
          </BaseButton>
        );
      })}
      <input hidden value={value} name={name} onChange={() => {}} />
    </div>
  );
};

export const Toggle: FunctionComponent<
  React.PropsWithChildren<FormProps & CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>>
> = ({ className, label, ...rest }) => {
  return (
    <label className={styles.toggle}>
      <input type="checkbox" className={clsx(styles.switchInput, className)} {...rest} />
      <div className={styles.switch}></div>
      {label && (
        <Title as="span" className={styles.checkboxLabel}>
          {label}
        </Title>
      )}
    </label>
  );
};
