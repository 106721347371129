import { Account, UserAccount } from "api/Account.model";

export function accountToUserAccount(account: Account): UserAccount {
  return {
    accountID: account.accountID,
    displayName: account.displayName,
    accountMode: account.mode || "production"
  };
}

export function userAccountToAccount(userAccount: UserAccount): Account {
  return {
    accountID: userAccount.accountID,
    displayName: userAccount.displayName,
    accountType: "business",
    mode: userAccount.accountMode,
    profile: {},
    createdOn: "",
    updatedOn: ""
  };
}
