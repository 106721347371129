/* eslint-disable no-console */
import deepMerge from "@moovfinancial/common/utils/deepMerge";
import { Account } from "api/Account.model";
import { AccountUnderwriting } from "api/v2";

export interface MoovFile extends File {
  fileID?: string;
}

export interface FileState {
  file: MoovFile;
  status: "pending" | "success" | "error";
  errorMessage?: string;
}

export interface StateType {
  title: string;
  underwriting: AccountUnderwriting;
  account: Partial<Account>;
  prefilled: boolean;
  manualAddress: boolean;
  supportManualAddress: boolean;
  files: FileState[];
}

export type ActionType =
  | "account"
  | "individual"
  | "business"
  | "individualName"
  | "individualPhone"
  | "individualAddress"
  | "individualBirthdate"
  | "businessAddress"
  | "businessPhone"
  | "businessCodes"
  | "businessTaxID"
  | "prefill"
  | "manualAddress"
  | "underwriting"
  | "addFile"
  | "uploadFiles"
  | "deleteFile"
  | "accountErrors";

export type Action = {
  type: ActionType;
  value?: any;
};

const isPreservedStateValue = (val: any, key: string) => {
  if (typeof val === "string" && val === "") return false;
  if (typeof val === "number" && val === 0) return false;
  if (key === "countryCode" && val === "1") return false;
  return true;
};

const calculateFiles = (files: FileState[], action: Action) => {
  const newFiles = files.filter((file) => file.status !== "error");
  newFiles.push({ file: action.value, status: "pending" });
  return newFiles;
};

const calculateDeletedFiles = (files: FileState[], action: Action) => {
  const fileIndex = files.findIndex((file) => {
    return file.file.name === action.value;
  });
  if (fileIndex !== undefined && fileIndex > -1) {
    files.splice(fileIndex, 1);
  }
  return files;
};

const calculateNewAccount = (state: StateType, action: Action) => {
  let newAccount = state.account as Partial<Account>;
  newAccount = deepMerge(newAccount, action.value.individual, isPreservedStateValue);
  newAccount = deepMerge(newAccount, action.value.business, isPreservedStateValue);
  return newAccount;
};

export const reducer = (state: StateType, action: Action) => {
  // this works as long as we don't add dates or complex types into our state.
  const newState: StateType = JSON.parse(JSON.stringify(state));
  const files = [...state.files];

  switch (action.type) {
    case "account":
      newState.account = { ...state.account, ...action.value };
      return newState;
    case "individual":
      if (newState.account.profile?.individual) {
        newState.account.profile.individual = {
          ...state.account.profile?.individual,
          ...action.value
        };
      } else {
        console.error("Error updating individual profile data.");
      }
      return newState;
    case "business":
      if (newState.account.profile?.business) {
        newState.account.profile.business = {
          ...state.account.profile?.business,
          ...action.value
        };
      } else {
        console.error("Error updating business profile data.");
      }
      return newState;
    case "individualName":
      if (newState.account.profile?.individual?.name) {
        newState.account.profile.individual.name = {
          ...state.account.profile?.individual?.name,
          ...action.value
        };
      } else {
        console.error("Error updating individual name data.");
      }
      return newState;
    case "individualPhone":
      if (newState.account.profile?.individual?.phone) {
        newState.account.profile.individual.phone = {
          ...state.account.profile?.individual?.phone,
          ...action.value,
          countryCode: "1"
        };
      } else {
        console.error("Error updating individual phone data.");
      }
      return newState;
    case "individualAddress":
      if (newState.account.profile?.individual?.address) {
        newState.account.profile.individual.address = {
          ...state.account.profile?.individual?.address,
          ...action.value,
          country: "US"
        };
      } else {
        console.error("Error updating individual address data.");
      }
      return newState;
    case "individualBirthdate":
      if (newState.account.profile?.individual?.birthDate) {
        newState.account.profile.individual.birthDate = {
          ...state.account.profile?.individual?.birthDate,
          ...action.value
        };
      } else {
        console.error("Error updating individual birth date.");
      }
      return newState;
    case "businessAddress":
      if (newState.account.profile?.business?.address) {
        newState.account.profile.business.address = {
          ...state.account.profile?.business?.address,
          ...action.value,
          country: "US"
        };
      } else {
        console.error("Error updating business address data.");
      }
      return newState;
    case "businessPhone":
      if (newState.account.profile?.business?.phone) {
        newState.account.profile.business.phone = {
          ...state.account.profile?.business?.phone,
          ...action.value,
          countryCode: "1"
        };
      } else {
        console.error("Error updating business phone data.");
      }
      return newState;
    case "businessCodes":
      if (newState.account.profile?.business?.industryCodes) {
        newState.account.profile.business.industryCodes = {
          ...state.account.profile?.business?.industryCodes,
          ...action.value
        };
      } else {
        console.error("Error updating business industryCodes data.");
      }
      return newState;
    case "businessTaxID":
      if (newState.account.profile?.business?.taxID?.ein) {
        newState.account.profile.business.taxID.ein.number = action.value;
      } else {
        console.error("Error updating EIN.");
      }
      return newState;
    case "prefill":
      action.value.business.profile.business.email =
        action.value.individual.profile.individual.email;
      return {
        ...state,
        account: calculateNewAccount(state, action),
        underwriting: action.value.underwriting,
        prefilled: true
      };
    case "manualAddress":
      newState.manualAddress = action.value;
      return newState;
    case "underwriting":
      if (newState.underwriting) {
        newState.underwriting = {
          ...newState.underwriting,
          ...action.value
        };
      } else {
        console.error("Error updating business underwriting.");
      }
      return newState;
    case "addFile":
      return {
        ...newState,
        files: calculateFiles(files, action)
      };
    case "uploadFiles":
      return { ...newState, files: action.value };
    case "deleteFile":
      return { ...newState, files: calculateDeletedFiles(files, action) };
    default:
      console.error("Invalid action");
      return state;
  }
};

export const initialState: StateType = {
  title: "",
  underwriting: {
    averageTransactionSize: undefined,
    maxTransactionSize: undefined,
    averageMonthlyTransactionVolume: undefined
  },
  account: {
    accountID: "",
    accountType: "business",
    displayName: "",
    customerSupport: {
      email: "",
      phone: {
        number: ""
      },
      website: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateOrProvince: "",
        postalCode: "",
        country: ""
      }
    },
    profile: {
      individual: {
        name: {
          firstName: "",
          middleName: "",
          lastName: "",
          suffix: ""
        },
        phone: {
          number: "",
          countryCode: ""
        },
        email: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          stateOrProvince: "",
          postalCode: "",
          country: ""
        },
        birthDate: {
          day: 0,
          month: 0,
          year: 0
        },
        governmentID: {
          ssn: {
            full: "",
            lastFour: ""
          },
          itin: {
            full: "",
            lastFour: ""
          }
        }
      },
      business: {
        legalBusinessName: "",
        doingBusinessAs: "",
        businessType: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          stateOrProvince: "",
          postalCode: "",
          country: ""
        },
        phone: {
          number: "",
          countryCode: ""
        },
        email: "",
        website: "",
        description: "",
        representatives: [],
        industryCodes: {
          naics: "",
          sic: "",
          mcc: ""
        },
        taxID: {
          ein: {
            number: ""
          }
        }
      }
    },
    termsOfService: {
      acceptedDate: "",
      acceptedIP: ""
    },
    foreignID: "",
    createdOn: "",
    updatedOn: ""
  },
  prefilled: false,
  manualAddress: false,
  supportManualAddress: false,
  files: []
};
