import clsx from "clsx";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { APIContext } from "contexts/APIContext";
import { AvatarUrlsContext } from "contexts/AvatarUrlsContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import FiIcon from "images/fi-icon-dark-fill.svg?react";
import styles from "./BankLogo.module.scss";

interface BankLogoProps {
  // Size in rem
  size?: number;
  // Unique id for the avatar cache
  id?: string;
  // Classname applied to the root element
  className?: string;
  // Fallback icon used when the bank image fails to load. Defaults to FiIcon.
  FallbackIcon?: React.ElementType;
}

const BankLogo = forwardRef<HTMLDivElement, BankLogoProps>(function BankLogo(
  { size, id, className, FallbackIcon = FiIcon },
  ref
) {
  const { moov } = useContext(APIContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const [errorLoading, setErrorLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>();
  const { avatarUrlCache } = useContext(AvatarUrlsContext);

  useEffect(() => {
    const { get, add } = avatarUrlCache;
    const avatarUrl = get(id);
    // avatarUrl was already fetched, no need to fetch again
    if (avatarUrl || avatarUrl === null) {
      setErrorLoading(false);
      setAvatarUrl(avatarUrl);
    }
    // avatarUrl for id had never been fetched before yet
    else if (avatarUrl === undefined && facilitatorID && id) {
      moov.avatars.getBank(facilitatorID, id).then(([blob, error]: any[]) => {
        if (error || !blob) {
          setErrorLoading(true);
          add(id, null);
          return;
        }
        // we add the blob to the cache which, in turn, will create an `ObjectURL` for it
        add(id, blob);
        // we get the `ObjectURL` from the cache as it's now transformed into a string UrlObject
        const avatarUrl = get(id);
        if (avatarUrl) {
          setErrorLoading(false);
          setAvatarUrl(avatarUrl);
        }
      });
    }
  }, [facilitatorID, id]);

  const styling: React.CSSProperties = {};
  if (size) {
    styling.width = `${size}rem`;
    styling.height = `${size}rem`;
  }

  const handleError = () => {
    setErrorLoading(true);
  };

  return (
    <div ref={ref} className={clsx(styles.bankLogo, className)} style={styling}>
      {(errorLoading || !avatarUrl) && <FallbackIcon />}
      {avatarUrl && !errorLoading && (
        <img
          src={avatarUrl}
          alt=""
          className={styles.bankLogoImage}
          loading="lazy"
          width="100%"
          height="100%"
          onError={handleError}
        />
      )}
    </div>
  );
});

export default React.memo(BankLogo);
