import { useContext, useEffect, useState } from "react";
import { BaseButton } from "@moovfinancial/cargo";
import { FormGroup, Textarea, ValidatedInput } from "@moovfinancial/cargo/src/components/Form";
import { REGEX } from "@moovfinancial/common/utils/regex";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { Label } from "components/form/Form";
import { capitalizeString } from "helpers/capitalizeWords";
import { AccountSetupContext } from "../../AccountSetupContext";
import style from "../../AccountSetup.module.scss";

export const DescriptionOrWebsiteInput = () => {
  const {
    account,
    dispatch,
    isErrored,
    resetInput,
    capabilityRequirements,
    invalidInputs,
    validate,
    setInvalidInputs
  } = useContext(AccountSetupContext);
  const { accountLocks, verificationLocked } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);
  const [shouldShowError, setShouldShowError] = useState(true);

  const [activeInput, setActiveInput] = useState<"website" | "description">(
    account.profile?.business?.website || !account.profile?.business?.description
      ? "website"
      : "description"
  );

  useEffect(() => {
    setShouldShowError(true);
  }, [invalidInputs]);

  const getWarningMessage = () => {
    if (
      (activeInput === "website" && !!accountRequirements?.profile?.business?.website) ||
      (activeInput === "description" && !!accountRequirements?.profile?.business?.description)
    ) {
      return `${capitalizeString(activeInput)} is missing.`;
    }
  };

  const getErrorMessage = () => {
    if (invalidInputs.includes(activeInput)) {
      if (!account.profile?.business?.description && !account.profile?.business?.website) {
        return "Website or description is required";
      }
      if (activeInput === "website") {
        return "Please enter a valid website";
      }
      if (activeInput === "description") {
        const descriptionLength = account.profile?.business?.description?.length || 0;
        if (descriptionLength > 0 && descriptionLength < 10) {
          return "Business description must be at least 10 characters.";
        }
        if (descriptionLength > 100) {
          return "Business description must be less than 100 characters.";
        }
      }
    }
    if (isErrored(activeInput)) {
      return `${capitalizeString(activeInput)} is invalid.`;
    }
  };

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch({
      type: "business",
      value: { [activeInput === "website" ? "website" : "description"]: "" }
    });
    resetInput(activeInput);
    setActiveInput((prev) => (prev === "website" ? "description" : "website"));
    setShouldShowError(true);
  };

  const validateDescription = (input: HTMLTextAreaElement) => {
    if (input.value.length < 10 || input.value.length > 100) {
      setInvalidInputs((prev) => [...prev, input.name]);
    }
  };

  const resetErrors = () => {
    setShouldShowError(false);
    resetInput(activeInput);
  };

  return (
    <FormGroup
      className={style.formGroup}
      warningMessage={getWarningMessage()}
      errorMessage={getErrorMessage()}
    >
      <Label
        className={style.toggleLabel}
        label={activeInput === "website" ? "Website" : "Business description"}
        htmlFor={activeInput === "website" ? "website" : "description"}
      >
        <BaseButton
          type="button"
          role="button"
          className={style.toggleButton}
          onClick={handleToggle}
        >
          {activeInput === "website" &&
            (verificationLocked ? "View business description" : "Use business description instead")}
          {activeInput === "description" &&
            (verificationLocked ? "View website" : "Use website instead")}
        </BaseButton>
      </Label>
      {activeInput === "website" ? (
        <>
          <ValidatedInput
            data-testid="websiteInput"
            disabled={!!accountLocks?.profile?.business?.legalBusinessName}
            isErroring={
              (isErrored("website") || invalidInputs.includes("website")) && shouldShowError
            }
            isLocked={!!accountLocks?.profile?.business?.website}
            isWarning={!!accountRequirements?.profile?.business?.website}
            name="website"
            pattern={REGEX.DOMAIN}
            placeholder="https://moov.io"
            required={!account.profile?.business?.description}
            value={account.profile?.business?.website || ""}
            onChange={(e) => {
              resetErrors();
              resetInput(e.target.name);
              dispatch({
                type: "business",
                value: { website: e.target.value }
              });
            }}
            onBlur={(e) => validate(e.target)}
          />
        </>
      ) : (
        <>
          <Textarea
            data-testid="descriptionInput"
            disabled={!!accountLocks?.profile?.business?.description}
            isErroring={
              (isErrored("description") || invalidInputs.includes("description")) && shouldShowError
            }
            isLocked={!!accountLocks?.profile?.business?.description}
            isWarning={!!accountRequirements?.profile?.business?.description}
            maxLength={100}
            minLength={10}
            name="description"
            required={!account.profile?.business?.website}
            value={account.profile?.business?.description || ""}
            onBlur={(e) => validateDescription(e.target)}
            onChange={(e) => {
              resetErrors();
              resetInput(e.target.name);
              dispatch({
                type: "business",
                value: { description: e.target.value }
              });
            }}
          />
        </>
      )}
    </FormGroup>
  );
};
