import React, { useState } from "react";
import {
  ValidationMessage,
  ValidationMessageProps
} from "@moovfinancial/cargo/src/components/Form";
import { PartialRecord } from "@moovfinancial/common/types/PartialRecord";

// import styles from "./useValidatedFields.module.scss";

type ValidateFn = (element: BasicInputElement) => boolean;

export type FieldMeta = {
  isDirty: boolean;
  isValid: boolean;
  message?: string;
  validate?: ValidateFn;
  ref?: BasicInputElementRef;
};

type FieldState<T extends string> = Record<T, FieldMeta>;
type Messages<T extends string> = PartialRecord<T, string>;
type ValidateFns<T extends string> = PartialRecord<T, ValidateFn>;
type BasicInputElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
export type BasicInputElementRef =
  | React.RefObject<HTMLInputElement>
  | React.RefObject<HTMLTextAreaElement>
  | React.RefObject<HTMLSelectElement>;
type Refs<T extends string> = PartialRecord<T, React.RefObject<BasicInputElementRef>>;
interface ElementToValidate {
  element?: BasicInputElement & { __test__forceBasicElement?: boolean };
  message?: string;
  basicValidation?: boolean;
  validate?: ValidateFn;
}

type ElementToValidatePublic = ElementToValidate & {
  /** Allow passing a ref to the element to validate
   * If passed, the element referenced will take precedence over the ref already in the state or an
   * element passed by param
   */
  ref?: BasicInputElementRef;
};

export function isBasicInputElement(
  element?: HTMLElement | BasicInputElementRef
): element is BasicInputElement {
  if (!element) return false;
  return (
    (element as BasicInputElement & { __test__forceBasicElement?: boolean })
      .__test__forceBasicElement ||
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLSelectElement
  );
}

const FIELD_DEFAULTS: FieldMeta = {
  isDirty: false,
  isValid: true,
  message: undefined,
  validate: undefined
};

/**
 *
 * Optionally accepts error messages for fields at initialization in the following map format:
 *
 * {
 *   fieldName: "Error message",
 *   fieldName2: "Error message 2"
 * }
 *
 * Any messages passed after initialization will overwrite the existing message.
 * 🛑 I.e. the state only keeps ONE message per field
 *
 * @param input
 * @param errorMessage - Sets a default error message for all fields
 * @returns [Fields, ValidationError]
 */

export default function useValidatedFields<T extends string>(
  input: Record<T, Partial<FieldMeta>> | Record<T, string> | T[],
  errorMessage = ""
) {
  // State

  // If no inputs are passed, we set them to ""
  const arrayLikeInput = Array.isArray(input)
    ? input.map((fieldName) => [fieldName, errorMessage])
    : (Object.entries(input) as [T, string][]);

  const initialFields = arrayLikeInput.reduce(
    (acc, [fieldName, messageOrField]) => ({
      ...acc,
      [fieldName]:
        typeof messageOrField === "string"
          ? { ...FIELD_DEFAULTS, message: messageOrField }
          : { ...FIELD_DEFAULTS, ...(messageOrField as FieldMeta) }
    }),
    {} as FieldState<T>
  );

  const [fields, setFields] = useState<FieldState<T>>(initialFields);

  // Internal Functions

  //The `flush` parameter is a staple of the internal functions to prevent flushing the state until all fields have been updated
  const setFieldValidity = ({
    field,
    value,
    markAsDirty = false,
    flush = true
  }: {
    field: T;
    value: boolean;
    markAsDirty?: boolean;
    flush?: boolean;
  }) => {
    fields[field].isValid = value;
    if (markAsDirty) setDirtyness(field, false);
    if (flush) setFields({ ...fields });
  };

  function setMapValidity(input: Messages<T>, value: boolean) {
    Object.entries<string>(input as Record<T, string>).forEach(([name, message]) => {
      const fieldName = assertFieldName(name);
      // The last `false` argument is to prevent flushing the state until all fields have been updated
      setFieldMessage(fieldName, message, false);
      setFieldValidity({ field: fieldName, value, flush: false });
    });
    setFields({ ...fields });
  }

  const setDirtyness = (name: T, flush = true) => {
    const fieldName = assertFieldName(name);
    fields[fieldName].isDirty = true;
    if (flush) setFields({ ...fields });
  };

  const setFieldMessage = (name: T, message?: string, flush = true) => {
    const fieldName = assertFieldName(name);
    if (!message) return;
    fields[fieldName].message = message;
    if (flush) setFields({ ...fields });
  };

  const defaultValidationFn = (input: BasicInputElement) => {
    const isHtmlValid = input.checkValidity();
    let isMatchValid = true;
    if ("pattern" in input && input.value) {
      isMatchValid = new RegExp(input.pattern).test(input.value);
    }
    return isHtmlValid && isMatchValid;
  };

  // Base function for the `isValid`, `isInvalid`, and `isDirty` functions
  const baseIsFn = (mode: "valid" | "invalid" | "dirty", input?: T | T[], ...rest: T[]) => {
    const proptToCheck = mode === "valid" || mode === "invalid" ? "isValid" : "isDirty";
    const shouldFlip = mode === "invalid";
    const maybeFlip = (value: boolean) => (shouldFlip ? !value : value);
    // Check that ALL the fields are [valid, invalid, dirty]
    if (!input) {
      const fieldArray = Object.values<FieldMeta>(fields);
      const arrayFn = (field: FieldMeta) => maybeFlip(field[proptToCheck]);
      // `valid` is special because we want to return true when ALL the field inthe state are valid
      // as opposed to checking ANY fields being `dirty` or `invalid`
      return mode === "valid" ? fieldArray.every(arrayFn) : fieldArray.some(arrayFn);
    } else {
      const arrayLikeInput = Array.isArray(input) ? input : [input];
      const fieldArray = [...arrayLikeInput, ...rest];
      const arrayFn = (name: T) => maybeFlip(fields[assertFieldName(name)][proptToCheck]);
      return mode === "valid" ? fieldArray.every(arrayFn) : fieldArray.some(arrayFn);
    }
  };

  const setValidateFn = (
    fieldName: T,
    validate: (element: BasicInputElement) => boolean,
    flushState = true
  ) => {
    fields[fieldName].validate = validate;
    if (flushState) setFields({ ...fields });
  };

  const getValidateFn = (fieldName: T) => fields[fieldName]?.validate;

  const validateOneField = (name: T, elementToValidate?: ElementToValidate) => {
    const fieldName = assertFieldName(name);
    const { element, message, validate: validateArg, basicValidation } = elementToValidate ?? {};
    const shouldDoBasicValidation = basicValidation ?? true;
    let isValid = true;
    if (isBasicInputElement(element) && shouldDoBasicValidation) {
      isValid = isValid && defaultValidationFn(element);
    }
    // Use the validation function passed by param, or the one we have in the state for that field as default
    const validate = validateArg ?? getValidateFn(fieldName);
    // if a custom validation function is passed by param, save it in the state and overwrite the previous one
    if (validateArg) setValidateFn(fieldName, validateArg, false);
    isValid = isValid && (element && validate ? validate(element) : true);
    setFieldValidity({ field: fieldName, value: isValid, flush: false });
    setFieldMessage(fieldName, message, false);
    setFields({ ...fields });
  };

  const resetOneField = (name: T, flush = true) => {
    const fieldName = assertFieldName(name);
    fields[fieldName] = { ...FIELD_DEFAULTS };
    if (flush) setFields({ ...fields });
  };

  const baseSetInvalid = ({
    input,
    rest,
    markAsDirty = false
  }: {
    input: T | T[] | Messages<T>;
    rest: T[];
    markAsDirty?: boolean;
  }) => {
    if (Array.isArray(input) || typeof input === "string") {
      const arrayLikeInput = Array.isArray(input) ? input : [input];
      [...arrayLikeInput, ...rest].forEach((name) => {
        const fieldName = assertFieldName(name);
        setFieldValidity({ field: fieldName, value: false, flush: false, markAsDirty });
      });
      setFields({ ...fields });
    } else {
      setMapValidity(input, false);
    }
  };

  // Public Functions

  /**
   * Marks field(s) as valid. Will also mark them as `dirty` automatically
   * Doesn't accept setting an error message because if the field is valid, no message will be shown
   */
  function setValid(input: T | T[], ...rest: T[]): typeof FIELDS {
    const arrayLikeInput = Array.isArray(input) ? input : [input];
    [...arrayLikeInput, ...rest].forEach((name) => {
      const fieldName = assertFieldName(name);
      setFieldValidity({ field: fieldName, value: true, flush: false });
    });
    setFields({ ...fields });
    return FIELDS;
  }

  /**
   * Marks field(s) as invalid and optionally set the error message. Will also mark the fields as `dirty` automatically
   *
   * Inputs accepted:
   *
   * 1. A single field name `setInvalid("fieldName")`
   * 2. A field name per argument `setInvalid("fieldName1", "fieldName2", "fieldName3")`
   * 3. An array of field names `setInvalid(["fieldName1", "fieldName2"])`
   * 4. A Message map `PartialRecord<T, string>` where the keys are the field names and the values are the messages
   *
   */
  function setInvalid(input: T | T[] | Messages<T>, ...rest: T[]): typeof FIELDS {
    baseSetInvalid({ input, rest });
    return FIELDS;
  }

  function setInvalidAndDirty(input: T | T[] | Messages<T>, ...rest: T[]): typeof FIELDS {
    baseSetInvalid({ input, rest, markAsDirty: true });
    return FIELDS;
  }

  // Marks the field(s) as dirty. Note that once a field is marked as dirty, it will remain dirty until the form is reset
  function setDirty(input: T | T[], ...rest: T[]): typeof FIELDS {
    const arrayLikeInput = Array.isArray(input) ? input : [input];
    [...arrayLikeInput, ...rest].forEach((name) => {
      const fieldName = assertFieldName(name);
      setDirtyness(fieldName, false);
    });
    setFields({ ...fields });
    return FIELDS;
  }

  /**
   * Set a field's error message. You can also set several messages at once by passing a map of field names and messages
   *
   * This is equivalent to calling the init function again, with the caveat that you cannot create new fields with this function,
   * new fields are only created at initialization
   *
   */
  function setMessage(input: T, message: string): typeof FIELDS;
  function setMessage(input: Messages<T>): typeof FIELDS;
  function setMessage(input: T | Messages<T>, message?: string): typeof FIELDS {
    if (typeof input === "string") {
      setFieldMessage(input, message, false);
    } else {
      // need to cast here because input is a PartialRecord<T, string>, but that makes Object.entries unhappy, so pretend it's a Record<T, string>
      Object.entries<string>(input as Record<T, string>).forEach(([name, msg]) => {
        const fieldName = assertFieldName(name);
        setFieldMessage(fieldName, msg, false);
      });
    }
    setFields({ ...fields });
    return FIELDS;
  }

  /**
   * Set a field's validation function. You can also set several validation functions at once by passing a map of field names and functions
   *
   * As with the rest of functions, this will override whatever validate function the given field had before
   */

  function setValidate(input: T, validate: ValidateFn): typeof FIELDS;
  function setValidate(input: ValidateFns<T>): typeof FIELDS;
  function setValidate(input: T | ValidateFns<T>, validate?: ValidateFn): typeof FIELDS {
    if (typeof input === "string" && validate) {
      setValidateFn(input, validate, false);
    } else {
      Object.entries<ValidateFn>(input as Record<T, ValidateFn>).forEach(([name, validateFn]) => {
        const fieldName = assertFieldName(name);
        setValidateFn(fieldName, validateFn, false);
      });
    }
    setFields({ ...fields });
    return FIELDS;
  }

  /**
   * Set a field's ref. You can also set several refs at once by passing a map of field names and refs
   */
  function setRef(input: T, ref: BasicInputElementRef): typeof FIELDS;
  function setRef(input: Refs<T>): typeof FIELDS;
  function setRef(input: T | Refs<T>, ref?: BasicInputElementRef): typeof FIELDS {
    if (typeof input === "string") {
      const fieldName = assertFieldName(input);
      fields[fieldName].ref = ref;
    }
    setFields({ ...fields });
    return FIELDS;
  }

  /**
   *  Checks if the field(s) are valid
   *
   *  Behavior depends on input:
   *
   * - No args: Checks if ALL fields in the state are valid
   * - A field name per argument: Checks if ALL passed fields are valid
   * - An array of field names: Checks if ALL passed fields are valid
   *
   */
  function isValid(): boolean;
  function isValid(input: T): boolean;
  function isValid(input: T[]): boolean;
  function isValid(input: T, ...rest: T[]): boolean;
  function isValid(input: T[], ...rest: T[]): boolean;
  function isValid(input?: T | T[], ...rest: T[]): boolean {
    return baseIsFn("valid", input, ...rest);
  }

  /**
   *  Checks if the field(s) are invalid
   *
   *  Behavior depends on input:
   *
   * - No args: Checks if ALL fields in the state are invalid
   * - A field name per argument: Checks if ALL passed fields are invalid
   * - An array of field names: Checks if ALL passed fields are invalid
   *
   */
  function isInvalid(): boolean;
  function isInvalid(input: T): boolean;
  function isInvalid(input: T[]): boolean;
  function isInvalid(input: T, ...rest: T[]): boolean;
  function isInvalid(input: T[], ...rest: T[]): boolean;
  function isInvalid(input?: T | T[], ...rest: T[]) {
    return baseIsFn("invalid", input, ...rest);
  }

  /**
   *  Checks if the field(s) are dirty
   *
   *  Behavior depends on input:
   *
   * - No args: Checks if ANY field in the state are dirty
   * - A field name per argument: Checks if ANY passed fields are dirty
   * - An array of field names: Checks if ANY passed fields are dirty
   *
   */

  function isDirty(): boolean;
  function isDirty(input: T): boolean;
  function isDirty(input: T[]): boolean;
  function isDirty(input: T, ...rest: T[]): boolean;
  function isDirty(input?: T | T[], ...rest: T[]) {
    return baseIsFn("dirty", input, ...rest);
  }

  function getMessageBase(input: T, undefinedIfValid: boolean = false): string | undefined {
    const message = fields[input].message ?? "";
    const shouldReturnUndefined = undefinedIfValid && isValid(input);
    return shouldReturnUndefined ? undefined : message;
  }

  /**
   * Get message(s) for the field(s)
   *
   * Returns undefined if the field is Valid
   *
   */
  function getMessage(): Messages<T>;
  function getMessage(input: T): string;
  function getMessage(input: T[]): Messages<T>;
  function getMessage(input: T, ...rest: T[]): Messages<T>;
  function getMessage(input?: T | T[], ...rest: T[]) {
    if (!input) {
      return Object.entries<FieldMeta>(fields).reduce<Messages<T>>((acc, [name]) => {
        const fieldName = assertFieldName(name);
        acc[fieldName] = getMessageBase(fieldName);
        return acc;
      }, {});
    }
    if (typeof input === "string" && (!rest || rest.length === 0)) {
      return getMessageBase(input);
    }
    const arrayLikeInput = Array.isArray(input) ? input : [input];
    return [...arrayLikeInput, ...rest].reduce<Messages<T>>((acc, name) => {
      const fieldName = assertFieldName(name);
      acc[fieldName] = getMessageBase(fieldName);
      return acc;
    }, {});
  }

  /**
   * Returns the message for the field, only when the field is invalid
   */

  function getMessageIfInvalid(): Messages<T>;
  function getMessageIfInvalid(input: T): string;
  function getMessageIfInvalid(input: T[]): Messages<T>;
  function getMessageIfInvalid(input: T, ...rest: T[]): Messages<T>;
  function getMessageIfInvalid(input?: T | T[], ...rest: T[]) {
    if (!input) {
      return Object.entries<FieldMeta>(fields).reduce<Messages<T>>((acc, [name]) => {
        const fieldName = assertFieldName(name);
        const message = getMessageBase(fieldName, true);
        if (message !== undefined) {
          acc[fieldName] = message;
        }
        return acc;
      }, {});
    }
    if (typeof input === "string" && (!rest || rest.length === 0)) {
      return getMessageBase(input, true);
    }
    const arrayLikeInput = Array.isArray(input) ? input : [input];
    return [...arrayLikeInput, ...rest].reduce<Messages<T>>((acc, name) => {
      const fieldName = assertFieldName(name);
      const message = getMessageBase(fieldName, true);
      if (message !== undefined) {
        acc[fieldName] = message;
      }
      return acc;
    }, {});
  }

  /**
   * Resets all (no args), a single field, or an array of fields
   *
   * If you instead pass a map of fields, you can reset the passed fields to the given:
   *
   * - message
   * - validate function
   * - isDirty
   * - isValid
   * - ref
   *
   * *NOTE*: if you pass an incomplete Field object, the remaining props will be reset to their default values, current state is ignored
   *
   * Which is basically equivalent to re-initialize those fields as if you were calling the init function again
   *
   */

  function reset(): typeof FIELDS;
  function reset(input: T): typeof FIELDS;
  function reset(input: T[]): typeof FIELDS;
  function reset(input: PartialRecord<T, Partial<FieldMeta>>): typeof FIELDS;
  function reset(input?: T | T[], ...rest: T[]): typeof FIELDS;
  function reset(input?: T | T[] | PartialRecord<T, Partial<FieldMeta>>, ...rest: T[]) {
    // no args case
    if (!input) {
      Object.keys(fields).forEach((field) => resetOneField(assertFieldName(field)));
    }
    // Dealing with the `T | T[]` case
    else if (typeof input === "string" || Array.isArray(input)) {
      const arrayLikeInput = Array.isArray(input) ? input : [input];
      [...arrayLikeInput, ...rest].forEach((field) => resetOneField(assertFieldName(field), false));
      setFields({ ...fields });
    }
    // Dealing with the `PartialRecord<T, Field>` case
    else {
      Object.entries<Partial<FieldMeta>>(input as Record<T, Partial<FieldMeta>>).forEach(
        ([name, fieldObject]) => {
          const fieldName = assertFieldName(name);
          fields[fieldName] = {
            ...FIELD_DEFAULTS,
            ...fieldObject
          };
        }
      );
      setFields({ ...fields });
    }
    return FIELDS;
  }

  /**
   * Returns the field object for a single field or a Field Map for an array of fields
   */

  function getField(): FieldState<T>;
  function getField(input: T): FieldMeta;
  function getField(input: T, ...rest: T[]): PartialRecord<T, FieldMeta>;
  function getField(input: T[]): PartialRecord<T, FieldMeta>;
  function getField(input?: T | T[], ...rest: T[]) {
    if (!input) {
      return fields;
    }
    if (typeof input === "string" && (!rest || rest.length === 0)) {
      const fieldName = assertFieldName(input);
      return fields[fieldName];
    } else {
      const arrayLikeInput = Array.isArray(input) ? input : [input];
      return [...arrayLikeInput, ...rest].reduce(
        (acc, name) => {
          const fieldName = assertFieldName(name);
          acc[fieldName] = fields[fieldName];
          return acc;
        },
        {} as PartialRecord<T, FieldMeta>
      );
    }
  }

  /**
   *
   * Internal function to get the element to validate.
   *
   * If a ref is passed, it will take precedence over the ref already in the state or an element passed by param.
   *
   * Precedence order:
   *
   * 1. Ref passed as argument
   * 2. Element passed as argument
   * 3. Ref already in the field state
   * 4. Query the document to get Element by name as a last resort
   *
   */
  const getElement = (name: T, refOrElementArg?: BasicInputElementRef | BasicInputElement) => {
    const ref = !isBasicInputElement(refOrElementArg) ? refOrElementArg : undefined;
    const fieldName = assertFieldName(name);
    // check if refOrElementArg is instance of BasicInputElementRef or BasicInputElement
    const elementByArgument = ref?.current ?? refOrElementArg;
    if (ref) {
      setRef(fieldName, ref);
    }
    // Element to validate is first, the ref or element passed as argument.
    // If not, we try to get it from the state or, lastly, directly from the DOM
    const element =
      elementByArgument ??
      fields[fieldName]?.ref?.current ??
      document.getElementsByName(fieldName)[0];
    if (!element || !isBasicInputElement(element)) {
      // eslint-disable-next-line no-console
      console.warn(`Element with name '${fieldName}' not found`);
      return;
    }
    return element;
  };

  /**
   *
   * You can call this function in the following ways:
   *
   * - To validate just one field, and use the default HTML validation AND custom `validate` function already in the state for the field, if any:
   *
   *     fields.validate("fieldName", HTMLElement)
   *
   * - To validate a single field while having more control (set Error message, pass overriding `validate` function, etc):
   *
   *     fields.validate({ element: inputElement, validate: () => boolean, message: "Error message", defaultValidation: boolean })`
   *
   * - To validate multiple fields at once by passing an array of field names. The inputs will be selected directly from the DOM by their `name` attribute
   *
   * `defaultValidation` is optional and defaults to `true`. If set to `false`, the function will skip the default HTML validation
   * even if your element is compatible with it
   *
   * NOTES:
   *
   * - If `element` is passed, it will take precedence to any ref previously defined
   *
   */
  function validate(input: T[]): typeof FIELDS;
  function validate(input: PartialRecord<T, ElementToValidatePublic>): typeof FIELDS;
  function validate(input: T, refArg?: BasicInputElementRef, message?: string): typeof FIELDS;
  function validate(input: T, element?: BasicInputElement, message?: string): typeof FIELDS;
  function validate(
    input: PartialRecord<T, ElementToValidate> | T[] | T,
    refOrElementArg?: BasicInputElementRef | BasicInputElement,
    message?: string
  ) {
    if (typeof input === "string") {
      const element = getElement(input, refOrElementArg);
      validateOneField(input, { element, message });
    } else if (Array.isArray(input)) {
      input.forEach((fieldName) => {
        const element = getElement(fieldName);
        validateOneField(fieldName, { element, message });
      });
    } else {
      Object.entries<ElementToValidatePublic>(input as Record<T, ElementToValidatePublic>).forEach(
        ([name, input]) => {
          const fieldName = assertFieldName(name);
          const element = getElement(fieldName, input.ref || input.element);
          validateOneField(fieldName, { ...input, element });
        }
      );
    }
    return FIELDS;
  }

  /**
   *
   * Asserts that the field name is valid and casts it to T
   *
   * If the field name is not valid, it will throw an error in development mode
   * or a log a warning in the console in production mode
   *
   */
  const assertFieldName = (fieldName: string): T => {
    if (!Object.keys(fields).includes(fieldName)) {
      const errorMessage = `Field ${fieldName} not found`;
      // if we're in dev mode, throw an error so we realize we're passing a wrong field name
      if (process.env.NODE_ENV === "development") {
        throw new Error(errorMessage);
      } else {
        // eslint-disable-next-line no-console
        console.warn(errorMessage);
      }
      return fieldName as T;
    }
    return fieldName as T;
  };

  const FIELDS = {
    assertFieldName,
    isDirty,
    setDirty,
    isValid,
    setValid,
    isInvalid,
    setInvalid,
    setInvalidAndDirty,
    getMessage,
    getMessageIfInvalid,
    setMessage,
    getField,
    validate,
    setValidate,
    setRef,
    reset
  };

  type ValidationErrorProps = { for: T } & ValidationMessageProps;

  const ValidationError = ({ for: field, noMargins = true, ...rest }: ValidationErrorProps) =>
    // <div className={styles.errorPlaceholder}>
    FIELDS.isInvalid(field) && FIELDS.isDirty(field) ? (
      <div>
        <ValidationMessage
          message={FIELDS.getMessage(field) || ""}
          noMargins={noMargins}
          {...rest}
        />
      </div>
    ) : null;

  // return [Fields, ValidationError];
  return { fields: FIELDS, ValidationError };
}
