import clsx from "clsx";
import { ReactNode, forwardRef, useMemo } from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import styles from "./Input.module.scss";

export type InputTheme = Theme<typeof styles>;
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Optional ref to pass to the input element
   */
  ref?: React.RefObject<HTMLInputElement>;
  /**
   * Optional theme object to override or change the element's style
   */
  theme?: InputTheme;
  /**
   * Optional label displayed above the input. It can be text or a ReactNode
   */
  label?: string | ReactNode;
  /**
   * Optional type of the input element
   *
   * @default "text"
   */
  type?: string;
  /**
   * Optionally, if you want to use a custom input component, you can pass it here
   *
   * Note: if passed, it will override everything, except the label prop and theme.labelElement
   */
  InputComponent?: React.ReactNode;
}

/**
 * This is Moov's basic-est, type-agnostic, input component.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { label, type, theme, InputComponent, ...rest }: InputProps,
  ref
) {
  const elementId = useMemo(
    () =>
      label && typeof label === "string"
        ? `input-${label.replace(/\s/g, "-").toLowerCase()}`
        : undefined,
    [label]
  );

  const BaseInput = (
    <input
      id={elementId}
      type={type}
      ref={ref}
      className={clsx(styles.inputElement, theme?.inputElement)}
      {...rest}
    />
  );
  const inputComponent = InputComponent ?? BaseInput;
  return (
    <>
      {!!label && (
        <label className={clsx(styles.labelElement, theme?.labelElement)} htmlFor={elementId}>
          {label}
        </label>
      )}
      {inputComponent}
    </>
  );
});
