import { Representative } from "api/Account.model";
import { PatchRepresentative } from "api/v2";
import deepDiff from "./deepDiff";
import { objectIsEmpty } from "./objectIsEmpty";
import pruneObject from "./pruneObject";

const isEmptyRep = (rep: Partial<Representative>) => {
  const keys = Object.keys(rep);
  const nonIDValues = keys.filter((key) => key !== "representativeID" && key !== "accountID");
  return !nonIDValues.length;
};

export const pruneRepForPatch = (
  originalRep: Partial<Representative> | undefined,
  repForUpdate: Partial<Representative>
): PatchRepresentative | undefined => {
  if (!originalRep) {
    if (repForUpdate.representativeID) {
      return { ...repForUpdate, representativeID: repForUpdate.representativeID };
    }
    return undefined;
  }

  const repDiff = deepDiff(originalRep, repForUpdate);

  if (isEmptyRep(repDiff)) {
    return undefined;
  }
  repDiff.representativeID = originalRep.representativeID;

  const prunedRep = pruneRep(repDiff);

  if (!objectIsEmpty(prunedRep.address) && !prunedRep.address.country) {
    prunedRep.address.country = "US";
  }

  return prunedRep;
};

export const pruneRep = (representative: Partial<Representative>) => {
  const shouldPrune = (key: string, value: any) => {
    if (value === null || value === undefined || value === "") return true;
    if (key === "address" && objectIsEmpty(value)) return true;
    if (key === "birthDate" && objectIsEmpty(value)) return true;
    if (key === "responsibilities" && objectIsEmpty(value)) return true;
    if (key === "name" && objectIsEmpty(value)) return true;
    if (key === "birthDateProvided") return true;
    if (key === "governmentIDProvided") return true;
    if (key === "phone" && !value?.number) return true;
    if (key === "updatedOn") return true;
    return false;
  };

  return pruneObject(representative, shouldPrune);
};

export const getName = (represenative?: Representative) => {
  if (!represenative) return;
  const { name } = represenative;
  return `${name.firstName} ${name.lastName}`;
};
