import { Representative } from "api/Account.model";

export const isRepresentativeComplete = (representative: Partial<Representative>) => {
  if (
    representative &&
    representative.name?.firstName &&
    representative.name?.lastName &&
    representative.email &&
    representative.responsibilities &&
    representative.address?.addressLine1 &&
    representative.birthDateProvided &&
    representative.governmentIDProvided &&
    representative.phone?.number
  ) {
    return true;
  }
  return false;
};

export const hasController = (representatives: Representative[]) => {
  const hasController = representatives.find(
    (representative) => representative.responsibilities?.isController === true
  );
  if (hasController) {
    return true;
  }
  return false;
};
