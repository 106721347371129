/**
 *  🔴 READ BEFORE USING REGEXES IN `pattern` PROP 🔴
 *
 *   https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern
 *
 *   > The pattern's regular expression is compiled with the 'v' flag. This makes the regular expression unicode-aware,
 *   > **and also changes how character classes are interpreted.**
 *
 *   Which means that the regex pattern you provide will be interpreted as a Unicode regex pattern, which is probably not what you have in mind.
 *
 *   - Make sure to test the regex in question with the /v pattern in https://regex101.com/ or similar sites
 *   - Alternatively, just don't use the `pattern` prop and validate the input through the `validate` function in `useValidatedFields`
 *     through element.value.match(REGEX.PASSWORD_REGEX) or similar
 *
 */

// https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
// Opting for a simpler, less opinionated email regex as there's no "good canonical way" to validate emails anyway (read the thread above)
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// https://stackoverflow.com/questions/5142103/regex-to-validate-password-strength
/** Password mathching:
 *
 *  - At least 12 characters
 *  - At least one number
 *  - At least one Uppercase letter
 *  - At least one lowercase letter
 *  - At least one special character
 *  - Spaces ARE allowed
 */
const PASSWORD_REGEX =
  /^(?=.*[!@#$&*%^()_\-\\/=?+<>`~"';:{}[\]])(?=.*[0-9].*)(?=.*[a-z].*)(?=.*[A-Z].*).{12,128}$/;

const UUID_REGEX =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const REGEX = {
  DOMAIN: "((https?:\\/\\/)?([a-zA-Z0-9]([a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}\\b)", // https://regex101.com/r/1iheKP/1
  EIN: "^[0-9][0-9]-?[0-9]{7,8}$", // https://regex101.com/r/3GYlW9/1
  EMAIL_REGEX,
  EMAIL: EMAIL_REGEX.toString().slice(1, -1),
  PASSWORD_REGEX,
  PASSWORD: PASSWORD_REGEX.toString().slice(1, -1),
  NO_ILLEGAL_CHARACTERS: "^[^<>\\{\\}\\[\\];`]+$", //https://regex101.com/r/3CkVVL/1,
  PHONE: "[0-9]+-[0-9]+-[0-9]+", //https://regex101.com/r/aC1XRM/1
  SSN: "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$", // https://regex101.com/r/03vkgq/2
  URL_ACCOUNT_ID: /^(?:\/admin)?(?:\/accounts\/)(?<accountID>[^/?&]*)/, // https://regex101.com/r/vOAdg7/1 -- note that a useless escape character was removed
  URL_WATCHLIST_ID: /^(?:\/admin\/watchlist\/)(?<accountID>[^/?&]*)/, // https://regex101.com/r/EaHqXq/1 -- note that a useless escape character was removed
  UUID: UUID_REGEX,
  UUID_PATTERN: UUID_REGEX.toString().slice(1, -1)
};
