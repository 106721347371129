import { FloatingLabelInput, FloatingLabelInputProps } from "./FloatingLabelInput";
import { PasswordInput } from "./PasswordInput";

export interface FloatingLabelPasswordInputProps
  extends Omit<FloatingLabelInputProps<typeof PasswordInput>, "disabled" | "isLocked"> {
  /**
   * The label for the input
   */
  label: string;
}

export const FloatingLabelPasswordInput = ({ label, ...rest }: FloatingLabelPasswordInputProps) => {
  return <FloatingLabelInput label={label} as={PasswordInput} {...rest} />;
};
