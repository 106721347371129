/** Uses a property path string, like "profile.business.phone.number",
 * to access the corresponding property on a given object.
 * returns undefined if the property is not found. */
export function getObjectProperty(obj: object, path: string) {
  try {
    return unsafeGetObjectProperty(obj, path);
  } catch (e) {
    return undefined;
  }
}

/** Uses a property path string, like "profile.business.phone.number",
 * to access the corresponding property on a given object.
 * Will throw an error if the property is not found */
function unsafeGetObjectProperty(obj: object, path: string) {
  let tempObj = obj as Record<string, any>;
  let cleanedPath = path;
  cleanedPath = cleanedPath.replace(/\[(\w+)\]/g, ".$1"); // convert [indexes] to .properties
  cleanedPath = cleanedPath.replace(/^\./, ""); // strip any leading dots
  const steps = cleanedPath.split(".");
  for (let i = 0; i < steps.length; ++i) {
    const step = steps[i];
    if (step in tempObj) {
      tempObj = tempObj[step];
    } else {
      throw new Error(
        "unsafeGetObjectProperty: Could not find object property corresponding to path."
      );
    }
  }
  return tempObj;
}

/** Uses a property path string, like "profile.business.phone.number",
 * to access the corresponding property on a given object and set a new value.
 * Fails silently if the property is not found */
export function setObjectProperty(obj: object, path: string, newValue: any) {
  try {
    unsafeSetObjectProperty(obj, path, newValue);
  } catch {
    return;
  }
}

/** Uses a property path string, like "profile.business.phone.number",
 * to access the corresponding property on a given object and set a new value.
 * Will throw an error if the property is not found */
function unsafeSetObjectProperty(obj: object, path: string, newValue: any) {
  let tempObj = obj as Record<string, any>;
  let cleanedPath = path;
  cleanedPath = cleanedPath.replace(/\[(\w+)\]/g, ".$1"); // convert [indexes] to .properties
  cleanedPath = cleanedPath.replace(/^\./, ""); // strip any leading dots
  const steps = cleanedPath.split(".");
  for (let i = 0; i < steps.length; ++i) {
    const step = steps[i];
    if (step in tempObj && i < steps.length - 1) {
      tempObj = tempObj[step];
    } else if (step in tempObj && i === steps.length - 1) {
      tempObj[step] = newValue;
    } else {
      throw new Error(
        "unsafeSetObjectProperty: Could not find object property corresponding to path."
      );
    }
  }
}
