import { AnimatePresence } from "framer-motion";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "@moovfinancial/common/hooks/useDebounce";
import { useOnClickOut } from "@moovfinancial/common/hooks/useOnClickOut";
import { ValidatedInput, ValidatedInputProps } from "./ValidatedInput";
import styles from "./AutoComplete.module.scss";

export type AutoCompleteProps<T> = ValidatedInputProps & {
  /**
   * A function to generate the ReactNode to display for each suggestion
   */
  displaySuggestion: (suggestion: T) => ReactNode;
  /**
   * A function to fetch suggestions based on the search text
   */
  fetchSuggestions: (search: string) => Promise<T[]>;
  /**
   * The message to display when there are no results
   *
   * @default "No results found"
   */
  noResultsMessage?: string;
  /**
   * A callback function to be called when the user selects a suggestion
   */
  onSuggestionSelected?: (suggestion: T) => void;
};

export const AutoComplete = <T,>({
  displaySuggestion,
  fetchSuggestions,
  label,
  name,
  noResultsMessage = "No results found",
  onBlur,
  onChange,
  onClick,
  onFocus,
  onSuggestionSelected,
  value,
  ...rest
}: AutoCompleteProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const firstMenuItemRef = useRef<HTMLLIElement>(null);
  const [searchData, setSearchData] = useState<T[]>([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  useOnClickOut(listRef, () => {
    setHasFocus(false);
  });

  useEffect(() => {
    const getSuggestions = async () => {
      if (debouncedSearchText && debouncedSearchText.length > 2) {
        const suggestions = await fetchSuggestions(debouncedSearchText);
        if (suggestions) {
          setSearchData(suggestions);

          // If the fetchSuggestions function changes, maybe because we need to fetch different results after a suggestion is selected, open the menu
          setHasFocus(true);

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }
      }
    };
    getSuggestions();
  }, [debouncedSearchText, fetchSuggestions]);

  const handleOnInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );

  const handleOnInputClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      setHasFocus(true);
      onClick?.(e);
    },
    [onClick]
  );

  const handleOnInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      /* if we allow the blur to happen too quickly the menu click is not registered */
      setTimeout(() => {
        onBlur?.(e);
      }, 200);
    },
    [onBlur]
  );

  const handleOnInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setHasFocus(true);
      e.target.focus();
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleOnInputKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown": {
        if (firstMenuItemRef.current) {
          setHasFocus(true);
          firstMenuItemRef.current.focus();
        }
        break;
      }
      case "Escape": {
        setHasFocus(false);
        break;
      }
      default:
        break;
    }
  }, []);

  const handleMenuSelect = useCallback(
    async (suggestion: T) => {
      onSuggestionSelected?.(suggestion);
      setHasFocus(false);
    },
    [onSuggestionSelected]
  );

  const handleMenuKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>, suggestion: T) => {
      e.stopPropagation();
      e.preventDefault();

      switch (e.key) {
        case "ArrowDown": {
          if (hasFocus && e.target instanceof Element) {
            const sibling = e.target.nextElementSibling;
            if (sibling instanceof HTMLElement) {
              sibling.focus();
            }
          }
          break;
        }
        case "ArrowUp": {
          if (hasFocus && e.target instanceof Element) {
            const sibling = e.target.previousElementSibling;
            if (sibling instanceof HTMLElement) {
              sibling.focus();
            }
          }
          break;
        }
        case "Enter": {
          handleMenuSelect(suggestion);
          break;
        }
        case "Escape": {
          setHasFocus(false);
          break;
        }
        default:
          break;
      }
    },
    [handleMenuSelect, hasFocus]
  );

  return (
    <div className={styles.comboboxWrapper}>
      <ValidatedInput
        aria-autocomplete="list"
        aria-controls={`${name}-list`}
        aria-expanded={hasFocus}
        aria-haspopup="listbox"
        label={label}
        name={name}
        onBlur={handleOnInputBlur}
        onChange={handleOnInputChange}
        onClick={handleOnInputClick}
        onFocus={handleOnInputFocus}
        onKeyUp={handleOnInputKeyUp}
        ref={inputRef}
        role="combobox"
        value={value}
        {...rest}
      />
      <AnimatePresence>
        {hasFocus && !!value && typeof value !== "number" && value?.length >= 3 && (
          <ul className={styles.combobox} id={`${name}-list`} role="listbox" ref={listRef}>
            {searchData.map((item: T, index) => (
              <li
                aria-selected="false"
                className={styles.comboboxItem}
                key={index}
                onClick={() => handleMenuSelect(item)}
                onKeyUp={(e) => handleMenuKeyUp(e, item)}
                ref={index === 0 ? firstMenuItemRef : null}
                role="option"
                tabIndex={0}
              >
                {displaySuggestion(item)}
              </li>
            ))}
            {searchData.length === 0 && value.length >= 3 && (
              <li key="noMatches" className={styles.comboboxItem}>
                {noResultsMessage}
              </li>
            )}
          </ul>
        )}
      </AnimatePresence>
    </div>
  );
};
