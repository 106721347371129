import { LiveMoovAPIClient } from "../api";
import { APIResponse } from "../request";
import { AdminMetadataResponse, AmexRegistrationPatch } from "./adminMetadata.model";

export interface AdminMetadataAPI {
  /** Fetches the card brand submids */
  getMetadata(
    facilitatorID: string,
    connectedAccountID: string
  ): APIResponse<AdminMetadataResponse>;
  /** Updates amex submid */
  patchAmex(
    facilitatorID: string,
    connectedAccountID: string,
    amexSubMid: string
  ): APIResponse<undefined>;
  /** Updates amex registrations */
  patchAmexRegistration(
    accountID: string,
    facilitatorID: string,
    amexRegistration: AmexRegistrationPatch
  ): APIResponse<undefined>;
}

export class LiveAdminMetadataAPI implements AdminMetadataAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async getMetadata(
    facilitatorID: string,
    connectedAccountID: string
  ): APIResponse<AdminMetadataResponse> {
    const [result, err, resp] = await this._client.request<AdminMetadataResponse>(
      `/admin/accounts/${connectedAccountID}/metadata`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err, resp];
  }

  async patchAmex(
    facilitatorID: string,
    connectedAccountID: string,
    amexSubMid: string
  ): APIResponse<undefined> {
    const [result, err, resp] = await this._client.request<undefined>(
      `/admin/accounts/${connectedAccountID}/metadata/amex`,
      {
        method: "PATCH",
        json: { amexSubMid: amexSubMid },
        xAccountID: facilitatorID
      }
    );
    return [result, err, resp];
  }
  async patchAmexRegistration(
    accountID: string,
    facilitatorID: string,
    amexRegistration: AmexRegistrationPatch
  ): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/admin/accounts/${accountID}/metadata/amex-registration-status`,
      {
        method: "PATCH",
        json: amexRegistration,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
