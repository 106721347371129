import { PaginationCursor } from "api/v2/common.model";
import { LiveMoovAPIClient } from "../api";
import { RequestError } from "../request";
import {
  AbbreviatedMatch,
  EnrichedAbbreviatedMatch,
  EnrichedMatch,
  MatchDecision,
  MatchEnrichedAcccount,
  MatchFilter
} from "./match.model";

export interface MatchAPI {
  /** Get match record. */
  get(accountID: string): Promise<[EnrichedMatch | undefined, RequestError | undefined]>;

  /** Lists match records. */
  list(
    filter?: Omit<MatchFilter, "status">,
    cursor?: PaginationCursor
  ): Promise<[EnrichedAbbreviatedMatch[] | undefined, RequestError | undefined]>;

  /** Update match status. */
  update(
    accountID: string,
    facilitatorID: string,
    decision: MatchDecision
  ): Promise<[MatchDecision | undefined, RequestError | undefined]>;

  /* Reprocesses match */
  reprocess(
    accountID: string,
    facilitatorID: string
  ): Promise<[undefined, RequestError | undefined]>;
}

export class LiveMatchAPI implements MatchAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(accountID: string): Promise<[EnrichedMatch | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/dashboard/admin/accounts/${accountID}/matches`
    );
    return [result, err];
  }

  async list(
    filter?: MatchFilter,
    cursor?: PaginationCursor
  ): Promise<[EnrichedAbbreviatedMatch[] | undefined, RequestError | undefined]> {
    let query;
    if (filter || cursor) {
      query = { ...filter, ...cursor };
    }
    // Get the match and the accounts
    const [result, err] = await this._client.request<any>("/dashboard/matches", {
      query
    });
    if (err) return [undefined, err];
    if (result === undefined) return [result, undefined];

    // Translate from backend matches to an match enriched with account data
    const enrichedMatches: EnrichedAbbreviatedMatch[] = result.matches.map((m: AbbreviatedMatch) =>
      toEnrichedMatchList(m, result.accounts)
    );
    return [enrichedMatches, undefined];
  }

  async update(
    accountID: string,
    facilitatorID: string,
    decision: MatchDecision
  ): Promise<[MatchDecision | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/admin/accounts/${accountID}/match-status`,
      {
        method: "POST",
        json: decision,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async reprocess(
    accountID: string,
    facilitatorID: string
  ): Promise<[undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/admin/accounts/${accountID}/reprocess-match`,
      {
        method: "POST",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}

function toEnrichedMatchList(
  unenriched: AbbreviatedMatch,
  accounts: Record<string, MatchEnrichedAcccount>
): EnrichedAbbreviatedMatch {
  return {
    ...unenriched,
    connectedAccount: accounts[unenriched.accountID]
  };
}
