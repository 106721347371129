export interface ApiKey {
  applicationKeyID: string;
  name: string;
  description: string;
  origins: string[];
  clientId: string;
  lastUsed?: string;
  createdOn: string;
}

export interface CreateApiKey extends Pick<ApiKey, "name" | "description" | "origins"> {}

export interface UpdateApiKey
  extends Pick<ApiKey, "applicationKeyID" | "name" | "description" | "origins"> {}

export interface CreatedApiKey extends ApiKey {
  clientSecret: string;
}

export const defaultCreateApiKey: CreateApiKey = {
  name: "",
  description: "",
  origins: []
};
