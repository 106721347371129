import { BankAccountStatus, DecryptedMicroDeposits, LiveMoovAPIClient } from "api/v2";
import { RequestError } from "api/v2/request";
import {
  BankAccount,
  BankAccountBypass,
  BankAccountBypassStatus,
  NewBankAccount
} from "./bankAccounts.model";

export interface BankAccountsAPI {
  /** List all the bank accounts associated with a particular Moov account. */
  list(
    facilitatorID: string,
    accountID: string
  ): Promise<[BankAccount[] | undefined, RequestError | undefined]>;
  /** Retrieve bank account details (i.e. routing number or account type) associated with a specific Moov account. */
  get(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<[BankAccount | undefined, RequestError | undefined]>;
  /** Link a bank account to an existing Moov account. */
  create(
    facilitatorID: string,
    accountID: string,
    bankAccount: NewBankAccount
  ): Promise<[BankAccount | undefined, RequestError | undefined]>;
  /** Discontinue using a specified bank account linked to a Moov account. */
  disable(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<RequestError | undefined>;
  /** Initiates the micro-deposit verification, sending two small credit transfers to the bank account you want to confirm. */
  initiateMicroDeposits(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<RequestError | undefined>;
  /** Complete the micro-deposit validation process by passing the amounts of the two transfers. */
  completeMicroDeposits(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string,
    amounts: number[]
  ): Promise<[{ status: "verified" } | undefined, RequestError | undefined]>;
  /** Get bank bypass status of an account. */
  getBypass(
    facilitatorID: string,
    accountID: string
  ): Promise<[BankAccountBypass | undefined, RequestError | undefined]>;
  /** Add an account to the bank bypass list. */
  updateBypass(
    facilitatorID: string,
    accountID: string,
    status: BankAccountBypassStatus
  ): Promise<[BankAccountBypass | undefined, RequestError | undefined]>;
  /** Update the status of a bank account. */
  updateStatus(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string,
    status: BankAccountStatus,
    statusReason: string
  ): Promise<RequestError | undefined>;
  /** Get the micro-deposit amounts sent to a bank account. */
  decryptMicroDeposits(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<[DecryptedMicroDeposits | undefined, RequestError | undefined]>;
}

export class LiveBankAccountsAPI implements BankAccountsAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    accountID: string
  ): Promise<[BankAccount[] | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<BankAccount[]>(
      `/accounts/${accountID}/bank-accounts`,
      { xAccountID: facilitatorID }
    );
    return [result, err];
  }

  async get(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<[BankAccount | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<BankAccount>(
      `/accounts/${accountID}/bank-accounts/${bankAccountID}`,
      { xAccountID: facilitatorID }
    );
    return [result, err];
  }

  async create(
    facilitatorID: string,
    accountID: string,
    bankAccount: NewBankAccount
  ): Promise<[BankAccount | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<BankAccount>(
      `/accounts/${accountID}/bank-accounts`,
      {
        method: "POST",
        json: { account: bankAccount },
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async disable(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<RequestError | undefined> {
    const [_, err] = await this._client.request<void>(
      `/accounts/${accountID}/bank-accounts/${bankAccountID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID || undefined
      }
    );
    return err;
  }

  async initiateMicroDeposits(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<RequestError | undefined> {
    const [_, err] = await this._client.request<void>(
      `/accounts/${accountID}/bank-accounts/${bankAccountID}/micro-deposits`,
      {
        method: "POST",
        xAccountID: facilitatorID
      }
    );
    return err;
  }

  async completeMicroDeposits(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string,
    amounts: number[]
  ): Promise<[{ status: "verified" } | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<{ status: "verified" }>(
      `/accounts/${accountID}/bank-accounts/${bankAccountID}/micro-deposits`,
      {
        method: "PUT",
        json: { amounts },
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getBypass(
    facilitatorID: string,
    accountID: string
  ): Promise<[BankAccountBypass | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<BankAccountBypass>(
      `/admin/bank-accounts/bypass-list/${accountID}`,
      {
        method: "GET",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async updateBypass(
    facilitatorID: string,
    accountID: string,
    status: BankAccountBypassStatus
  ): Promise<[BankAccountBypass | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<BankAccountBypass>(
      `/admin/bank-accounts/bypass-list/${accountID}`,
      {
        method: "PUT",
        json: { status },
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async updateStatus(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string,
    status: BankAccountStatus,
    statusReason: string
  ): Promise<RequestError | undefined> {
    const [_, err] = await this._client.request<void>(
      `/accounts/${accountID}/bank-accounts/${bankAccountID}`,
      {
        method: "PATCH",
        json: { status, statusReason },
        xAccountID: facilitatorID
      }
    );
    return err;
  }

  async decryptMicroDeposits(
    facilitatorID: string,
    accountID: string,
    bankAccountID: string
  ): Promise<[DecryptedMicroDeposits | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<DecryptedMicroDeposits>(
      `/accounts/${accountID}/bank-accounts/${bankAccountID}/micro-deposits`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
