import { useCallback, useContext, useState } from "react";
import { Link, useLinkClickHandler, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseButton, Icon, Nameplate } from "@moovfinancial/cargo";
import { IconAddCircleOutlined } from "@moovfinancial/cargo/icons";
import UserAvatar from "components/avatar/UserAvatar";
import Badge from "components/badge/Badge";
import Dropdown from "components/dropdown/Dropdown";
import { Checkbox } from "components/form/Form";
import { Representative as LegacyRepresentative } from "api/Account.model";
import { OnboardingContext, Representative } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { OwnersStepHeading } from "./OwnersStepHeading";
import { RemoveOwnerConfirmation } from "./RemoveOwnerConfirmation";
import styles from "./ReviewOwners.module.scss";

const getName = (representative: Representative) =>
  `${representative.name?.firstName ?? ""} ${representative.name?.lastName ?? ""}`;
const getSpecialBadgeContent = (representative: Representative) =>
  representative.responsibilities?.isController ? "Control officer" : undefined;
const getSubtitle = (representative: Representative) => {
  const jobTitle = representative.responsibilities?.jobTitle;
  const ownership = representative.responsibilities?.ownershipPercentage;
  if (jobTitle && ownership) {
    return `${jobTitle} - ${ownership}% ownership`;
  } else if (jobTitle) {
    return jobTitle;
  } else if (ownership) {
    return `${ownership}% ownership`;
  } else {
    return undefined;
  }
};

const AddOwnerRow = ({ isEmptyState = false }: { isEmptyState?: boolean }) => {
  const handleClick = useLinkClickHandler<HTMLButtonElement>("./add");
  return (
    <BaseButton className={styles.addRow} onClick={handleClick}>
      <Icon iconComponent={IconAddCircleOutlined} />
      {isEmptyState ? "Add an owner" : "Add another profile"}
    </BaseButton>
  );
};

const DropDownRowMenu = ({
  onRemove,
  representative
}: {
  onRemove: (rep: Representative) => void;
  representative: Representative;
}) => {
  const handleClick = useLinkClickHandler<HTMLAnchorElement>(
    `./edit/${representative.representativeID}`
  );

  return (
    <>
      <Dropdown right>
        <Dropdown.Toggle data-testid="repDropdownToggle" className={styles.toggle} icon more />
        <Dropdown.Menu title="Representative details">
          <Dropdown.Item onClick={handleClick}>Edit profile</Dropdown.Item>
          <Dropdown.Item destructive onClick={() => onRemove(representative)}>
            Delete profile
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const RightRowContent = ({
  onRemove,
  representative
}: {
  onRemove: (representative: Representative) => void;
  representative: Representative;
}) => {
  const specialBadgeContent = getSpecialBadgeContent(representative);
  return (
    <div className={styles.rightRowContent}>
      {specialBadgeContent ? (
        <Badge color="purpleDynamic" label={specialBadgeContent} className={styles.badge} />
      ) : null}
      <DropDownRowMenu onRemove={onRemove} representative={representative} />
    </div>
  );
};

export const ReviewOwners = () => {
  const navigate = useNavigate();
  const { account, patchAccount, representatives } = useContext(OnboardingContext);
  const { getNextStepUrl, getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl());
  const [hasCertified, setHasCertified] = useState(
    account.profile?.business?.ownersProvided ?? false
  );
  const [representativeToRemove, setRepresentativeToRemove] = useState<
    Representative | undefined
  >();

  const onContinue = useCallback(async () => {
    const updated = {
      ...account,
      profile: {
        ...account.profile,
        business: { ...account.profile?.business, ownersProvided: hasCertified }
      }
    };

    const { data, error } = await patchAccount(updated);

    if (error) {
      toast("Unable to save account. Please try again.");
      return;
    }

    if (data) {
      navigate(getNextStepUrl());
    }
  }, [account, getNextStepUrl, hasCertified, navigate, patchAccount]);

  return (
    <div className={styles.content}>
      {representativeToRemove && (
        <RemoveOwnerConfirmation
          onCancel={() => setRepresentativeToRemove(undefined)}
          onRemove={() => {
            setRepresentativeToRemove(undefined);
          }}
          representative={representativeToRemove}
        />
      )}
      <div className={styles.layout}>
        <OwnersStepHeading />
        {representatives?.map((rep) => (
          <Link key={rep.representativeID} to={`./edit/${rep.representativeID}`}>
            <Nameplate
              Icon={<UserAvatar representative={rep as LegacyRepresentative} size={40} />}
              title={getName(rep)}
              subtitle={getSubtitle(rep)}
              rightContent={
                <RightRowContent
                  onRemove={(representative) => setRepresentativeToRemove(representative)}
                  representative={rep}
                />
              }
              theme={{
                nameplate: styles.nameplate,
                rightContent: styles.rightContent
              }}
            />
          </Link>
        ))}
        <AddOwnerRow isEmptyState={!representatives?.length} />
        <div className={styles.disclaimer}>
          <Checkbox
            checked={hasCertified}
            id="disclaimer"
            onChange={(e) => setHasCertified(e.target.checked)}
          />
          <label htmlFor="disclaimer">
            I certify that I&apos;ve listed all owners with at least a 25% stake in my company, and
            one controller with significant management authority.
          </label>
        </div>
        <FooterButtons disabled={!hasCertified} onBack={handleBackClick} onContinue={onContinue} />
      </div>
    </div>
  );
};
