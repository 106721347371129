"use client";

import clsx from "clsx";
import { CargoElement, CargoElementProps } from "../../CargoElement";
import { CurrentStep } from "./CurrentStep";
import { Step as StepComponent } from "./Step";
import { Step as StepType } from "./step.model";
import styles from "./Stepper.module.scss";

export interface StepperProps extends CargoElementProps {
  steps: StepType[];
}

export function Stepper({ className, steps, ...props }: StepperProps) {
  return (
    <CargoElement className={clsx(styles.container, className)} {...props}>
      {steps.map((step, index) => {
        return <StepComponent key={index} step={step} />;
      })}
      <CurrentStep steps={steps} />
    </CargoElement>
  );
}
