import { Responsibilities } from "api/Account.model";
import {
  Account,
  AccountUnderwriting,
  Address,
  Business,
  CardVolumeDistribution,
  Fulfillment,
  GovernmentID,
  Individual,
  Name,
  Representative,
  VolumeByCustomerType
} from "api/v2";
import {
  HTTP422Error,
  HTTPError,
  RecursiveErrorObject,
  RequestError,
  isHTTP422Error,
  isHTTPError,
  isLegacyHTTP409Error,
  isLegacyHTTP422Error,
  isNetworkError,
  isPostProcessingError
} from "api/v2/request";
import { capitalizeString } from "./capitalizeWords";

export const ERR_UNEXPECTED =
  "Something unexpected happened. Wait a minute and try again, or submit a ticket via support.moov.io if the problem persists.";
const PASSWORD_LENGTH_ERROR = "Passwords must be a minimum of 12 characters";
const PASSWORD_UPPERCASE_ERROR = "Password must contain at least one uppercase character";
const PASSWORD_LOWERCASE_ERROR = "Password must contain at least one lowercase character";
const PASSWORD_SYMBOL_ERROR = "Password must contain at least one special character";
const PASSWORD_NUMBER_ERROR = "Password must contain at least one number";

export function is4xx(status: number): boolean {
  return status >= 400 && status < 500;
}

/**
 * Handles errors generated by our API client.
 *
 * @param err - The error to returned by our API client
 * @returns Error message to display to the user
 */
export function handleError(err: RequestError | Error): string {
  if ("type" in err) {
    switch (err.type) {
      case "network":
        // We don't log this -- it's not our fault
        return "Something went wrong with the connection. Check your network and try again.";
      case "http":
        if (is4xx(err.resp.status)) {
          return handle4xxError(err);
        } else {
          return ERR_UNEXPECTED;
        }
    }
  }
  return ERR_UNEXPECTED;
}

function handle4xxError(err: HTTPError): string {
  let msg: string;

  switch (err.resp.status) {
    case 401:
      msg = "You are no longer authenticated. Please sign in again.";
      break;
    case 402:
      msg = "There was an error processing the request. Please try again.";
      break;
    case 403:
      msg = "You are not authorized to perform this action.";
      break;
    case 404:
      msg = "The requested resource was not found.";
      break;
    case 408:
      msg = "The request timed out. Please try again, or contact us if the problem persists.";
      break;
    case 422:
      // We shouldn't get here. The FE code should make sure request bodies are valid.
      msg = "The request was missing required fields or had invalid values.";
      break;
    default:
      // It's our fault here because we should've anticipated and prevented it from happening
      msg = ERR_UNEXPECTED;
      break;
  }

  return msg;
}

export type AccountErrorKeys =
  | keyof Pick<Account, "accountType" | "foreignID">
  | keyof Omit<
      Business,
      "taxIDProvided" | "representatives" | "ownersProvided" | "taxID" | "address"
    >
  | keyof Omit<
      Individual,
      "governmentIDProvided" | "birthDateProvided" | "name" | "governmentID" | "address"
    >
  | keyof Name
  | keyof GovernmentID
  | keyof Address
  | "ein";

const accountKeyMap: Record<AccountErrorKeys, string> = {
  accountType: "Account type",
  email: "Email",
  phone: "Phone",
  website: "Website",
  description: "Description",
  industryCodes: "Industry codes",
  foreignID: "Foreign ID",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  city: "City",
  stateOrProvince: "State or province",
  postalCode: "Postal code",
  country: "Country",
  firstName: "First name",
  middleName: "Middle name",
  lastName: "Last name",
  suffix: "Suffix",
  legalBusinessName: "Business name",
  doingBusinessAs: "Doing business as",
  businessType: "Business type",
  birthDate: "Birth date",
  ssn: "SSN",
  itin: "ITIN",
  ein: "EIN",
  primaryRegulator: "Primary regulator"
};

export const handleAccount422Errors = (error: RequestError): ErrorMessage[] => {
  let formattedErrors: ErrorMessage[] = [];
  if (isLegacyHTTP422Error(error)) {
    formattedErrors = buildUIErrorObjects(error.body, accountKeyMap);
  } else if (isHTTP422Error(error)) {
    formattedErrors = buildUIErrorObjects(error.body.error, accountKeyMap);
  } else if (isLegacyHTTP409Error(error)) {
    formattedErrors.push({ key: "Error", value: error.body.error });
  } else {
    const non422Errors = handleError(error);
    formattedErrors.push({ key: "Error", value: non422Errors });
  }
  return formattedErrors;
};

type RepresentativeErrorKeys =
  | keyof Omit<
      Representative,
      "createdOn" | "updatedOn" | "disabledOn" | "responsibilities" | "representativeID" | "name"
    >
  | keyof Omit<Responsibilities, "isOwner" | "isController">
  | keyof Omit<Name, "middleName" | "suffix">;

const repKeyMap: Record<RepresentativeErrorKeys, string> = {
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  phone: "Phone",
  address: "Address",
  birthDate: "Birth date",
  birthDateProvided: "Birth date",
  governmentID: "Government ID",
  governmentIDProvided: "Government ID",
  jobTitle: "Job title",
  ownershipPercentage: "Ownership percentage"
};

export const handleRepresentative422Errors = (errors: RequestError): ErrorMessage[] => {
  if (isLegacyHTTP422Error(errors)) {
    return buildUIErrorObjects(errors.body, repKeyMap);
  }
  if (isHTTP422Error(errors)) {
    return buildUIErrorObjects(errors.body.error, repKeyMap);
  }
  return [];
};

type UnderwritingErrorKeys =
  | keyof Omit<
      AccountUnderwriting,
      "cardVolumeDistribution" | "fulfillment" | "volumeByCustomerType"
    >
  | keyof CardVolumeDistribution
  | keyof VolumeByCustomerType
  | keyof Fulfillment;

const underwritingKeyMap: Record<UnderwritingErrorKeys, string> = {
  averageMonthlyTransactionVolume: "Average monthly transaction volume",
  averageTransactionSize: "Average transaction size",
  businessToBusinessPercentage: "Business to business percentage",
  cardPresentPercentage: "Card present percentage",
  consumerToBusinessPercentage: "Consumer to business percentage",
  debtRepaymentPercentage: "Debt repayment percentage",
  ecommercePercentage: "E-commerce percentage",
  hasPhysicalGoods: "Has physical goods",
  isShippingProduct: "Is shipping product",
  mailOrPhonePercentage: "Mail or phone percentage",
  maxTransactionSize: "Maximum transaction size",
  returnPolicy: "Return policy",
  shipmentDurationDays: "Shipment duration days",
  status: "Status"
};

export const handleUnderwritingErrors = (error: RequestError): ErrorMessage[] => {
  let formattedErrors: ErrorMessage[] = [];
  if (isLegacyHTTP422Error(error)) {
    formattedErrors = buildUIErrorObjects(error.body, underwritingKeyMap);
  } else if (isHTTP422Error(error)) {
    formattedErrors = buildUIErrorObjects(error.body.error, underwritingKeyMap);
  } else {
    const non422Errors = handleError(error);
    formattedErrors.push({ key: "Error", value: non422Errors });
  }

  return formattedErrors;
};

export const handleFileDownloadErrors = (err: RequestError): string => {
  if (isLegacyHTTP422Error(err) && "FileName" in err.body) {
    return `Filename: ${err.body.FileName}`;
  }
  return handleError(err);
};

export const buildUIErrorObjects = (
  apiErrors: RecursiveErrorObject,
  keyMap: Record<string, string>
) => {
  const errors: ErrorMessage[] = [];
  Object.entries(apiErrors).forEach(([key, value]) => {
    if (typeof value === "string") {
      const title = keyMap[key] ?? "Unknown";
      errors.push({
        key,
        title,
        value: capitalizeString(value)
      });
    } else if (typeof value === "object" && !!value) {
      errors.push(...buildUIErrorObjects(value, keyMap));
    }
  });
  return errors;
};

// Super OLD ERROR HANDLING

export const HTTP_4XX_ERROR = "Something is missing in that request.";
export const HTTP_5XX_ERROR = "Something went wrong on our end.";
export const CONNECTION_ERROR =
  "Something went wrong with the connection. Check your network and try again.";
export const UNEXPECTED_ERROR = "Something unexpected happened. Please contact support.";

export function is5xx(status: number): boolean {
  return status >= 500 && status < 600;
}

export function getDefaultErrorMessage(err?: Error | Response): string {
  if (!err || !("status" in err || "message" in err)) {
    return UNEXPECTED_ERROR;
  }
  if ("status" in err) {
    // It's a Response-like object
    if (is4xx(err.status)) {
      return HTTP_4XX_ERROR;
    }
    if (is5xx(err.status)) {
      return HTTP_5XX_ERROR;
    }
    return "";
  } else {
    // It's an Error-like object
    if (err.name === "NetworkError") {
      return CONNECTION_ERROR;
    } else {
      return UNEXPECTED_ERROR;
    }
  }
}
interface PasswordError {
  password: string;
}
export async function handlePasswordErrors(err: any): Promise<string> {
  let finalMessage = getDefaultErrorMessage(err);

  await err.json().then((stringErr: PasswordError) => {
    //length
    if (stringErr.password.includes("the length must be between 12 and 128")) {
      finalMessage = PASSWORD_LENGTH_ERROR;
    }
    //uppercase
    if (stringErr.password.includes("required to have a uppercase letter")) {
      finalMessage = PASSWORD_UPPERCASE_ERROR;
    }
    //lowercase
    if (stringErr.password.includes("required to have a lowercase letter")) {
      finalMessage = PASSWORD_LOWERCASE_ERROR;
    }
    //symbol
    if (stringErr.password.includes("required to have a symbol")) {
      finalMessage = PASSWORD_SYMBOL_ERROR;
    }
    //number
    if (stringErr.password.includes("required to have a digit")) {
      finalMessage = PASSWORD_NUMBER_ERROR;
    }
  });
  return finalMessage;
}

/**
 * I tried my best to refactor this function to deal with as many cases as possible but we should not
 * be using it anymore. It's a mess and it's not typesafe.
 *
 * We should build a better, more standardized way to handle error messages
 *
 * @deprecated
 */
export async function handlePossible422Errors(
  err: RequestError | { json: () => Promise<HTTP422Error["body"]> } | undefined,
  fallBackMessage: string
): Promise<string> {
  let errors: ErrorMessage[] = [];
  if (!err || isNetworkError(err) || isPostProcessingError(err)) return fallBackMessage;
  // typesafe portion of error handling
  if (isHTTPError(err)) {
    const errorObject = isHTTP422Error(err)
      ? err.body.error
      : isLegacyHTTP422Error(err)
        ? err.body
        : null;
    if (errorObject) {
      errors = getNestedMessages(errorObject);
      const errorMessages = errors.map(
        ({ key, value }: ErrorMessage) =>
          `${camelCaseToSentence(key)}: ${value.charAt(0).toUpperCase() + value.slice(1)}`
      );
      return errorMessages.join("\n") || fallBackMessage;
    } else {
      return fallBackMessage;
    }
  } else {
    // if it's not an HTTPError, we try to async resolve the error body and treat it as an HTTP422Error body
    try {
      await err.json().then((errorObject) => {
        errors = getNestedMessages(errorObject);
      });
      const errorMessages = errors.map(
        ({ key, value }: ErrorMessage) =>
          `${camelCaseToSentence(key)}: ${value.charAt(0).toUpperCase() + value.slice(1)}`
      );
      return errorMessages.join("\n") || fallBackMessage;
    } catch (err) {
      return fallBackMessage;
    }
  }
}

interface ErrorMessage {
  key: string;
  value: string;
  title?: string;
}

export function getNestedMessages(obj: any): ErrorMessage[] {
  const nested: ErrorMessage[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "string") {
      nested.push({ key, value });
    } else if (typeof value === "object") {
      nested.push(...getNestedMessages(value));
    }
  });
  return nested;
}

function camelCaseToSentence(value: string) {
  const res = value.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  return res.charAt(0).toUpperCase() + res.slice(1);
}
