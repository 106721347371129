import React, { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { Avatar } from "@moovfinancial/cargo";
import { getInitials } from "@moovfinancial/common/utils/getInitials";
import { Account, Representative, UserAccount } from "api/Account.model";
import { User } from "api/User.model";
import { APIContext } from "contexts/APIContext";
import { AvatarUrlsContext } from "contexts/AvatarUrlsContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import TestAccountAvatar from "../icons/test_account_avatar.svg?react";

interface AvatarProps {
  size?: number;
  sizeRem?: number;
  id?: string;
  user?: User | null;
  account?: Partial<Account>;
  userAccount?: UserAccount;
  representative?: Representative;
  loading?: boolean;
  className?: string;
  isTestAccount?: boolean;
  style?: React.CSSProperties;
  fallbackChars?: string;
}

const UserAvatar = forwardRef<HTMLDivElement, AvatarProps>(function InnerAvatar(
  {
    size,
    sizeRem,
    user,
    account,
    userAccount,
    representative,
    id,
    loading: eagerLoading,
    className,
    isTestAccount,
    style,
    fallbackChars
  },
  ref
) {
  const { moov } = useContext(APIContext);
  const { avatarUrlCache } = useContext(AvatarUrlsContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const initials = useMemo(() => {
    if (user) {
      return getInitials(user.givenName, user.familyName);
    }
    if (account && account.profile?.individual) {
      return getInitials(
        account.profile.individual.name.firstName,
        account.profile.individual.name.lastName
      );
    }
    if (account && account.profile?.business) {
      return getInitials(account.displayName);
    }
    if (account && account.displayName) {
      return getInitials(account.displayName);
    }
    if (userAccount && userAccount.displayName) {
      return getInitials(userAccount.displayName);
    }
    if (representative) {
      return getInitials(representative.name.firstName, representative.name.lastName);
    }
    if (fallbackChars) {
      return fallbackChars;
    }
    return "A";
  }, [
    user?.givenName,
    user?.familyName,
    account?.profile?.individual,
    account?.profile?.business,
    representative?.name.firstName,
    representative?.name.lastName,
    userAccount?.displayName
  ]);

  const avatarID = useMemo(() => {
    return (
      representative?.representativeID ||
      user?.userID ||
      account?.accountID ||
      userAccount?.accountID ||
      id
    );
  }, [
    id,
    user?.userID,
    account?.accountID,
    representative?.representativeID,
    userAccount?.accountID
  ]);

  useEffect(() => {
    const { get, add } = avatarUrlCache;
    const avatarUrl = get(avatarID);
    // avatarUrl was already fetched, no need to fetch again
    if (avatarUrl) {
      setAvatarUrl(avatarUrl);
    }
    // avatarUrl for avatarID had never been fetched before yet
    else if (avatarUrl === undefined && facilitatorID && avatarID) {
      moov.avatars.getUser(facilitatorID, avatarID).then(([blob, error]) => {
        if (error || !blob) {
          add(avatarID, null);
          return;
        }
        // we add the blob to the cache which, in turn, will create an `ObjectURL` for it
        add(avatarID, blob);
        // we get the `ObjectURL` from the cache as it's now transformed into a string UrlObject
        const avatarUrl = get(avatarID);
        if (avatarUrl) {
          setAvatarUrl(avatarUrl);
        }
      });
    }
  }, [facilitatorID, avatarID]);

  const handleError = () => {
    if (avatarUrl) URL.revokeObjectURL(avatarUrl);
  };

  return (
    <Avatar
      fallbackChars={initials}
      url={avatarUrl}
      hashSeed={avatarID}
      size={sizeRem ? sizeRem : size ? size / 16 : undefined}
      className={className}
      style={style}
      isLazyLoading={!eagerLoading}
      onImageLoadError={handleError}
      content={isTestAccount && <TestAccountAvatar />}
      ref={ref}
    />
  );
});

const areEqual = (prevProps: AvatarProps, nextProps: AvatarProps) =>
  prevProps.size === nextProps.size &&
  prevProps.sizeRem === nextProps.sizeRem &&
  prevProps.id === nextProps.id &&
  prevProps.user?.userID === nextProps.user?.userID &&
  prevProps.account?.accountID === nextProps.account?.accountID &&
  prevProps.userAccount?.accountID === nextProps.userAccount?.accountID &&
  prevProps.representative?.representativeID === nextProps.representative?.representativeID &&
  prevProps.loading === nextProps.loading &&
  prevProps.className === nextProps.className &&
  prevProps.isTestAccount === nextProps.isTestAccount &&
  prevProps.style === nextProps.style;

const MemoUserAvatar = React.memo(UserAvatar, areEqual);

export default MemoUserAvatar;
