import { useCallback, useState } from "react";
import {
  formattedPhoneStringToUnformattedString,
  unformattedStringToFormattedPhoneString
} from "@moovfinancial/common/utils/format/formatPhone";
import { StringMaskingInput, type StringMaskingInputProps } from "./StringMaskingInput";

export interface PhoneNumberInputProps
  extends Omit<
    StringMaskingInputProps,
    "unformattedToFormatted" | "formattedToUnformatted" | "acceptedChars" | "inputFormatter"
  > {}

/**
 * MaskingInput for US 10-digit phone numbers
 *
 * - Formats phone numbers as (123) 456-7890
 * - onValueChange is called with the phone number as a number on the first argument
 * - Validates phone numbers on blur and shows error message if invalid
 * - Re-validates on every keystroke and hides error message if phone number is valid
 *
 */
export const PhoneNumberInput = ({
  onBlur: parentOnBlur,
  onValueChange: parentOnValueChange,
  error: parentErrorMessage,
  ...rest
}: PhoneNumberInputProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const validatePhoneNumber = (value: string, onlyReset?: boolean) => {
    // debugger;
    const cleanNumberString = formattedPhoneStringToUnformattedString(value);
    // if the value is greater than the max, set the value to the max
    if (cleanNumberString.length === 10) {
      setErrorMessage(undefined);
    } else if (!onlyReset) {
      setErrorMessage("Invalid phone number");
    }
  };

  const handleOnValueChange = (value: string, formattedValue: string) => {
    validatePhoneNumber(value, true);
    parentOnValueChange?.(value, formattedValue);
  };

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const input = e.target;
      validatePhoneNumber(input.value);
      parentOnBlur?.(e);
    },
    [parentOnBlur]
  );

  return (
    <StringMaskingInput
      error={parentErrorMessage || errorMessage}
      acceptedChars={/[\d]+/g}
      inputFormatter={unformattedStringToFormattedPhoneString}
      unformattedToFormatted={unformattedStringToFormattedPhoneString}
      formattedToUnformatted={formattedPhoneStringToUnformattedString}
      onBlur={handleBlur}
      onValueChange={handleOnValueChange}
      placeholder="(123) 456-7890"
      {...rest}
    />
  );
};
