import React, { useState } from "react";

interface InputValidation {
  invalidInputs: string[];
  setInvalidInputs: React.Dispatch<React.SetStateAction<string[]>>;
  erroredInputs: string[];
  setErroredInputs: React.Dispatch<React.SetStateAction<string[]>>;
  validate: (input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) => void;
  resetInput: (name: string) => void;
}

export default function useInputValidation(): InputValidation {
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
  const [erroredInputs, setErroredInputs] = useState<string[]>([]);

  const validate = (input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) => {
    const isValid = input.checkValidity();
    if ("pattern" in input && input.value) {
      const matchesPattern = new RegExp(input.pattern).test(input.value);
      if (!matchesPattern) return setInvalidInputs((prev) => [...prev, input.name]);
    }
    isValid ? resetInput(input.name) : setInvalidInputs((prev) => [...prev, input.name]);
  };

  const resetInput = (name: string) => {
    setInvalidInputs((prev) => prev.filter((inputName) => inputName !== name));
    setErroredInputs((prev) => prev.filter((inputName) => inputName !== name));
  };

  return {
    invalidInputs,
    setInvalidInputs,
    erroredInputs,
    setErroredInputs,
    validate,
    resetInput
  };
}
