import pruneObject from "./pruneObject";

/**
 * Recursively travels an original and revised version of an object. Returns any revision data that is different from the original.
 * @param original The original version of the object
 * @param revision The revised version of the object
 * @param exclude A function that returns true if the given property should be excluded from the diff
 * @returns A new object containing the changes from the original object to the revised object
 */
export default function deepDiff(
  original: any = {},
  revision: any = {},
  exclude: (val: any, key: string) => boolean = () => false
) {
  const diff: any = {};

  // Traverse the revised object one key at a time. Add changed values to the diffObj
  // If we encounter an object, we call the function recursively
  const getDiff = (originalObj: any, revisedObj: any, diffObj: any, excludeFunc?: any) => {
    for (const key in revisedObj) {
      // https://eslint.org/docs/latest/rules/no-prototype-builtins#:~:text=to%20avoid%20subtle%20bugs%20like%20this%2C%20it%E2%80%99s%20better%20to%20always%20call%20these%20methods%20from%20object.prototype.%20for%20example%2C%20foo.hasownproperty(%22bar%22)%20should%20be%20replaced%20with%20object.prototype.hasownproperty.call(foo%2C%20%22bar%22).
      if (Object.prototype.hasOwnProperty.call(revisedObj, key)) {
        // Skip over prototype properties
        // https://eslint.org/docs/latest/rules/no-prototype-builtins#:~:text=to%20avoid%20subtle%20bugs%20like%20this%2C%20it%E2%80%99s%20better%20to%20always%20call%20these%20methods%20from%20object.prototype.%20for%20example%2C%20foo.hasownproperty(%22bar%22)%20should%20be%20replaced%20with%20object.prototype.hasownproperty.call(foo%2C%20%22bar%22).
        if (Object.prototype.hasOwnProperty.call(originalObj, key)) {
          if (
            Object.prototype.toString.call(revisedObj[key]) === "[object Object]" &&
            Object.prototype.toString.call(originalObj[key]) === "[object Object]"
          ) {
            diffObj[key] = {};
            getDiff(originalObj[key], revisedObj[key], diffObj[key], excludeFunc);
          } else if (revisedObj[key] !== originalObj[key]) {
            const shouldIgnore = excludeFunc(key, revisedObj[key]);
            if (!shouldIgnore) diffObj[key] = revisedObj[key];
          }
        } else {
          const shouldIgnore = excludeFunc(key, revisedObj[key]);
          if (!shouldIgnore) diffObj[key] = revisedObj[key];
        }
      }
    }
  };

  getDiff(original, revision, diff, exclude);

  // Allow `null` values
  const shouldPrune = (key: string, value: any) => value === undefined || value === "";
  return pruneObject(diff, shouldPrune);
}
