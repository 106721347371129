import { memo, useCallback, useMemo } from "react";
import { formatCurrencyInput } from "@moovfinancial/common/utils/format/formatCurrency";
import { NumberMaskingInput, NumberMaskingInputProps } from "../NumberMaskingInput";

export interface CurrencyAmountInputProps
  extends Omit<
    NumberMaskingInputProps,
    "onBeforeInput" | "inputFormatter" | "documentEventListeners" | "acceptedChars"
  > {}

// @TODO: Replace all instandes of legacy CurrencyDollarsInput component with this one
// https://github.com/moovfinancial/dashboard/blob/main/src/components/form/Form.tsx#L449C5-L449C5

/**
 * HOC that provides acceptedChars, documentEventListeners, inputFormatter, and onBeforeInput to `NumberMaskingInput` for Currency inputs
 */
export const CurrencyAmountInput = memo(function InnerCurrencyAmountInput(
  props: CurrencyAmountInputProps
) {
  const handleBeforeInput = useCallback((e: any) => {
    const input = e.target;
    // When decimal point is pressed for the first time, edit value/selection to prevent cursor jumping
    if (e.data === "." && !input.value.includes(".")) {
      if (input.value === "$0") input.selectionStart = input.selectionEnd = 2;
    }
    // When decimal point is pressed and decimal already exists, move selection to dollars
    if (e.data === "." && input.value.includes(".")) {
      input.selectionStart = input.selectionEnd = input.value.length - 2;
      e.preventDefault();
    }
    // Highlight `0` dollars on first digit keypress
    if (
      e.data !== "." &&
      input.value.split(".")[0] === "$0" &&
      input.selectionStart <= 2 &&
      input.selectionEnd <= 2
    ) {
      input.selectionStart = 1;
      input.selectionEnd = 2;
    }
  }, []);

  const documentEventListeners = useMemo(
    () => ({
      // Force cursor to the right of the dollar sign
      selectionchange: (_e: Event, inputElem: HTMLInputElement) => {
        if (inputElem.selectionStart === 0) inputElem.selectionStart = 1;
      }
    }),
    []
  );

  return (
    <NumberMaskingInput
      {...props}
      documentEventListeners={documentEventListeners}
      inputFormatter={formatCurrencyInput}
      onBeforeInput={handleBeforeInput}
    />
  );
});
