import { useContext } from "react";
import { LegacyCard, Pricing } from "@moovfinancial/cargo";
import { fetchBillingPlans } from "@moovfinancial/common/api/fetchers/fetchBillingPlans";
import { FacilitatorContext } from "contexts/FacilitatorContext";

type PricingSectionProps = {
  planIDs: string[];
};

export const PricingSection = ({ planIDs }: PricingSectionProps) => {
  const { facilitatorID } = useContext(FacilitatorContext);
  const fetchPricing = () =>
    fetchBillingPlans({ accountID: facilitatorID, callerAccountID: facilitatorID, planIDs });

  return (
    <LegacyCard>
      <LegacyCard.Header>
        <LegacyCard.Title>Pricing disclosure</LegacyCard.Title>
      </LegacyCard.Header>
      <LegacyCard.Body>
        <Pricing fetcher={fetchPricing} />
      </LegacyCard.Body>
    </LegacyCard>
  );
};
