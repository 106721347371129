export function loadFromLocalStorage(key: string, defaultValue: any, expectedType: string): any {
  let item = null;
  try {
    item = JSON.parse(localStorage.getItem(key) as string);
  } catch {
    // OK to ignore exceptions here
  }
  if (item === null || typeof item !== expectedType) {
    item = defaultValue;
  }
  return item;
}

export function saveToLocalStorage(key: string, item: any) {
  localStorage.setItem(key, JSON.stringify(item));
}
