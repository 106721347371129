import createClient, { Middleware } from "openapi-fetch";
import type { paths } from "@moovfinancial/common/types/__generated-types__/api";

const API_BASE_PATH = "/api";

export const openApi = createClient<paths>({ baseUrl: API_BASE_PATH });

const removeUndefinedMiddleware: Middleware = {
  async onRequest(req, _options) {
    const queryParams = req.params.query;
    if (queryParams) {
      Object.keys(queryParams).forEach((key) =>
        queryParams[key] === undefined ? delete queryParams[key] : {}
      );
    }
    return req;
  }
};

openApi.use(removeUndefinedMiddleware);
