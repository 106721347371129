import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonLegacy,
  ButtonLegacyProps,
  ButtonStyle,
  CargoElement
} from "@moovfinancial/cargo";
import { useBreakpoint } from "hooks/useBreakpoint";
import ChevronLeft from "components/icons/chevron_left.svg?react";
import { NavContext } from "contexts/NavContext";
import styles from "./Dropdown.module.scss";

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  right?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

interface ToggleProps extends ButtonLegacyProps {
  persist?: boolean;
  icon?: boolean;
  more?: boolean;
  largeIcon?: boolean;
  toggleRef?: React.RefObject<HTMLButtonElement>;
  menuRef?: React.RefObject<any>;
  desktopLayoutOnly?: boolean;
}

interface ToggleContextProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  up?: boolean;
  menuRef?: React.RefObject<HTMLDivElement>;
  showButtons?: boolean;
  secondaryButton?: SecondaryButton;
  title?: string;
  desktopLayoutOnly?: boolean;
}

interface ItemProps extends React.HTMLAttributes<HTMLAnchorElement> {
  destructive?: boolean;
  to?: string;
  as?: string;
  disabled?: boolean;
  badge?: boolean;
}

export interface SecondaryButton {
  label: string;
  onClick: () => void;
  buttonStyle?: ButtonStyle;
}

const ToggleContext = React.createContext<ToggleContextProps>({
  setIsOpen: () => {},
  isOpen: false
});

function Dropdown({ children, right, className, open, setOpen, ...rest }: DropdownProps) {
  const [isOpen, localSetIsOpen] = useState(open || false);

  const setIsOpen = (value: boolean) => {
    localSetIsOpen(value);
    if (setOpen) setOpen(value);
  };

  useEffect(() => {
    localSetIsOpen(open ? true : false);
  }, [open]);

  return (
    <ToggleContext.Provider
      value={{
        isOpen: isOpen,
        setIsOpen: setIsOpen
      }}
    >
      <div
        className={`${styles.root} ${className || ""} ${right ? styles.right : ""}`}
        data-open={isOpen}
        {...rest}
      >
        {children}
      </div>
    </ToggleContext.Provider>
  );
}

function DropdownToggle({ children, persist, toggleRef, menuRef, ...rest }: ToggleProps) {
  const buttonEl = useRef<HTMLButtonElement>(null);
  const { isOpen, setIsOpen } = useContext(ToggleContext);
  const [buttonRef, setRef] = useState(buttonEl);
  const { setShouldRenderNavMenu } = useContext(NavContext);
  const isMobile = useBreakpoint("mobile");
  const openRef = useRef({});
  openRef.current = isOpen;

  const escHandler = (e: any) => {
    if (e.keyCode === 27) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (toggleRef) setRef(toggleRef);
    document.addEventListener("keydown", escHandler, false);
    return () => {
      document.removeEventListener("keydown", escHandler, false);
    };
  }, []);

  const toggle = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!openRef.current);
    },

    []
  );

  const close = useCallback(
    (event: any) => {
      if (buttonRef.current?.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (openRef.current && (!persist || !menuRef?.current?.contains(event.target))) {
        setIsOpen(false);
      }
    },

    []
  );

  useEffect(() => {
    if (isMobile && isOpen) {
      setShouldRenderNavMenu(false);
    } else {
      setShouldRenderNavMenu(true);
    }
    if (isOpen) {
      document.addEventListener("click", close, true);
    }
    return () => {
      document.removeEventListener("click", close, true);
    };
  }, [isOpen]);

  const restCopy = { ...rest };
  delete restCopy.desktopLayoutOnly;

  return (
    <ButtonLegacy
      ref={buttonRef}
      onClick={toggle}
      aria-haspopup="true"
      aria-expanded={isOpen}
      role="button"
      {...restCopy}
    >
      {children}
    </ButtonLegacy>
  );
}

function DropdownMenu({
  children,
  role,
  up,
  className,
  menuRef,
  showButtons = true,
  secondaryButton,
  title,
  desktopLayoutOnly,
  ...rest
}: MenuProps) {
  const { setIsOpen } = useContext(ToggleContext);

  const setIsOpenHandler = (open: boolean) => {
    setIsOpen(open);
  };

  const classNames = useMemo(() => {
    const result = [styles.menu];
    if (className) result.push(className);
    if (up) result.push(styles.up);
    if (desktopLayoutOnly) result.push(styles.desktopLayoutOnly);
    return result.join(" ");
  }, [up, className, desktopLayoutOnly]);

  return (
    <div className={classNames} role={role || "menu"} ref={menuRef} {...rest}>
      <div className={styles.mobileHeader}>
        <ChevronLeft
          onClick={() => {
            setIsOpenHandler(false);
          }}
        />
        {title}
      </div>
      {children}
      {showButtons && (
        <div className={styles.mobileButtons}>
          {secondaryButton && (
            <ButtonLegacy
              buttonStyle={secondaryButton.buttonStyle || "link"}
              onClick={(e) => {
                e.preventDefault();
                if (secondaryButton.onClick) secondaryButton.onClick();
                setIsOpenHandler(false);
              }}
            >
              {secondaryButton.label}
            </ButtonLegacy>
          )}
          <Button
            buttonType="primary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpenHandler(false);
            }}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
}

function DropdownItem({
  children,
  destructive,
  to,
  as,
  badge,
  className,
  onClick,
  ...rest
}: ItemProps) {
  const classNames = useMemo(() => {
    const list = [styles.item];

    if (className) list.push(className);
    if (badge) list.push(styles.badge);
    if (destructive) list.push(styles.destructive);

    return list.join(" ");
  }, [className, badge, destructive]);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    if (onClick) onClick(e);
  };

  if (to) {
    return (
      <Link to={to} className={classNames} onClick={handleClick} {...rest}>
        {children}
      </Link>
    );
  } else {
    return (
      <CargoElement
        renderAs={as || "button"}
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
        className={classNames}
        {...rest}
      >
        {children}
      </CargoElement>
    );
  }
}

function DropdownDivider() {
  return <div className={styles.divider}></div>;
}

Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
Dropdown.Divider = DropdownDivider;
export default Dropdown;
