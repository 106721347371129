import clsx from "clsx";
import React, { ForwardedRef, useMemo } from "react";
import { Loading } from "../../Foundations";
import { IconChevronDown, IconMoreHorizontal } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./ButtonLegacy.module.scss";

export type ButtonStyle =
  | "accountSwitcher"
  | "icon"
  | "link"
  | "linkBare"
  | "linkBlueBare"
  | "linkHeader"
  | "linkSubdued"
  | "primary"
  | "secondary"
  | "secondaryDark"
  | "tertiary"
  | "textDestructive"
  | "userDropdown"
  | "vertical"
  | "destructive";

/** @deprecated: Use the new Button component if at all possible */
export interface ButtonLegacyProps
  extends React.HTMLAttributes<HTMLButtonElement & HTMLDivElement> {
  buttonStyle?: ButtonStyle;
  block?: boolean;
  icon?: boolean;
  largeIcon?: boolean;
  smallIcon?: boolean;
  submit?: boolean;
  loading?: boolean;
  menu?: boolean;
  more?: boolean;
  buttonSize?: keyof typeof styles;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  alignLeft?: boolean;
  pullLeft?: boolean;
  pullRight?: boolean;
  spreadContent?: boolean;
  innerRef?: ForwardedRef<any>;
  fullWidthOnMobile?: boolean;
}

const ButtonLegacyComponent = React.forwardRef<HTMLButtonElement, ButtonLegacyProps>(
  function ButtonComponent(props, ref) {
    const {
      children,
      className,
      buttonStyle,
      buttonSize,
      block,
      fullWidthOnMobile = false,
      icon,
      largeIcon,
      smallIcon,
      submit,
      loading,
      disabled,
      onClick,
      menu,
      more,
      type,
      alignLeft,
      pullLeft,
      pullRight,
      spreadContent,
      innerRef,
      ...rest
    } = props;

    const classNames = useMemo(() => {
      const names = [];
      if (buttonStyle) names.push(styles[buttonStyle]);
      if (className) names.push(className);
      if (buttonSize) {
        names.push(styles[buttonSize]);
      }
      if (block) names.push(styles.block);
      if (icon) names.push(styles.icon);
      if (largeIcon) names.push(styles.largeIcon);
      if (smallIcon) names.push(styles.smallIcon);
      if (more) names.push(styles.more);
      if (loading) names.push(styles.loading);
      if (pullLeft) names.push(styles.pullLeft);
      if (pullRight) names.push(styles.pullRight);
      if (fullWidthOnMobile) names.push(styles.fullWidthOnMobile);
      return names.join(" ");
    }, [
      buttonStyle,
      buttonSize,
      block,
      icon,
      largeIcon,
      smallIcon,
      more,
      loading,
      pullLeft,
      pullRight,
      className
    ]);

    return (
      <button
        data-moov="button-legacy"
        ref={innerRef || ref}
        tabIndex={disabled ? -1 : 0}
        {...(rest as React.HTMLAttributes<HTMLButtonElement>)}
        onClick={
          !disabled
            ? (onClick as unknown as (
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => void)
            : undefined
        }
        disabled={disabled}
        className={clsx(styles.LegacyButton, classNames)}
        type={submit ? "submit" : type ? type : undefined}
      >
        <span
          className={clsx(
            styles.inner,
            alignLeft && styles.alignLeft,
            spreadContent && styles.spreadContent
          )}
        >
          {children}
          {menu && (
            <span className={styles.menuIcon}>
              <Icon iconComponent={IconChevronDown} />
            </span>
          )}
          {more && <Icon iconComponent={IconMoreHorizontal} className={styles.moreIcon} />}
        </span>
        {loading && (
          <div className={styles.loadingElement}>
            <Loading />
          </div>
        )}
      </button>
    );
  }
);

const MemoButton = React.memo(ButtonLegacyComponent);
MemoButton.displayName = "Button";

/** @deprecated: Use the new Button component if at all possible */
export const ButtonLegacy = MemoButton;
