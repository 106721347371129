import { useEffect, useState } from "react";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { openApi } from "api/OpenApiClient";

type Bank = components["schemas"]["AchParticipant"];

export function RoutingNumberInputHelper({ routingNumber }: { routingNumber: string }) {
  const [bank, setBank] = useState<Bank>();

  const fetchRoutingNumber = async () => {
    const { data } = await openApi.GET("/institutions/{rail}/search", {
      params: {
        path: { rail: "ach" },
        query: { routingNumber: routingNumber }
      }
    });
    if (data && data.achParticipants && data.achParticipants.length > 0) {
      setBank(data.achParticipants[0]);
    }
  };

  useEffect(() => {
    setBank(undefined);
    // if the routingNumber is anything other than 9 digits it's invalid
    if (routingNumber?.length === 9) {
      fetchRoutingNumber();
    }
  }, [routingNumber]);

  if (bank) {
    return <div>{bank.customerName}</div>;
  }
}
