import { Helmet, HelmetProvider } from "react-helmet-async";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import GoogleRecaptcha from "@moovfinancial/common/utils/GoogleRecaptcha";
import useServiceWorker from "hooks/useServiceWorker";
import { LiveMoovAPIClient, MoovAPIClient } from "api/v2";
import APIContextProvider from "contexts/APIContext";
import UserContextProvider from "contexts/UserContext";
import WindowResizeContextProvider from "contexts/WindowResizeContext";
import initFeatureFlagHelper from "helpers/featureFlags";
import "react-toastify/dist/ReactToastify.minimal.css";
import { AppRoutes } from "./Routes/AppRoutes";
import { ErrorPage } from "./Routes/ErrorPage";

initFeatureFlagHelper();
const env = initEnvironment();
const moov = initAPIClient(env);

const ToastTransition = cssTransition({
  enter: "toast-slideUp",
  exit: "toast-slideDown",
  collapseDuration: 500,
  appendPosition: false,
  collapse: false
});

GoogleRecaptcha.init({
  environment: env
});

// eslint-disable-next-line no-console
console.log("Moov Dashboard v", __APP_VERSION__);

const router = createBrowserRouter([
  { path: "/*", element: <Root />, errorElement: <ErrorPage /> }
]);

function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  useServiceWorker();

  return (
    <div className="App">
      <APIContextProvider moov={moov}>
        <UserContextProvider>
          <WindowResizeContextProvider>
            <HelmetProvider>
              <Helmet titleTemplate="%s | Moov Dashboard" defaultTitle="Moov Dashboard" />
              <AppRoutes />
              <ToastContainer
                className="toast-container"
                toastClassName="toast"
                bodyClassName="toast-body"
                position="bottom-center"
                pauseOnFocusLoss={false}
                autoClose={4000}
                limit={3}
                closeButton={false}
                transition={ToastTransition}
              />
            </HelmetProvider>
          </WindowResizeContextProvider>
        </UserContextProvider>
      </APIContextProvider>
    </div>
  );
}

function initEnvironment(): "development" | "staging" | "production" {
  return window.location.hostname === "localhost" || window.location.hostname === "local.moov.io"
    ? "development"
    : window.location.hostname === "dashboard.moov.io"
      ? "production"
      : "staging";
}

function initAPIClient(env: string): MoovAPIClient {
  const baseURL =
    env === "production"
      ? "https://dashboard.moov.io/api"
      : env === "staging"
        ? "https://dashboard.moov-staging.io/api"
        : "/api";
  const moov = new LiveMoovAPIClient({ baseURL });
  (window as any).moovAPI = moov;

  return moov;
}

export default App;
