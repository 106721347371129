import styles from "./PageFooter.module.scss";

export const PageFooter = () => {
  return (
    <footer className={styles.footer}>
      <div>
        Powered by{" "}
        <a href="https://moov.io" rel="noreferrer" target="_blank">
          Moov
        </a>
      </div>
      <a href="https://moov.io/legal/platform-agreement/">Terms</a>
      <a href="https://moov.io/legal/privacy-policy/">Privacy</a>
    </footer>
  );
};
