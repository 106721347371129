import { LiveMoovAPIClient } from "./api";
import { Query, Reproduce } from "./lookback.model";
import { RequestError } from "./request";

export interface LookbackAPI {
  /** Create lookback query. */
  post(query: Query): Promise<[any | undefined, RequestError | undefined]>;

  replay(reproduce: Reproduce): Promise<[any | undefined, RequestError | undefined]>;
}

export class LiveLookbackAPI implements LookbackAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async post(query: Query): Promise<[any | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>("/ops/lookbacks", {
      method: "POST",
      json: query
    });
    return [result, err];
  }

  async replay(reproduce: Reproduce): Promise<[any | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>("/ops/reproduces", {
      method: "POST",
      json: reproduce
    });
    return [result, err];
  }
}
