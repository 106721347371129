import clsx from "clsx";
import { ReactElement, useMemo, useState } from "react";
import SvgrReactComponent from "vite-plugin-svgr/*.svg?react";
import { BaseButton } from "../../Actions/BaseButton";
import {
  IconBlocked,
  IconCheckmarkCircleOutlined,
  IconClear,
  IconErrorOutlined,
  IconInfoOutlined,
  IconLightbulb
} from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./Alert.module.scss";

type AlertType = "info" | "warning" | "error" | "success" | "special";

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Optional React element rendered within the banner. Takes precedence over allowClear if both are provided.
   */
  actionElement?: ReactElement;
  /**
   * The type of alert, which determines color and default icon display.
   */
  type: AlertType;
  /**
   * The text to be shown in the alert. If passed along with children, it is ignored.
   */
  label?: string;
  /**
   * The (optional) icon component that can override the default icon for each alert type.
   */
  iconComponent?: typeof SvgrReactComponent;
  /**
   * Whether or not the icon and text should be centered in the alert.
   *
   * Defaults to `false`.
   */
  centered?: boolean;
  /**
   * Whether or not the alert can be "cleared" (hidden) by the user.
   *
   * Defaults to `false`.
   */
  allowClear?: boolean;
  /**
   * Whether or not the alert should be "polite". Adds `aria-live="polite"` and uses a `complementary` role.
   *
   * Use this for updates that are important for the user to receive, but should not be so rapid as to be annoying.
   * The screen reader will speak changes whenever the user is idle.
   *
   * Defaults to `false`.
   */
  polite?: boolean;
  /**
   * Whether or not the alert should be "quiet". Uses a `complementary` role instead of `alert`.
   *
   * Use this for updates that _**do not**_ require the user's immediate attention.
   *
   * This allows us to use this component for more persistent, non-interactive messaging like bank holiday alerts.
   *
   * If `polite` is `true`, this prop is overridden and the `complementary` role is used.
   *
   * Defaults to `false`.
   */
  quiet?: boolean;
}

/**
 * Alert is a component that displays a brief, important message in a way that attracts the user's attention without interrupting their task.
 */
export const Alert = ({
  actionElement,
  allowClear = false,
  centered = false,
  children,
  className,
  iconComponent: IconComponent,
  label,
  polite = false,
  quiet = false,
  type
}: AlertProps) => {
  const [cleared, setCleared] = useState(false);
  const complementary = polite ? true : quiet;

  const icon = useMemo(() => {
    if (IconComponent) return IconComponent;

    switch (type) {
      case "info":
        return IconInfoOutlined;
      case "warning":
        return IconErrorOutlined;
      case "error":
        return IconBlocked;
      case "success":
        return IconCheckmarkCircleOutlined;
      case "special":
        return IconLightbulb;
    }
  }, [type]);

  if (cleared) {
    return null;
  }

  return (
    <div
      aria-live={polite ? "polite" : undefined}
      className={clsx(styles.alert, styles[type], centered && styles.centered, className)}
      data-testid="alert"
      role={complementary ? "complementary" : "alert"}
    >
      <Icon className={styles.alertIcon} iconComponent={icon} size="XS" />
      <div className={styles.alertContent}>
        <div>{children || label}</div>
        {actionElement ? (
          <div className={styles.alertAction}>{actionElement}</div>
        ) : (
          allowClear && (
            <BaseButton
              aria-label="Close alert"
              centered
              onClick={() => setCleared(true)}
              role="button"
            >
              <Icon iconComponent={IconClear} size="XS" />
            </BaseButton>
          )
        )}
      </div>
    </div>
  );
};
