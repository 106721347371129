import {
  AdminAccountStatuses,
  ManualRepresentativeReview,
  ManualRepresentativeReviewFilter
} from "./admin.model";
import { LiveMoovAPIClient } from "./api";
import { PaginationCursor } from "./common.model";
import { RequestError } from "./request";

export interface AdminAPI {
  /** Get underwriting record. */
  get(accountID: string): Promise<[AdminAccountStatuses | undefined, RequestError | undefined]>;

  listManualRepresentativeReviews(
    filter?: ManualRepresentativeReviewFilter,
    cursor?: PaginationCursor
  ): Promise<[ManualRepresentativeReview[] | undefined, RequestError | undefined]>;
}

export class LiveAdminAPI implements AdminAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(
    accountID: string
  ): Promise<[AdminAccountStatuses | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/dashboard/admin/accounts/${accountID}/statuses`
    );
    return [result, err];
  }

  async listManualRepresentativeReviews(
    filter?: ManualRepresentativeReviewFilter,
    cursor?: PaginationCursor
  ): Promise<[ManualRepresentativeReview[] | undefined, RequestError | undefined]> {
    let query;
    if (filter || cursor) {
      query = { ...filter, ...cursor };
    }
    const [result, err] = await this._client.request<any>(
      "/dashboard/admin/accounts/representatives/manual-verifications",
      {
        query
      }
    );
    return [result, err];
  }
}
