import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { BaseButton } from "@moovfinancial/cargo";
import BlockedIcon from "../icons/blocked.svg?react";
import CheckmarkIcon from "../icons/checkmark_circle_outlined.svg?react";
import ClearIcon from "../icons/clear.svg?react";
import ExclamationIcon from "../icons/error_outlined.svg?react";
import InfoIcon from "../icons/info.svg?react";
import LightBulbIcon from "../icons/lightbulb.svg?react";
import BankIcon from "../icons/museum_no_outline.svg?react";
import styles from "./Alert.module.scss";

export type LegacyAlertType = "info" | "warning" | "error" | "success" | "special" | "holiday";

export interface LegacyAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  type: LegacyAlertType;
  centered?: boolean;
  showClear?: boolean;
}

export default function LegacyAlert({
  type,
  className,
  children,
  centered = false,
  showClear = false
}: LegacyAlertProps): React.ReactElement {
  const [hidden, setHidden] = useState(false);
  const icon = useMemo(() => {
    switch (type) {
      case "info":
        return <InfoIcon />;
      case "warning":
        return <ExclamationIcon />;
      case "error":
        return <BlockedIcon />;
      case "success":
        return <CheckmarkIcon />;
      case "special":
        return <LightBulbIcon />;
      case "holiday":
        return <BankIcon />;
    }
  }, [type]);
  return (
    <div
      className={clsx(
        {
          [styles.hidden]: hidden,
          [styles.centered]: centered,
          [styles.alertWithClear]: showClear,
          [styles.alert]: !showClear
        },
        styles[type],
        className
      )}
    >
      <div className={styles.icon}>{icon}</div>
      <div>{children}</div>
      {showClear && (
        <BaseButton onClick={() => setHidden(true)} className={styles.clear}>
          <ClearIcon />
        </BaseButton>
      )}
    </div>
  );
}
