import { LiveMoovAPIClient } from "api/v2";
import { RequestError } from "api/v2/request";
import {
  CAUEnrollment,
  Card,
  DiscoverClosureCode,
  DiscoverPatch,
  MerchantStatuses,
  NewCAUEnrollment,
  NewCAUEnrollmentResponse,
  NewCard,
  UpdateCAUEnrollment,
  UpdateCard
} from "./cards.model";

export interface CardsAPI {
  /** List all the cards associated with a Moov account. */
  list(
    facilitatorID: string,
    accountID: string
  ): Promise<[Card[] | undefined, RequestError | undefined]>;
  /** Fetch a specific card associated with a Moov account. */
  get(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<[Card | undefined, RequestError | undefined]>;
  /** Link a card to an existing Moov account. */
  create(
    facilitatorID: string,
    accountID: string,
    card: NewCard
  ): Promise<[Card | undefined, RequestError | undefined]>;
  /** Disables a card associated with a Moov account. */
  disable(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<RequestError | undefined>;
  /** Updates card data associated with a Moov account. */
  update(
    facilitatorID: string,
    accountID: string,
    cardID: string,
    card: UpdateCard
  ): Promise<[Card | undefined, RequestError | undefined]>;
  /** Checks if the given account is enrolled in the Card Account Updater program */
  adminGetCAUEnrollment(
    accountID: string
  ): Promise<[CAUEnrollment | undefined, RequestError | undefined]>;
  /** Enrolls the given account in the Card Account Updater program */
  adminCreateCAUEnrollment(
    cauEnrollment: NewCAUEnrollment
  ): Promise<[CAUEnrollment | undefined, RequestError | undefined]>;
  /** Updates the accounts enrollment in the Card Account Updater program */
  adminUpdateCAUEnrollment(
    cauEnrollment: UpdateCAUEnrollment
  ): Promise<[CAUEnrollment | undefined, RequestError | undefined]>;
  /** Used to update the discover closure reason */
  adminUpdateDiscoverClosureReason(
    accountID: string,
    statusCode: DiscoverClosureCode
  ): Promise<[DiscoverPatch | undefined, RequestError | undefined]>;
  /** Get merchant statuses for disco and amex */
  getMerchantStatuses(
    accountID: string
  ): Promise<[MerchantStatuses | undefined, RequestError | undefined]>;
}

export class LiveCardsAPI implements CardsAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    accountID: string
  ): Promise<[Card[] | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Card[]>(`/accounts/${accountID}/cards`, {
      xAccountID: facilitatorID
    });

    // Repair missing card types
    if (Array.isArray(result)) {
      for (const card of result) {
        if (!card.cardType || card.cardType === "unknown") {
          card.cardType = "credit";
        }
      }
    }

    return [result, err];
  }

  async get(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<[Card | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Card>(
      `/accounts/${accountID}/cards/${cardID}`,
      {
        xAccountID: facilitatorID
      }
    );

    // Repair missing card type
    if (result && (!result.cardType || result.cardType === "unknown")) {
      result.cardType = "credit";
    }

    return [result, err];
  }

  async create(
    facilitatorID: string,
    accountID: string,
    card: NewCard
  ): Promise<[Card | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Card>(`/accounts/${accountID}/cards`, {
      method: "POST",
      json: card,
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async disable(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<RequestError | undefined> {
    const [_, err] = await this._client.request<void>(`/accounts/${accountID}/cards/${cardID}`, {
      method: "DELETE",
      xAccountID: facilitatorID
    });
    return err;
  }

  async update(
    facilitatorID: string,
    accountID: string,
    cardID: string,
    card: UpdateCard
  ): Promise<[Card | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<Card>(
      `/accounts/${accountID}/cards/${cardID}`,
      {
        method: "PATCH",
        json: card,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminGetCAUEnrollment(
    accountID: string
  ): Promise<[CAUEnrollment | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<CAUEnrollment>(
      `/admin/cau/enrollments/${accountID}`
    );
    return [result, err];
  }

  async adminCreateCAUEnrollment(
    cauEnrollment: NewCAUEnrollment
  ): Promise<[CAUEnrollment | undefined, RequestError | undefined]> {
    const [newCAUEnrollmentResponse, responseErr] =
      await this._client.request<NewCAUEnrollmentResponse>("/admin/cau/enrollments", {
        method: "POST",
        json: [cauEnrollment]
      });
    if (responseErr) return [undefined, responseErr];
    const result = newCAUEnrollmentResponse?.successes[0];
    const err = newCAUEnrollmentResponse?.errors
      ? ({
          type: "post",
          error: new Error(newCAUEnrollmentResponse?.errors[0]?.message)
        } as unknown as RequestError)
      : undefined;
    return [result, err];
  }

  async adminUpdateCAUEnrollment(
    cauEnrollment: UpdateCAUEnrollment
  ): Promise<[CAUEnrollment | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<CAUEnrollment>(
      `/admin/cau/enrollments/${cauEnrollment.accountID}`,
      {
        method: "PATCH",
        json: cauEnrollment
      }
    );
    return [result, err];
  }

  async adminUpdateDiscoverClosureReason(
    accountID: string,
    statusCode: DiscoverClosureCode
  ): Promise<[DiscoverPatch | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<DiscoverPatch>(
      `/admin/registration/${accountID}`,
      {
        method: "PATCH",
        json: { statusCode: statusCode }
      }
    );
    return [result, err];
  }

  async getMerchantStatuses(
    accountID: string
  ): Promise<[MerchantStatuses | undefined, RequestError | undefined]> {
    const [result, err] = await this._client.request<MerchantStatuses>(
      `/admin/registration/${accountID}`
    );
    return [result, err];
  }
}
