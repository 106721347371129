import { ReactNode, forwardRef } from "react";
import { FormGroup } from "../FormGroup";
import { TextInput, TextInputProps } from "./TextInput";

export interface ValidatedInputProps extends TextInputProps {
  /**
   * Error message, which will also set the input to an error state
   */
  error?: ReactNode;
  /**
   * Warning message, which will also set the input to a warning state
   */
  warning?: ReactNode;
  /**
   * Optional helper message to display. It can be text or a ReactNode
   */
  helper?: ReactNode;
  /**
   * should the input have no margins? Ideally, yes, so defaulting to `true`.
   */
  noMargins?: boolean;
}

/**
 * This is Moov's basic input component. It's a wrapper around the native HTML input element and we only add
 * styling and support for error and warning messages through wrapping it in a `FormGroup` component.
 */
export const ValidatedInput = forwardRef<HTMLInputElement, ValidatedInputProps>(
  function ValidatedInput(
    {
      error,
      helper,
      isErroring,
      isWarning,
      label,
      noMargins = true,
      warning,
      ...rest
    }: ValidatedInputProps,
    ref
  ) {
    return (
      <FormGroup
        errorMessage={error}
        warningMessage={warning}
        helper={helper}
        noMargins={noMargins}
        noGap
      >
        <TextInput
          isErroring={!!error || isErroring}
          isWarning={!!warning || isWarning}
          label={label}
          ref={ref}
          {...rest}
        />
      </FormGroup>
    );
  }
);
