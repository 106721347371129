import { Enum } from "@moovfinancial/common/types/Enum";
import { BusinessTypeMap } from "@moovfinancial/common/types/models/BusinessType";
import { CapabilityName, CapabilityStatus } from "./v2";

export interface AccountSummary {
  accountId: string;
  displayName: string;
  email: string;
  createdOn: string;
  type: string;
  enabled: boolean;
}

export type AccountType = "individual" | "business";

export type AccountMode = "production" | "sandbox";

export const businessTypeMap = BusinessTypeMap;

export type BusinessType = Enum<typeof businessTypeMap>;

export interface FacilitatorAccount {
  accountID: string;
  accountType: AccountType;
  connectedTo: string;
  connectionID: string;
  createdOn: string;
  disabledOn: string | null;
  displayName: string;
  email: string;
  enabled: boolean;
  updatedOn: string;
}

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  country?: string;
}

export interface AddressSuggestion extends Address {
  entries?: number;
}

export interface Phone {
  number?: string;
  countryCode?: string;
}

export interface Name {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
}

export interface DateObject {
  day: number | undefined;
  month: number | undefined;
  year: number | undefined;
}

export interface GovernmentID {
  ssn?: {
    full?: string;
    lastFour?: string;
  } | null;
  itin?: {
    full?: string;
    lastFour?: string;
  } | null;
}

export interface Responsibilities {
  isController?: boolean;
  isOwner?: boolean;
  ownershipPercentage?: number;
  jobTitle?: string;
}

export interface Individual {
  name: Name;
  phone?: Phone;
  email: string;
  address?: Address;
  birthDateProvided?: boolean;
  governmentIDProvided?: boolean;
  birthDate?: DateObject;
  governmentID?: GovernmentID;
}

export interface PatchIndividual
  extends Omit<Partial<Individual>, "birthDateProvided" | "governmentIDProvided"> {}

export interface MetaData {
  [key: string]: string;
}

export interface Representative {
  representativeID: string;
  name: Name;
  phone?: Phone;
  email?: string;
  address?: Address;
  birthDateProvided: boolean;
  birthDate?: DateObject;
  governmentID?: GovernmentID;
  governmentIDProvided: boolean;
  responsibilities?: Responsibilities;
  createdOn: string;
  updatedOn: string;
  disabledOn?: string;
}

export interface Business {
  legalBusinessName?: string;
  doingBusinessAs?: string;
  businessType: BusinessType;
  address: Address;
  phone: Phone;
  email: string;
  website?: string;
  description?: string;
  taxIDProvided?: boolean;
  representatives?: Representative[];
  ownersProvided?: boolean;
  industryCodes: {
    naics: string;
    sic: string;
    mcc: string;
  };
  taxID?: TaxID;
}

export interface TaxID {
  ein: {
    number: string;
  };
}

export interface PatchBusiness
  extends Omit<Partial<Business>, "representatives" | "taxIDProvided"> {}

export interface PartialBusinessAccount {
  legalBusinessName?: string;
  businessType: BusinessType | "";
  website?: string;
  description?: string;
  mode: AccountMode;
}

export interface CustomerSupport {
  email?: string;
  phone?: Phone | null;
  website?: string;
  address?: Address;
}

export interface TermsOfService {
  acceptedDate: string;
  acceptedIP: string;
}

export interface PatchTermsOfService {
  token: string;
}

export interface AccountSettings {
  cardPayment?: {
    statementDescriptor: string;
  };
  achPayment?: {
    companyName: string;
  };
}

export interface Account {
  accountID: string;
  accountType: AccountType;
  displayName: string;
  customerSupport?: CustomerSupport;
  profile: {
    individual?: Individual;
    business?: Business;
  };
  metadata?: MetaData;
  termsOfService?: TermsOfService;
  verification?: {
    verificationStatus: SimpleAccountVerificationStatus;
  };
  capabilities?: SimpleCapability[];
  settings?: AccountSettings;
  mode?: AccountMode;
  foreignID?: string;
  createdOn: string;
  updatedOn: string;
  disconnectedOn?: string;
}

export interface CreateAccount
  extends Omit<Account, "termsOfService" | "accountID" | "capabilities"> {
  termsOfService: PatchTermsOfService;
}

export interface PatchAccount
  extends Pick<
    Partial<Account>,
    "accountID" | "metadata" | "foreignID" | "customerSupport" | "settings"
  > {
  profile?: {
    individual?: PatchIndividual;
    business?: PatchBusiness;
  };
  termsOfService?: PatchTermsOfService;
}

export interface UserAccount {
  accountID: string;
  displayName: string;
  accountMode?: AccountMode;
  superaccess?: boolean;
}

export type AccountStatusType = "enabled" | "disabled";

export interface AccountListFilter {
  skip?: number;
  count?: number;
  name?: string;
  email?: string;
  type?: "individual" | "business";
  foreignID?: string;
  verification_status?: SimpleAccountVerificationStatus;
  mode?: "production" | "sandbox";
}

export const defaultAccountListFilter = {
  skip: 0,
  count: 20
};

export interface SimpleCapability {
  capability: CapabilityName;
  status: CapabilityStatus;
}

export type SimpleAccountVerificationStatus =
  | "unverified"
  | "pending"
  | "resubmit"
  | "review"
  | "verified"
  | "failed";

export type SimpleMatchResults = "hits-found" | "no-hits";

export type VerificationProcessStatus =
  | "approved"
  | "pending"
  | "pending-watchlist"
  | "pending-kyc"
  | "errored"
  | "rejected"
  | "needsReview";

export type RequirementError = {
  requirement: string;
  errorCode: string;
};

type VerificationRepresentative = {
  sourceID: string;
  status: VerificationProcessStatus;
  requirementErrors: RequirementError[];
  createdOn: string;
  updatedOn: string;
  disabledOn?: string;
  vendorRequests: VendorRequests[];
};

type VendorRequests = {
  vendor: string;
  referenceID: string;
  sourceID: string;
  createdOn: string;
};

export interface Verification {
  verificationID: string;
  accountID: string;
  accountType: "individual" | "business";
  status: VerificationProcessStatus;
  statusReason?: string;
  individual?: {
    sourceID: string;
    status: VerificationProcessStatus;
    requirementErrors: RequirementError[];
    createdOn: string;
    updatedOn: string;
    disabledOn?: string;
    vendorRequests: VendorRequests[];
  };
  business?: {
    status: VerificationProcessStatus;
    representatives: VerificationRepresentative[];
    requirementErrors: RequirementError[];
    createdOn: string;
    updatedOn: string;
    disabledOn?: string;
    vendorRequests: VendorRequests[];
  };
  createdOn: string;
  updatedOn: string;
  disabledOn?: string;
}

export type VerificationFilters = {
  status?: VerificationProcessStatus;
  startUpdatedOn?: string;
  endUpdatedOn?: string;
  startCreatedOn?: string;
  endCreatedOn?: string;
};

export type PatchVerification = {
  status: VerificationProcessStatus;
  statusReason?: string;
  userID: string;
  userName: string;
};

/** Mimics the structure of the Representative object, but every field is an optional boolean */
export type BooleanRepresentativeMap = {
  name?: {
    firstName?: boolean;
    lastName?: boolean;
  };
  phone?: {
    number?: boolean;
  };
  email?: boolean;
  address?: {
    addressLine1?: boolean;
    addressLine2?: boolean;
    city?: boolean;
    stateOrProvince?: boolean;
    postalCode?: boolean;
    country?: boolean;
  };
  birthDate?: {
    day?: boolean;
    month?: boolean;
    year?: boolean;
  };
  governmentID?: {
    ssn?: {
      lastFour?: boolean;
      full?: boolean;
    };
    itin?: {
      lastFour?: boolean;
      full?: boolean;
    };
  };
  responsibilities?: {
    isController?: boolean;
    isOwner?: boolean;
    ownershipPercentage?: boolean;
    jobTitle?: boolean;
  };
};

/** Mimics the structure of the Account object, but every field is an optional boolean */
export type BooleanAccountMap = {
  accountID?: boolean;
  accountType?: boolean;
  displayName?: boolean;
  profile?: {
    individual?: {
      name?: {
        firstName?: boolean;
        middleName?: boolean;
        lastName?: boolean;
        suffix?: boolean;
      };
      phone?: {
        number?: boolean;
      };
      email?: boolean;
      address?: {
        addressLine1?: boolean;
        addressLine2?: boolean;
        city?: boolean;
        stateOrProvince?: boolean;
        postalCode?: boolean;
        country?: boolean;
      };
      birthDate?: {
        day?: boolean;
        month?: boolean;
        year?: boolean;
      };
      governmentID?: {
        ssn?: {
          lastFour?: boolean;
          full?: boolean;
        };
        itin?: {
          lastFour?: boolean;
          full?: boolean;
        };
      };
    };
    business?: {
      legalBusinessName?: boolean;
      doingBusinessAs?: boolean;
      website?: boolean;
      description?: boolean;
      businessType?: boolean;
      taxID?: {
        ein?: {
          number?: boolean;
        };
      };
      address?: {
        addressLine1?: boolean;
        addressLine2?: boolean;
        city?: boolean;
        stateOrProvince?: boolean;
        postalCode?: boolean;
        country?: boolean;
      };
      phone?: {
        number?: boolean;
      };
      email?: boolean;
      representatives?: BooleanRepresentativeMap[];
      industryCodes?: boolean;
      primaryRegulator?: boolean;
    };
  };
  metadata?: boolean;
  termsOfService?: {
    acceptedDate?: boolean;
    acceptedIP?: boolean;
  };
  verification?: {
    status?: boolean;
    details?: boolean;
    verificationStatus?: boolean;
  };
  foreignID?: boolean;
};

export type ErrorAccountMap = {
  accountID?: string;
  accountType?: string;
  displayName?: string;
  profile?: {
    individual?: {
      name?: {
        firstName?: string;
        middleName?: string;
        lastName?: string;
        suffix?: string;
      };
      phone?: {
        number?: string;
      };
      email?: string;
      address?: {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        stateOrProvince?: string;
        postalCode?: string;
        country?: string;
      };
      birthDate?: {
        day?: string;
        month?: string;
        year?: string;
      };
      governmentID?: {
        ssn?: {
          lastFour?: string;
          full?: string;
        };
        itin?: {
          lastFour?: string;
          full?: string;
        };
      };
    };
    business?: {
      legalBusinessName?: string;
      doingBusinessAs?: string;
      website?: string;
      description?: string;
      businessType?: string;
      taxID?: {
        ein?: {
          number?: string;
        };
      };
      address?: {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        stateOrProvince?: string;
        postalCode?: string;
        country?: string;
      };
      phone?: {
        number?: string;
      };
      email?: string;
      representatives?: BooleanRepresentativeMap[];
      industryCodes?: string;
    };
  };
  metadata?: string;
  termsOfService?: {
    acceptedDate?: string;
    acceptedIP?: string;
  };
  verification?: {
    status?: string;
    details?: string;
    verificationStatus?: string;
  };
  foreignID?: string;
};

export type FilePurpose =
  | "identity_verification"
  | "business_verification"
  | "merchant_underwriting"
  | "representative_verification"
  | "individual_verification"
  | "account_requirement";

export type MoovDocument = {
  accountID: string;
  createdOn: string;
  fileID: string;
  fileName: string;
  filePurpose: FilePurpose;
  fileSizeBytes: number;
  fileStatus: string;
  updatedOn: string;
};
