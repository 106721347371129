import maybeMockWithPrism from "helpers/mockWithPrism";

const DEFAULT_BASE_URL = "/api";

export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
  toString() {
    return this.message;
  }
}
NetworkError.prototype.name = "NetworkError";

export interface HttpConfig {
  baseUrl?: string;
  fetchFn?: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}

const defaultConfig: HttpConfig = {
  baseUrl: DEFAULT_BASE_URL
};

let activeConfig = defaultConfig;

export function getConfig(): HttpConfig {
  return activeConfig;
}

export function setConfig(config: Partial<HttpConfig>) {
  activeConfig = { ...activeConfig, ...config };
}

export function resetConfig() {
  activeConfig = defaultConfig;
}

export interface HttpOptions {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "HEAD";
  query?: Record<string, string>;
  headers?: Record<string, string>;
  json?: any;
  form?: FormData;
  xAccountID?: string;
  body?: any;
}

export function http(endpoint: string, options: HttpOptions = {}): Promise<any> {
  let url = endpoint;
  const method = options.method || "GET";
  const headers: Record<string, string> = options.headers || {};
  let body: BodyInit | null = options.form || options.body || null;

  if (options.query) {
    const params = new URLSearchParams(options.query);
    url += `?${params.toString()}`;
  }
  if (options.json) {
    body = JSON.stringify(options.json);
    headers["content-type"] = "application/json";
  }
  if (options.xAccountID) {
    headers["x-account-id"] = options.xAccountID;
  }
  if (activeConfig.baseUrl) {
    url = maybeMockWithPrism(activeConfig.baseUrl, url);
  }

  const fetchFn = activeConfig.fetchFn || fetch;
  return new Promise((resolve, reject) => {
    fetchFn(url, { method, headers, body })
      .then((res: Response) => {
        if (!res.ok) {
          reject(res);
        } else {
          const result = unwrapResponse(res);
          resolve(result);
        }
      })
      .catch((err: any) => {
        reject(new NetworkError(err?.message));
      });
  });
}

export function unwrapResponse(res: Response): Promise<any> {
  let result;
  const contentType = res.headers.get("content-type") || "";
  if (contentType.startsWith("application/json")) {
    result = res.json();
  } else if (contentType.startsWith("text/")) {
    result = res.text();
  } else {
    result = res.blob();
  }
  return result;
}
