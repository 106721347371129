/**
 * The request wasn't able to reach the server. This can happen when the app is
 * offline or there are other downstream connection issues.
 */
export type NetworkError = {
  type: "network";
  error: TypeError | DOMException;
};

/**
 * According to some of our existing code before this commit, some errors might have nested
 * error objects in them, so accounting for that here.
 */
export type RecursiveErrorObject = { [key: string]: string | RecursiveErrorObject };

/**
 * The request reached the server, but the server returned a status code outside
 * of the range [200, 400).
 */
export type HTTPError = {
  type: "http";
  resp: Response;
  // old 422 errors where returned directly in the body as a dict and 409 errors as a string
  // TODO: allow for recursive error types
  body?: RecursiveErrorObject | string | { error: RecursiveErrorObject } | { error: string[] };
};

export const isHTTPError = (error?: any): error is HTTPError => {
  return !!error && !!error.type && error.type === "http";
};

/**
 * According to our FE code, we have some errors that are returned
 * as strings wrapped in an `error` object
 *
 * e.g. https://github.com/moovfinancial/frontend/blob/main/dashboard/src/pages/documents/DocumentPreviewModal/DocumentDecision.tsx#L105
 */

export type HTTPErrorWrappedString = HTTPError & {
  body: { error: string };
};

export const isHTTPErrorWrappedString = (error: RequestError): error is HTTPErrorWrappedString => {
  return (
    isHTTPError(error) &&
    typeof error.body === "object" &&
    "error" in error.body &&
    typeof error.body.error === "string"
  );
};

/**
 *  Legacy 422 errors are returned as a string or as a dict
 */
export type LegacyHTTP422Error = HTTPError & {
  body: Record<string, string>;
};

export const isLegacyHTTP422Error = (error: RequestError): error is LegacyHTTP422Error => {
  return isHTTPError(error) && error.resp.status === 422 && typeof error.body === "object";
};

/**
 * Legacy HTTP409 Errors with string
 */
export type LegacyHTTP409Error = HTTPError & {
  body: { error: string };
};

export const isLegacyHTTP409Error = (error: RequestError): error is LegacyHTTP409Error => {
  return (
    isHTTPError(error) &&
    error.resp.status === 409 &&
    typeof error.body === "object" &&
    typeof error.body.error === "string"
  );
};

/**
 *  New 422 errors are wrapped in an `error` object
 */
export type HTTP422Error = HTTPError & {
  body: {
    error: Record<string, string>;
  };
};

export const isHTTP422Error = (error: RequestError): error is HTTP422Error => {
  return (
    isHTTPError(error) &&
    error.resp.status === 422 &&
    typeof error.body === "object" &&
    "error" in error.body &&
    typeof error.body.error === "object"
  );
};

/**
 * The request reached the server, the server returned a successful status code,
 * but an error occurred while parsing the JSON or text response body.
 */
export type PostProcessingError = {
  type: "post";
  error: Error;
  resp: Response;
};

/** One of three different types of errors that can occur when using the Fetch API. */
export type RequestError = NetworkError | HTTPError | PostProcessingError;

/** Determines if and asserts the error is a NetworkError. */
export function isNetworkError(err?: any): err is NetworkError {
  return !!err && err.type === "network";
}

/** Determines if and asserts the error is a PostProcessingError. */
export function isPostProcessingError(err?: any): err is PostProcessingError {
  return !!err && err.type === "post";
}

/** Helper generic to easily create API endpoint return types */
export type APIResponse<T> = Promise<
  | [T | undefined, RequestError | undefined]
  | [T | undefined, RequestError | undefined, Response | undefined]
>;

export interface RequestOptions {
  /** The HTTP method to use. Defaults to "GET". */
  method?: string;
  /** The request headers. */
  headers?: Record<string, string>;
  /** The request query parameters. Passed to URLSearchParams constructor. */
  query?: any;
  /** The request body. If you're sending JSON, use the `json` property instead. */
  body?: any;
  /** The JSON request body. The fetch client will automatically stringify it and set the content-type header. */
  json?: any;
}

/**
 * Sends an asynchoronous request to a URL.
 *
 * @param url - Location to send the request to.
 * @param options - Configuration options for the request.
 * @param [fetchFn] - The fetch function to use. Defaults to the global fetch function.
 */
export async function request<T>(
  url: string,
  options?: RequestOptions,
  fetchFn: (
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ) => Promise<Response> = window.fetch
): Promise<[T | undefined, RequestError | undefined, Response | undefined]> {
  // Configure the request
  const init: RequestInit = {
    method: options?.method || "GET"
  };
  if (options?.headers) {
    init.headers = options.headers;
  }
  if (options?.body) {
    init.body = options.body;
  }
  if (options?.query) {
    // eslint-disable-next-line no-param-reassign
    url += "?" + new URLSearchParams(options.query).toString();
  }
  if (options?.json) {
    init.body = JSON.stringify(options.json);
    if (init.headers === undefined) init.headers = {};
    (init.headers as Record<string, string>)["content-type"] = "application/json";
  }

  // Make the request
  let resp: Response | null = null;
  try {
    resp = await fetchFn(url, init);
  } catch (err: any) {
    // Network error
    return [undefined, { type: "network", error: err }, undefined];
  }

  // Check for a response body
  let body: any | undefined, readErr: Error | undefined;
  if (hasContent(resp)) {
    const contentType = (resp.headers.get("content-type") || "").toLowerCase();

    // Read the body if it's JSON or text
    if (isJSON(contentType)) {
      try {
        body = await resp.json();
      } catch (err: any) {
        readErr = err;
      }
    } else if (
      isImage(contentType) ||
      isPDF(contentType) ||
      (url.endsWith("/download") && isText(contentType))
    ) {
      try {
        body = await resp.blob();
      } catch (err: any) {
        readErr = err;
      }
    } else if (isText(contentType)) {
      try {
        body = await resp.text();
      } catch (err: any) {
        readErr = err;
      }
    }
  }

  // Check for HTTP error
  if (!resp.ok) {
    return [undefined, { type: "http", resp, body }, resp];
  }

  // Check for post-processing error
  if (readErr) {
    return [undefined, { type: "post", resp, error: readErr }, resp];
  }

  // Success!
  return [body, undefined, resp];
}

function isJSON(contentType: string): boolean {
  return contentType.startsWith("application/json");
}

function isText(contentType: string): boolean {
  return contentType.startsWith("text/") || contentType.startsWith("application/xml");
}

function isImage(contentType: string): boolean {
  return contentType.startsWith("image/");
}

function isPDF(contentType: string): boolean {
  return contentType.endsWith("/pdf");
}

function hasContent(resp: Response): boolean {
  return resp.headers.get("content-length") !== "0";
}
