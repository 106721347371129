import clsx from "clsx";
import { memo, useState } from "react";
import { Sizes, StandardSizes } from "@moovfinancial/common/styling/StandardSizes";
import styles from "./Avatar.module.scss";

export type AvatarProps = {
  /**
   * The URL of the image to display in the avatar.
   *
   * If the URL is not provided, or the URL fails to load,
   * the avatar will display the `fallbackText` prop with a background color
   * calculated from the `hashSeed` prop.
   *
   */
  url?: string;
  /**
   * The 2 chars to display in the avatar if the image fails to load.
   * If the string passed is longer than 2 characters, only the first 2 will be displayed.
   *
   * Note: the text should be kept short, around 2 characters ideally
   *
   */
  fallbackChars: string;
  /**
   * The seed for the calculation of the background color of the avatar if the image fails to load,
   * or there's no url provided. If the string passed is less than 36 chars, it will be padded with right-"0"s
   *
   * @defaults to a hash of the 36-char padded version of the `fallbackChars` prop
   *
   */
  hashSeed?: string;
  /**
   * The shape of the avatar. `round` has a border-radius of 50%, `square` has a very small border-radius.
   *
   * @defaults to `circle`.
   *
   */
  shape?: "circle" | "square";
  /**
   * The size of the avatar in either standard "Tshirt size" derived from Cargo's Figma,
   * or a number representing the size in rem
   *
   * @defaults to M (2.5rem)
   *
   */
  size?: Sizes;
  /**
   * Whether the image should be lazy loaded
   *
   * @defaults true
   *
   */
  isLazyLoading?: boolean;
  /**
   * Additional class names to apply to the avatar component
   */
  className?: string;
  /**
   * Optional content to override the contents of the avatar component
   *
   * Passing anything to this prop will override the `url` and `fallbackChars` props
   *
   * @deprecated 🔴 DO NOT USE, this is only for backwards compatibility purposes
   *
   */
  content?: React.ReactNode;
  /**
   * Additional styles to apply to the avatar component
   */
  style?: React.CSSProperties;
  /**
   * Optional ref to pass to the div element
   */
  ref?: React.Ref<HTMLDivElement>;
  /**
   * Optional callback for when the image fails to load
   */
  onImageLoadError?: React.ReactEventHandler<HTMLImageElement>;
};

const padString = (str: string) => {
  return str.padEnd(36, "0");
};

const stringToColor = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    const text = "00" + value.toString(16);
    color += text.substring(text.length - 2);
  }
  return color;
};

const textColor = (background: any) => {
  let color = background;
  color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

  const r = color >> 16;
  const g = (color >> 8) & 255;
  const b = color & 255;

  const luma = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  if (luma < 127.5) {
    return "#fff";
  } else {
    return "rgba(34,34,34,0.9)";
  }
};

// sizes from the figma file
const sizes = new StandardSizes(
  {
    XXS: 0.75,
    XS: 1.5,
    S: 2,
    M: 2.5,
    L: 3,
    XL: 3.75,
    XXL: 4.5
  },
  "S"
);

/**
 * This is the prototypical Avatar component that should be used for all kinds of avatars in the app, including
 * user avatars, bank avatars, etc.
 *
 * It does not implement any kind of caching and leaves that responsibility to the parent component.
 *
 * Figma file: https://www.figma.com/file/bmcmPClkddJOksoPj1mSdQ/Cargo?node-id=792-67647&mode=dev
 *
 */
export const Avatar = memo(function MemoAvatar({
  size,
  shape,
  url,
  fallbackChars,
  hashSeed = fallbackChars,
  isLazyLoading = true,
  className,
  style = {},
  content: deprecatedContent,
  ref,
  onImageLoadError = () => {}
}: AvatarProps) {
  const [errorLoading, setErrorLoading] = useState(false);

  const handleImageLoadError = () => {
    setErrorLoading(true);
  };

  const paddedHashSeed = padString(hashSeed);
  const backgroundColor = stringToColor(paddedHashSeed);
  const fillColor = textColor(backgroundColor);
  const dynamicStyling = { ...style };

  const calcStyle = { ...dynamicStyling, ...sizes.getStyle(size), ...style };

  return (
    <div
      ref={ref}
      className={clsx(styles.Avatar, shape && styles[shape], className)}
      style={calcStyle}
    >
      {deprecatedContent
        ? deprecatedContent
        : (errorLoading || !url) && (
            <svg
              viewBox="0 0 48 48"
              preserveAspectRatio="xMidYMid meet"
              className={styles.initials}
              style={{ backgroundColor }}
            >
              <text
                fontSize={20}
                letterSpacing="-0.04em"
                textAnchor="middle"
                x="50%"
                y="50%"
                dy=".35em"
                fill={fillColor}
              >
                {fallbackChars.trim().slice(0, 2)}
              </text>
            </svg>
          )}
      {url && !errorLoading && (
        <img
          src={url}
          alt=""
          className={styles.image}
          loading={isLazyLoading ? "lazy" : "eager"}
          width="100%"
          height="100%"
          onError={(e) => {
            handleImageLoadError();
            onImageLoadError(e);
          }}
        />
      )}
    </div>
  );
});
