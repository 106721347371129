import { useContext } from "react";
import { AccountTypeSelector } from "./components/AccountTypeSelector";
import { CapabilitiesSelector } from "./components/CapabilitiesSelector";
import LegacyAlert from "components/alert/Alert";
import { Step } from "components/dialog/StepModal";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import styles from "./CreateNewAccount.module.scss";

export const CreateNewAccount = () => {
  const { facilitatorSubmitted, mode, productionAppEnabled } = useContext(FacilitatorContext);

  return (
    <Step>
      {mode === "production" && facilitatorSubmitted && !productionAppEnabled && (
        <LegacyAlert type="info" className={styles.alert}>
          While your account is under review, you won’t be able to verify accounts.
        </LegacyAlert>
      )}
      <AccountTypeSelector />
      <CapabilitiesSelector />
    </Step>
  );
};
