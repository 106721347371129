import { LiveMoovAPIClient } from "./api";
import { APIResponse } from "./request";
import { ACHConfig, PTCConfig, UpdateACHConfig, UpdatePTCConfig } from "./transfersConfig.model";

export interface TransfersConfigAPI {
  /** Retrieves the ACH transfer config for a facilitator */
  getACH(facilitatorID: string, accountID: string): APIResponse<ACHConfig>;
  /** Updates the ACH transfer config for a facilitator */
  updateACH(facilitatorID: string, accountID: string, ach: UpdateACHConfig): APIResponse<ACHConfig>;
  /**
   * Retrieves all push-to-card configs for a facilitator.
   * In virtually all cases, facilitators will have a single config.
   * In the future, facilitators may have separate configs for different connected merchants.
   **/
  listPTC(facilitatorID: string): APIResponse<PTCConfig[]>;
  /** Retrieves a specific push-to-card config for a given config id */
  getPTC(facilitatorID: string, configID: string): APIResponse<PTCConfig>;
  /** Creates a new PTC config for the facilitator */
  createPTC(facilitatorID: string, ptc: UpdatePTCConfig): APIResponse<PTCConfig>;
  /** Updates a specific push-to-card config using the given config id */
  updatePTC(facilitatorID: string, configID: string, ptc: UpdatePTCConfig): APIResponse<PTCConfig>;
}

export class LiveTransfersConfigAPI implements TransfersConfigAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async getACH(facilitatorID: string, accountID: string): APIResponse<ACHConfig> {
    const [result, err] = await this._client.request<ACHConfig>(
      `/ops/transfers-configs/ach/${accountID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async updateACH(
    facilitatorID: string,
    accountID: string,
    ach: UpdateACHConfig
  ): APIResponse<ACHConfig> {
    const [result, err] = await this._client.request<ACHConfig>(
      `/ops/transfers-configs/ach/${accountID}`,
      {
        xAccountID: facilitatorID,
        method: "PATCH",
        json: ach
      }
    );
    return [result, err];
  }

  async listPTC(facilitatorID: string): APIResponse<PTCConfig[]> {
    const [result, err] = await this._client.request<PTCConfig[]>(
      "/ops/transfers-configs/card-acquiring-merchant-contexts",
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getPTC(facilitatorID: string, configID: string): APIResponse<PTCConfig> {
    const [result, err] = await this._client.request<PTCConfig>(
      `/ops/transfers-configs/card-acquiring-merchant-contexts/${configID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async createPTC(facilitatorID: string, ptc: UpdatePTCConfig): APIResponse<PTCConfig> {
    const [result, err] = await this._client.request<PTCConfig>(
      "/ops/transfers-configs/card-acquiring-merchant-contexts",
      {
        xAccountID: facilitatorID,
        method: "POST",
        json: ptc
      }
    );
    return [result, err];
  }

  async updatePTC(
    facilitatorID: string,
    configID: string,
    ptc: UpdatePTCConfig
  ): APIResponse<PTCConfig> {
    const [result, err] = await this._client.request<PTCConfig>(
      `/ops/transfers-configs/card-acquiring-merchant-contexts/${configID}`,
      {
        xAccountID: facilitatorID,
        method: "PATCH",
        json: ptc
      }
    );
    return [result, err];
  }
}
