import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";
import type { Theme } from "@moovfinancial/common/types/Theme";
import styles from "./Nameplate.module.scss";

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  /**
   * The icon to display on the left
   */
  Icon?: ReactNode;
  /**
   * The string or ReactNode title to display on the right
   */
  title?: string | ReactNode;
  /**
   * The string or ReactNode subtitle to display on the right
   */
  subtitle?: string | ReactNode;
  /**
   * CSS class(es) to override default styling
   */
  capitalize?: boolean;
  /**
   * Optional content to display on the right side of the nameplate
   */
  rightContent?: ReactNode;
  /**
   * DEPRECATED: Use `theme` prop instead
   *
   * @deprecated Use `theme` prop instead
   */
  className?: string;
  /**
   *  theme object to override default styling of the component
   */
  theme?: Theme<typeof styles>;
}

/**
 * Nameplate is a horizontal component that displays an icon, title, and subtitle. The icon
 * is on the left, and the stacked title and subtitle are on the right.
 */
export const Nameplate = ({
  Icon,
  title,
  subtitle,
  className,
  theme,
  rightContent,
  capitalize = false,
  ...rest
}: Props) => (
  <div
    className={clsx(
      styles.nameplate,
      theme?.nameplate,
      capitalize && styles.capitalize,
      capitalize && theme?.capitalize,
      className
    )}
    {...rest}
  >
    <div className={clsx(styles.leftContent, theme?.leftContent)}>
      {Icon && <div className={clsx(styles.icon, theme?.icon)}>{Icon}</div>}
      <div className={styles.titles}>
        {title && <div className={clsx(styles.title, theme?.title)}>{title}</div>}
        {subtitle && <div className={clsx(styles.subtitle, theme?.subtitle)}>{subtitle}</div>}
      </div>
    </div>
    <div className={clsx(styles.rightContent, theme?.rightContent)}>{rightContent}</div>
  </div>
);
