import clsx from "clsx";
import { ElementType } from "react";
import { Loading } from "../../Foundations";
import {
  ButtonLikeModifiers,
  ButtonLikeSizing,
  ButtonLikeStyles,
  ButtonSizes
} from "./ButtonLikeTypes";
import {
  generateButtonLikeModifierClasses,
  generateButtonLikeStyleClasses
} from "./ButtonLikeUtils";
import styles from "./ButtonLike.module.scss";

type ButtonLikeAppearance = ButtonLikeModifiers & ButtonLikeSizing & ButtonLikeStyles;

export type ButtonLikeProps<C extends ElementType> = {
  /**
   * The component that should display the button-like styles.
   * Supports any component, but recommended usage is with `<button>` and `<Link>`
   * (see `Button` and `LinkAsButton` components).
   */
  as: C;
  /**
   * The text to be shown in the button. If passed along with children, it is ignored.
   */
  label?: string;
} & ButtonLikeAppearance &
  React.ComponentProps<C>;

/**
 * ButtonLike is a component that handles styling elements to look like buttons.
 */
export const ButtonLike = <C extends ElementType = "button">({
  as: Component,
  label,
  buttonType,
  buttonStyle,
  buttonSize = "M",
  gap = 0,
  fullWidth,
  fullWidthOnMobile,
  isLoading = false,
  children,
  className,
  ...rest
}: ButtonLikeProps<C>) => {
  const classNames = clsx(
    styles.buttonLike,
    styles[`size-${buttonSize as ButtonSizes}`],
    generateButtonLikeStyleClasses({ buttonType, buttonStyle }),
    generateButtonLikeModifierClasses({
      fullWidth,
      fullWidthOnMobile,
      isLoading
    }),
    gap !== 0 && styles.withGap,
    className
  );
  const isButton = Component === "button" || Component.componentName === "BaseButton";
  const isDisabled = rest.disabled || isLoading;
  const style = gap !== 0 ? { gap: `${gap}rem` } : {};
  return (
    <Component className={classNames} disabled={isButton ? isDisabled : undefined} {...rest}>
      <span className={styles.outer} style={style}>
        <span className={styles.inner} style={style}>
          {children ?? label}
        </span>
      </span>
      {isLoading && (
        <div className={styles.loadingElement}>
          <Loading />
        </div>
      )}
    </Component>
  );
};
