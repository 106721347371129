import React, { useContext, useEffect, useState } from "react";
import { FormGroup } from "@moovfinancial/cargo";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { Step, StepContext } from "components/dialog/StepModal";
import { Input, Label } from "components/form/Form";
import StateSelect from "components/form/StateSelect";
import { Address } from "api/Account.model";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "../../AccountSetup.module.scss";

export const ManualAddress = ({
  setAddressUpdated
}: {
  setAddressUpdated: (address: boolean) => void;
}) => {
  const { account, dispatch, invalidInputs, validate, isErrored, resetInput } =
    useContext(AccountSetupContext);
  const { setBack } = useContext(StepContext);

  const [address, setAddress] = useState<Address>(() => {
    if (account.accountType === "individual") {
      if (account.profile?.individual?.address?.addressLine1) {
        return account.profile.individual.address;
      }
    } else if (account.accountType === "business") {
      if (account.profile?.business?.address.addressLine1) {
        return account.profile.business.address;
      }
    }

    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateOrProvince: "",
      postalCode: "",
      country: ""
    };
  });

  useEffect(() => {
    setBack(() => handleBack());
  }, []);

  useEffect(() => {
    setAddressUpdated(true);
  }, [address]);

  const updateAddress = (newAddress: Partial<Address>) => {
    setAddress({ ...address, ...newAddress });
    dispatch({
      type: account.accountType === "individual" ? "individualAddress" : "businessAddress",
      value: { ...address, ...newAddress }
    });
  };

  const handleBack = () => {
    setBack(undefined);
    closeManualAddress();
  };

  const closeManualAddress = () => {
    dispatch({
      type: "manualAddress",
      value: false
    });
  };

  const handleSubmit = () => {
    setBack(undefined);
    closeManualAddress();
  };

  const getErrorMessage = (name: string) => {
    if (isErrored(name) || invalidInputs.includes(name)) {
      return "Field is invalid";
    }
  };
  return (
    <Step className={styles.manualAddress} onSubmit={handleSubmit}>
      <FormGroup className={styles.fullwidthField} errorMessage={getErrorMessage("addressLine1")}>
        <Label label="Address line 1" htmlFor="addressLine1" />
        <Input
          name="addressLine1"
          autoFocus
          type="text"
          onChange={(e) => {
            resetInput(e.target.name);
            updateAddress({ addressLine1: e.target.value });
          }}
          value={address.addressLine1 || ""}
          autoComplete="address-line1"
          onBlur={(e) => validate(e.target)}
          pattern={REGEX.NO_ILLEGAL_CHARACTERS}
        />
      </FormGroup>
      <FormGroup className={styles.fullwidthField} errorMessage={getErrorMessage("addressLine2")}>
        <Label label="Address line 2" htmlFor="addressLine2" />
        <Input
          name={"addressLine2"}
          type="text"
          onChange={(e) => {
            resetInput(e.target.name);
            updateAddress({ addressLine2: e.target.value });
          }}
          value={address.addressLine2 || ""}
          autoComplete="address-line2"
          onBlur={(e) => validate(e.target)}
          pattern={REGEX.NO_ILLEGAL_CHARACTERS}
        />
      </FormGroup>
      <FormGroup className={styles.fullwidthField} errorMessage={getErrorMessage("city")}>
        <Label label="City" htmlFor="city" />
        <Input
          name="city"
          type="text"
          onChange={(e) => {
            resetInput(e.target.name);
            updateAddress({ city: e.target.value });
          }}
          value={address.city || ""}
          autoComplete="address-level2"
          onBlur={(e) => validate(e.target)}
          pattern={REGEX.NO_ILLEGAL_CHARACTERS}
        />
      </FormGroup>
      <StateSelect
        name="stateOrProvince"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          resetInput(e.target.name);
          updateAddress({ stateOrProvince: e.target.value });
        }}
        value={address.stateOrProvince || ""}
        onBlur={(e) => validate(e.target)}
      />
      <FormGroup errorMessage={getErrorMessage("postalCode")}>
        <Label label="ZIP code" htmlFor="postalCode" />
        <Input
          name="postalCode"
          type="text"
          onChange={(e) => {
            resetInput(e.target.name);
            updateAddress({ postalCode: e.target.value });
          }}
          value={address.postalCode || ""}
          autoComplete="postal-code"
          pattern="[0-9]{5}"
          maxLength={5}
          minLength={5}
          inputMode={"numeric"}
          onBeforeInput={(e) => {
            // @ts-expect-error TODO
            if (e.data.match(/[^0-9]/)) {
              e.preventDefault();
            }
          }}
          onBlur={(e) => validate(e.target)}
        />
      </FormGroup>
    </Step>
  );
};
