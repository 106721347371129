import clsx from "clsx";
import DOMPurify from "dompurify";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { FormGroup, Icon } from "@moovfinancial/cargo";
import { IconErrorOutlined, IconUpload } from "@moovfinancial/cargo/icons";
import { useBreakpoint } from "hooks/useBreakpoint";
import { FileForUpload } from "pages/production-access-form/ProductionAccessFormReducer";
import FileInput from "./FileInput";
import { Label } from "./Form";
import styles from "./DocumentUpload.module.scss";

const FileExtensionsMap = {
  ".csv": "CSV",
  ".png": "PNG",
  ".jpeg": "JPEG",
  ".pdf": "PDF",
  ".tiff": "TIFF"
};

type FileExtensions = keyof typeof FileExtensionsMap;

export interface FileForUploadState {
  file: FileForUpload;
  status: "pending" | "success" | "error";
  errorMessage?: string;
}

interface DocumentUploadProps {
  addFileErrorMessage?: string;
  acceptedFileTypes?: FileExtensions[];
  addFile: (file: FileForUpload) => void;
  className?: string;
  dropZoneText?: string;
  label?: string;
  maxDocs?: number;
  onDelete: (name: string) => void;
  selectedFiles: FileForUploadState[];
  showUploadIcon?: boolean;
}

export const DocumentUpload = ({
  addFileErrorMessage,
  acceptedFileTypes = [".pdf", ".csv", ".jpeg", ".png"],
  addFile,
  className,
  dropZoneText,
  label,
  maxDocs,
  onDelete,
  selectedFiles,
  showUploadIcon = false
}: DocumentUploadProps) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const mobile = useBreakpoint("mobile");

  const handleOnDragOver = (event: any) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleOnDrop = (event: any) => {
    event.preventDefault();
    setIsDragOver(false);
    if (event.target.files.length > 0) {
      const files = [...event.target.files];
      files.forEach((file) => {
        addFile(file);
      });
    }
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    inputRef.current?.click();
  };

  const handleFileUpload = (event: any) => {
    if (event.target.files.length > 0) {
      const files = [...event.target.files];
      files.forEach((file) => {
        addFile(file);
      });
      event.target.value = null;
    }
  };

  const handleFileInputClick = (e: any) => {
    const fileToOpen = selectedFiles.find((file) => file.file.name === e.target.value)?.file;
    if (fileToOpen && !fileToOpen.fileID) {
      try {
        const url = URL.createObjectURL(fileToOpen);
        const sanitizedUrl = DOMPurify.sanitize(url);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = sanitizedUrl;
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileToOpen.name);
        a.click();
        URL.revokeObjectURL(sanitizedUrl);
      } catch {
        toast("There was an error previewing your file.");
        return;
      }
    }

    e.stopPropagation();
  };

  const nonErroredFileCount = selectedFiles.filter(
    (file) => file.status === "pending" || file.status === "success"
  ).length;

  const getDropZoneText = () => {
    if (dropZoneText) return dropZoneText;

    return `${acceptedFileTypes.map((ext) => FileExtensionsMap[ext]).join(", ")} accepted (10mb max)`;
  };

  return (
    <FormGroup className={className}>
      <>
        {label && <Label>{label}</Label>}
        {(!maxDocs || nonErroredFileCount < maxDocs) && (
          <>
            <div
              className={clsx(
                { [styles.isDragOver]: isDragOver, [styles.addFileError]: addFileErrorMessage },
                styles.dropZone
              )}
              onDrop={handleOnDrop}
              onDragOver={handleOnDragOver}
              onDragLeave={handleDragLeave}
              onClick={handleClick}
              data-testid="dropZone"
            >
              {isDragOver ? (
                <p>Drop your file...</p>
              ) : (
                <>
                  {showUploadIcon && (
                    <div className={styles.uploadIconContainer}>
                      <Icon className={styles.uploadIcon} iconComponent={IconUpload} size="XL" />
                      <div className={styles.uploadIconBackground}></div>
                    </div>
                  )}
                  <span className={styles.textRow}>
                    <span className={styles.fileSelect}>Select file </span>
                    <input
                      type="file"
                      data-testid="fileInput"
                      id="file"
                      ref={inputRef}
                      className={styles.hidden}
                      onChange={handleFileUpload}
                      multiple={maxDocs && maxDocs > 1 ? true : false}
                      accept={acceptedFileTypes.join(",")}
                    />
                    {!mobile && "or drop here"}
                  </span>
                  <p className={styles.dropZoneText}>{getDropZoneText()}</p>
                </>
              )}
            </div>
          </>
        )}
        {selectedFiles.map((file, index) => {
          return (
            <FileInput
              key={`${file.file.name}-${index}`}
              value={file.file.name}
              onFileInputClick={handleFileInputClick}
              onDelete={onDelete}
              className={styles.fileInput}
              complete={file.status === "success"}
              errorMessage={file.errorMessage}
              disabled={!!file.file.fileID}
            />
          );
        })}
        {addFileErrorMessage && (
          <div className={styles.addFileErrorMessageContainer}>
            <Icon iconComponent={IconErrorOutlined} size="XS" />
            <p className={styles.addFileErrorMessage}>{addFileErrorMessage}</p>
          </div>
        )}
      </>
    </FormGroup>
  );
};
