import { Wallet } from "api/v2";
import { MetaData } from "./MetaData.model";
import { Amount, PagingFilter } from "./common.model";
import { Dispute } from "./disputes.model";
import { BankAccount } from "./paymentMethods/bankAccounts.model";
import { Card } from "./paymentMethods/cards.model";
import { PaymentMethod, PaymentMethodType } from "./paymentMethods/paymentMethods.model";

export type DebitHoldPeriod = "no-hold" | "1-day" | "2-days";

export type ACHTransferStatus = "initiated" | "originated" | "corrected" | "returned" | "completed";

export type ACHTransferStatusUpdates = {
  initiatedOn?: string;
  originatedOn?: string;
  failedOn?: string;
  completedOn?: string;
  returnedOn?: string;
  correctedOn?: string;
};

export interface ACHDetail extends ACHTransferStatusUpdates {
  status: ACHTransferStatus;
  traceNumber: string;
  return?: {
    code: string;
    reason: string;
    description: string;
  };
  correction?: {
    code: string;
    reason: string;
    description: string;
  };
  debitHoldPeriod?: DebitHoldPeriod;
  originatingCompanyName?: string;
  companyEntryDescription?: string;
  secCode?: "WEB" | "PPD" | "CCD" | "TEL";
}

export type RTPTransferStatus = "initiated" | "completed" | "failed" | "accepted-without-posting";

export type RTPTransferStatusUpdates = {
  initiatedOn?: string;
  completedOn?: string;
  failedOn?: string;
  acceptedWithoutPostingOn?: string;
};

export type RTPFailureCode =
  | "processing-error"
  | "invalid-account"
  | "account-closed"
  | "account-blocked"
  | "invalid-field"
  | "transaction-not-supported"
  | "limit-exceeded"
  | "invalid-amount"
  | "customer-deceased"
  | "other";

export interface RTPTransferDetail extends RTPTransferStatusUpdates {
  status: RTPTransferStatus;
  networkResponseCode?: string;
  failureCode?: RTPFailureCode;
}

export interface RefundAmount {
  amount: number;
}

export type CardFailureCode =
  | "call-issuer"
  | "lost-or-stolen"
  | "do-not-honor"
  | "processing-error"
  | "invalid-transaction"
  | "invalid-amount"
  | "invalid-card-number"
  | "no-such-issuer"
  | "reenter-transaction"
  | "insufficient-funds"
  | "expired-card"
  | "incorrect-pin"
  | "transaction-not-allowed"
  | "suspected-fraud"
  | "amount-limit-exceeded"
  | "cvv-mismatch"
  | "velocity-limit-exceeded"
  | "invalid-merchant"
  | "card-not-activated"
  | "cardholder-account-closed"
  | "issuer-not-available"
  | "revocation-of-authorization"
  | "could-not-route";

export const cardFailureReasons: Record<CardFailureCode, string> = {
  "amount-limit-exceeded": "The amount entered exceeds issuer or approved amount",
  "call-issuer": "Contact card issuer for decline reason",
  "card-not-activated": "The card has not been properly unblocked",
  "cardholder-account-closed": "The account is closed",
  "could-not-route":
    "The financial institution cannot be found for routing (receiving institution ID is invalid)",
  "cvv-mismatch": "The CVV entered is incorrect",
  "do-not-honor": "The issuer will not allow the transaction",
  "expired-card": "The card has expired or expiration date is missing",
  "incorrect-pin": "The PIN is incorrect or missing",
  "insufficient-funds": "This card has insufficient funds to complete the transaction",
  "invalid-amount": "The amount entered is invalid",
  "invalid-card-number": "The card number is incorrect",
  "invalid-merchant": "The merchant is invalid",
  "invalid-transaction": "The transaction is invalid",
  "issuer-not-available":
    "The Issuer was unavailable for the transaction and STIP is not applicable or not available for this transaction",
  "lost-or-stolen": "The card was reported as lost or stolen",
  "no-such-issuer": "The issuer is invalid",
  "processing-error": "The transaction could not be processed due to processing error",
  "reenter-transaction": "The transaction needs to be re-entered",
  "revocation-of-authorization": "Revocation of authorization order",
  "suspected-fraud": "The transaction was declined because of suspected fraud",
  "transaction-not-allowed": "The transaction was not allowed",
  "velocity-limit-exceeded": "The transaction exceeds withdrawal frequency limit"
};

export interface CardTransferStatusUpdates {
  initiatedOn?: string;
  failedOn?: string;
  canceledOn?: string;
  confirmedOn?: string;
  settledOn?: string;
  completedOn?: string;
}

export interface CardTransferDetail extends CardTransferStatusUpdates {
  status: CardTransferStatus;
  failureCode?: CardFailureCode;
  dynamicDescriptor?: string;
  transactionSource?: "first-recurring" | "recurring" | "unscheduled";
}

export interface ApplePayTransferSource {
  brand:
    | "American Express"
    | "Visa"
    | "Mastercard"
    | "Discover"
    | "JCB"
    | "unknown"
    | "AmericanExpress"
    | "MasterCard"; // @TODO: last two are deprecated
  cardType: "credit" | "debit" | "prepaid" | "unknown";
  cardDisplayName: string;
}

export type CardTransferStatus =
  | "initiated"
  | "confirmed"
  | "canceled"
  | "settled"
  | "failed"
  | "completed";

export type TransferPartialAccount = {
  accountID: string;
  displayName: string;
  email: string;
};

export interface TransferSource {
  paymentMethodID: string;
  paymentMethodType: PaymentMethodType;
  account: TransferPartialAccount;
  wallet?: Wallet;
  bankAccount?: Pick<
    BankAccount,
    | "bankAccountID"
    | "holderName"
    | "holderType"
    | "bankAccountType"
    | "bankName"
    | "lastFourAccountNumber"
  >;
  achDetails?: ACHDetail;
  card?: Card;
  cardDetails?: CardTransferDetail;
  rtpDetails?: RTPTransferDetail;
  transferID?: string;
  applePay?: ApplePayTransferSource;
}

export interface MoovFeeDetails {
  cardScheme: string;
  interchange?: string;
  moovProcessing: string;
  discount?: string;
}

export type RefundStatus = "created" | "pending" | "completed" | "failed";

export interface Refund {
  refundID: string;
  createdOn: string;
  updatedOn: string;
  status: RefundStatus;
  amount: Amount;
  failureReason?: CardFailureCode;
  cardDetails?: CardTransferDetail;
}

export type CancellationStatus = "pending" | "completed" | "failed";

export interface Cancellation {
  status: CancellationStatus;
  createdOn: string;
}

export interface Reversal {
  cancellation?: Cancellation;
  refund?: Refund;
}

export type TransferStatus =
  | "created"
  | "pending"
  | "completed"
  | "failed"
  | "canceled"
  | "reversed"
  | "queued"
  | "refunded" // fe-only
  | "disputed" // fe-only
  | "dispute-resolved"; // fe-only

export type TransferLegStatus =
  | "initiated"
  | "originated"
  | "corrected"
  | "returned"
  | "completed"
  | "confirmed"
  | "settled"
  | "failed"
  | "refunded"
  | "refund-created"
  | "refund-pending"
  | "refund-completed"
  | "refund-failed"
  | "disputed"
  | "dispute-resolved"
  | "dispute-response-needed"
  | "dispute-reversal";

export type FailureReason =
  | "source-payment-error"
  | "destination-payment-error"
  | "wallet-insufficient-funds"
  | "rejected-high-risk"
  | "processing-error";

export interface Transfer {
  transferID: string;
  createdOn: string;
  completedOn?: string;
  status: TransferStatus;
  failureReason?: FailureReason;
  source: TransferSource;
  destination: TransferSource;
  amount: Amount;
  description: string;
  metadata?: MetaData;
  refunds?: Refund[];
  refundedAmount?: {
    currency: string;
    value: number;
  };
  disputes?: Pick<Dispute, "disputeID" | "createdOn" | "amount">[];
  disputedAmount?: {
    currency: string;
    value: number;
  };
  facilitatorFee?: {
    total?: number;
    markup?: number;
  };
  moovFeeDetails?: MoovFeeDetails;
  groupID?: string;
  moovFee?: number;
}

export interface NewTransfer {
  source: {
    paymentMethodID?: string;
    accountID?: string;
  };
  destination: {
    paymentMethodID?: string;
    accountID?: string;
  };
  amount: Amount;
  description: string;
}

export interface GetTransferOptions {
  source?: {
    paymentMethodID?: string;
    accountID?: string;
  };
  destination?: {
    paymentMethodID?: string;
    accountID?: string;
  };
  amount: Amount;
}

export interface TransferOptions {
  sourceOptions?: PaymentMethod[];
  destinationOptions?: PaymentMethod[];
}

export interface UpdateTransferMetadata {
  metadata?: MetaData;
}

export interface TransferListFilter extends PagingFilter {
  status?: string;
  accountIDs?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  groupID?: string;
  refunded?: boolean;
  disputed?: boolean;
}

export const defaultTransferListFilter: TransferListFilter = {
  count: 20,
  skip: 0
};
